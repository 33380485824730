import React, { useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import { dashboardFilter } from 'utils/helpers/constant';
import Select from "react-select";
import { sectionLabels } from 'utils/helpers/constant';
import { getFinancialYear, getRole } from 'utils/helpers/sessionStores';
import { categoryWiseAnalysis, categoryWiseFilterAnalysis, dashboardWigets, filterDashboardWigets, graphOfFilterPayment, graphOfPayment, lastTenOrder, lastTenOrderFilter } from 'api/dashboardApi';
import { useMainContext } from 'Context/Context';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { tokenDecode } from 'utils/helpers/comman';

const Section = (props: any) => {
    const myDate = new Date();
    const hrs = myDate.getHours();
    const { setFilterData, setGraphData, setRecentOrderData, setOrderData } = useMainContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterValue, setFilterValue] = useState(dashboardFilter[0]);
    const role = getRole();
    const financialYear = getFinancialYear();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const userNameFromToken: any = tokenDecode();

    function handleFilter(selectedOption: any) {
        setFilterValue(selectedOption);
    }

    const adjustDateToLocal = (date: any) => {
        const offset = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() - offset);
        return date;
    }

    const handleWidgetFilter = () => {
        let data = {
            startDate: startDate ? adjustDateToLocal(new Date(startDate)).toISOString().split('T')[0] : null,
            endDate: endDate ? adjustDateToLocal(new Date(endDate)).toISOString().split('T')[0] : null,
            orderType: filterValue ? filterValue.value : "All",
            financialYear: financialYear
        };
        let lastTenOrderData = {
            orderType: filterValue ? filterValue.value : "All"
        };
        setIsLoading(true);
        filterDashboardWigets(data)
            .then((resp) => {
                setFilterData(resp.data);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false);
            })
        graphOfFilterPayment(data)
            .then((resp) => {
                setGraphData(resp.data);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false);
            })
        categoryWiseFilterAnalysis(data)
            .then((resp) => {
                setRecentOrderData(resp.data);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false);
            })
        lastTenOrderFilter(lastTenOrderData)
            .then((resp) => {
                setOrderData(resp.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const clearFilter = () => {
        setStartDate(null);
        setEndDate(null);
        setFilterValue(dashboardFilter[0]);
        dashboardWigets({ financialYear })
            .then((resp) => {
                setFilterData(resp.data);
            })
            .catch((err) => {
                console.log(err)
            })
        graphOfPayment({ financialYear })
            .then((resp) => {
                setGraphData(resp.data)
            })
            .catch((err) => {
                console.log(err)
            })
        categoryWiseAnalysis({ financialYear })
            .then((resp) => {
                setRecentOrderData(resp.data);
            })
            .catch((err) => {
                console.log(err);
            })
        lastTenOrder()
            .then((resp) => {
                setOrderData(resp?.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <React.Fragment>
            <Row className="mb-3 pb-1">
                <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1">
                            <h4 className="fs-16 mb-1">
                                {hrs >= 12
                                    ? hrs >= 16
                                        ? sectionLabels?.goodEvening
                                        : sectionLabels?.goodAfternoon
                                    : sectionLabels?.goodMorning}
                                , {userNameFromToken?.name}!
                            </h4>
                        </div>
                        {role === sectionLabels?.seller && (
                            <div className="mt-3 mt-lg-0">
                                <form action="#">
                                    <Row className="g-3 mb-0 align-items-center">
                                        <div className="col-sm-auto d-flex align-items-center">
                                            From Date:
                                            <Flatpickr
                                                className="form-control mx-2"
                                                options={{
                                                    dateFormat: "d M, Y"
                                                }}
                                                style={{ width: '120px' }}
                                                value={startDate || undefined}
                                                placeholder='Select Date..'
                                                onChange={(date: any) => setStartDate(date[0])}
                                            />

                                            To Date:
                                            <Flatpickr
                                                className="form-control mx-2"
                                                options={{
                                                    dateFormat: "d M, Y"
                                                }}
                                                style={{ width: '120px' }}
                                                value={endDate || undefined}
                                                placeholder='Select Date..'
                                                onChange={(date: any) => setEndDate(date[0])}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <Select
                                                value={filterValue}
                                                onChange={handleFilter}
                                                options={dashboardFilter}
                                                name="choices-single-default"
                                                id="idStatus"
                                            ></Select>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" onClick={handleWidgetFilter} className="btn btn-soft-success">
                                                {isLoading ? <Spinner size="sm" /> : <> <i className="ri-filter-line align-middle "></i> Load </>}
                                            </button>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" onClick={clearFilter} className="btn btn-soft-danger" id="tooltip">
                                                <i className="ri-brush-3-fill align-middle"></i>
                                                <ReactTooltip
                                                    place="top"
                                                    variant="info"
                                                    content="Clear Filter"
                                                    anchorId="tooltip"
                                                    style={{ zIndex: '9999' }}
                                                />
                                            </button>
                                        </div>
                                    </Row>
                                </form>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;