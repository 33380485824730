import LoaderBlur from 'Components/Common/BlurLoader';
import Loader from 'Components/Common/loader';
import { listOfHSN } from 'api/HSN';
import { listOfCategory } from 'api/categoryApi';
import { addProductPurchase, deleteAddedProduct, editAddedProduct, listOfProductCode, productDetails, viewAddedProduct } from 'api/productPurchase';
import { listOfSubCategory } from 'api/subCategory';
import { useFormik } from 'formik';
import AddProduct from 'pages/Products/addProduct';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap'
import { categoryLabels, productPurchase, productPurchaseEntry, title } from 'utils/helpers/constant';
import { ResponseStatusEnum } from 'utils/helpers/enums';
import { PlaceHolderFormat, RequiredField } from 'utils/helpers/validationRequiredMessages';
import * as Yup from 'yup';

interface PropsData {
    isOpen: boolean,
    toggleModal: any,
    supplierId: number | undefined,
    productPurchaseId: number | undefined,
    viewAddedProductData: ViewProductPurchaseData | undefined,
    isProductId: number | undefined,
    isPublish: string | undefined,
    refreshAddedProductTable: () => void
}

interface CategoryOptions {
    category_name: string,
    id: number
}
interface SubCategoryOptions {
    id: number,
    subCategory_name: string,
}
interface HsnOption {
    id: number,
    code: string,
}

interface OptionType {
    value: string,
    label: string,
}

interface ConvertedRateOptionType {
    value: number,
    label: string,
}

interface ProductDetails {
    code: string,
    product_name: string,
    product_description: string,
    category_id: {
        id: number,
        category_name: string
    },
    hsn_code_id: {
        id: number,
        code: string
    },
    sub_category_id: {
        id: number,
        sub_category_name: string
    },
    product_variant: ProductVariantProps[]
}

interface ProductVariantProps {
    id: number,
    mrp: number,
    sale_rate: number,
    color: string,
    size: string,
    purchase_rate: number
}

interface ViewProductPurchaseData {
    id: number,
    code: undefined | string,
    product_name: string,
    product_description: string,
    purchase_rate: number,
    mrp: any,
    inr_purchase_rate: number,
    sale_rate: number,
    shipping_rate: number,
    total_quantity: number,
    converted_rate: number,
    total_amount: number,
    category_id: Category
    sub_category_id: SubCategory,
    hsn_code_id: HSN,
    product_purchase_variant: ProductPurchaseVariantProps[],
    supplier_product_purchase_id: SupplierProductPurchaseProps
}
interface SupplierProductPurchaseProps {
    id: number,
    supplier_id: {
        id: number,
        supplier_name: string
    }
    status: string
}
interface ProductPurchaseVariantProps {
    id: number,
    mrp: any,
    sale_rate: number,
    color: string,
    size: string,
    purchase_variant_description: null,
    product_variant_id: {
        id: number
    }
}
interface Category {
    id: number,
    category_name: string
}
interface SubCategory {
    id: number,
    sub_category_name: string
}
interface HSN {
    id: number,
    code: string
}
interface VariantsProps {
    id: number,
    mrp: number,
    sale_rate: number,
    color: string,
    size: string,
    quantity: number,
    purchase_rate: number,
    purchase_variant_description: null | string,
    product_variant_id: {
        id: number
    }
}

interface ValidationVariants {
    id: number,
    mrp: number,
    sale_rate: string,
    color: string,
    size: string,
    quantity: string
}

const AddProductPurchase = () => {
    const navigate = useNavigate();
    const [productDetailsData, setProductDetailsData] = useState<ProductDetails>();
    const [productCategoryData, setProductCategoryData] = useState([]);
    const [productSubCategoryData, setProductSubCategoryData] = useState([]);
    const [productHsnData, setProductHsnData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [productDetailsDataCode, setProductDetailsDataCode] = useState([]);
    const [productCodeData, setProductCodeData] = useState('');
    const [viewAddedProductData, setViewAddedProductData] = useState<ViewProductPurchaseData>();
    const [isOpen, setIsOpen] = useState(false);
    const { productId }: any = useParams();
    const { supplierPurchaseId }: any = useParams();

    const isPublish = viewAddedProductData?.supplier_product_purchase_id?.status

    const toggleForm = () => {
        setIsOpen(!isOpen);
    };

    const fetchCategory = () => {
        setIsLoading(true);
        listOfCategory({})
            .then(response => {
                setProductCategoryData(response?.data?.listOfCategory?.map((category: CategoryOptions) => ({
                    value: category?.id,
                    label: category?.category_name
                })));
            })
            .catch((error) => {
                return error
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchSubCategory = () => {
        setIsLoading(true)
        listOfSubCategory({})
            .then(response => {
                setProductSubCategoryData(response?.data?.listOfSubCategoryData?.map((sub_category: SubCategoryOptions) => ({
                    value: sub_category?.id,
                    label: sub_category?.subCategory_name
                })));
            })
            .catch((err) => {
                return err;
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const fetchHSN = () => {
        setIsLoading(true)
        listOfHSN({})
            .then(response => {
                setProductHsnData(response?.data?.map((hsn_data: HsnOption) => ({
                    value: hsn_data?.id,
                    label: hsn_data?.code
                })));
            })
            .catch((err) => {
                return err;
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const fetchProductDetails = () => {
        setIsProductLoading(true);
        listOfProductCode({}).then((response) => {
            setProductDetailsDataCode(response?.data?.map((code: { code: string }) => ({
                value: code?.code,
                label: code?.code
            })))
            setVariants(response?.data?.product_variant)
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsProductLoading(false);
        })
    }

    const fetchProductDetailsCode = (selectedCode: string) => {
        setIsProductLoading(true);
        productDetails({ code: selectedCode }).then((response) => {
            setProductDetailsData(response?.data)
            setVariants(response?.data?.product_variant)
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsProductLoading(false);
        })
    }

    const fetchViewAddedProduct = () => {
        setIsLoading(true);
        viewAddedProduct(productId).then((response) => {
            setViewAddedProductData(response?.data);
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        Promise.all([
            fetchCategory(), fetchSubCategory(), fetchHSN(), fetchProductDetails(),
        ])
    }, [])

    useEffect(() => {
        fetchViewAddedProduct()
    }, [productId])


    const initialValues: any = viewAddedProductData
        ? {
            code: viewAddedProductData?.code,
            product_name: viewAddedProductData.product_name || productDetailsData?.product_name,
            product_description: viewAddedProductData.product_description || '',
            category_id: viewAddedProductData.category_id?.id || '',
            sub_category_id: viewAddedProductData.sub_category_id?.id || '',
            hsn_code_id: viewAddedProductData.hsn_code_id?.id || '',
            total_amount: viewAddedProductData.total_amount || '',
            total_quantity: viewAddedProductData.total_quantity || '',
            purchase_rate: viewAddedProductData.inr_purchase_rate || 0,
            mrp: viewAddedProductData.mrp || 0,
            converted_rate: viewAddedProductData?.converted_rate || '',
            sale_rate: viewAddedProductData.sale_rate || 0,
            shipping_rate: viewAddedProductData.shipping_rate || 0,
            variants: viewAddedProductData.product_purchase_variant || [],
        }
        : {
            code: productDetailsData?.code,
            product_name: productDetailsData?.product_name,
            product_description: productDetailsData?.product_description,
            category_id: productDetailsData?.category_id?.id,
            sub_category_id: productDetailsData?.sub_category_id?.id,
            hsn_code_id: productDetailsData?.hsn_code_id?.id,
            total_amount: '',
            total_quantity: '',
            purchase_rate: 0,
            mrp: 0,
            converted_rate: '',
            sale_rate: 0,
            shipping_rate: 0,
            variants: productDetailsData?.product_variant || [],
        };

    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            code: Yup.string().required(RequiredField("Product code")),
            purchase_rate: Yup.string().required(RequiredField("Purchase rate")),
            mrp: Yup.string().required(RequiredField("MRP")),
            converted_rate: Yup.string().required(RequiredField("Converted rate")),
            shipping_rate: Yup.string().required(RequiredField("Shipping rate")),
            variants: Yup.array().of(
                Yup.object().shape({
                    mrp: Yup.number().required(RequiredField("MRP")),
                    sale_rate: Yup.number().required(RequiredField("Sale rate")),
                    quantity: Yup.number().required(RequiredField("Quantity")),
                })
            ),
        }),
        onSubmit: (value) => {
            const updatedVariants = value?.variants?.map((variant: any) => ({  // here i have add any because facing errors
                ...variant,
                mrp: parseInt(variant.mrp),
                sale_rate: parseInt(variant.sale_rate),
                quantity: parseInt(variant.quantity),
                // purchase_rate: variant.purchase_rate,
                purchase_rate: variant.purchase_rate !== undefined ? parseInt(variant.purchase_rate) : parseInt(validation.values.mrp),
                product_variant_id: variant.product_variant_id?.id
            }));
            value.variants = updatedVariants

            const payloadOfEditProduct = {
                inr_purchase_rate: value.purchase_rate,
                converted_rate: value.converted_rate,
                purchase_rate: parseInt(value.mrp),
                shipping_rate: value.shipping_rate,
                sale_rate: value.sale_rate,
                total_amount: value.total_amount,
                variants: value.variants,
            }
            setIsLoading(true);
            if (viewAddedProductData === undefined) {
                const payload = {
                    product_purchase_line_item: {
                        category_id: value.category_id,
                        sub_category_id: value.sub_category_id,
                        hsn_code_id: value.hsn_code_id,
                        code: value.code,
                        product_name: value.product_name,
                        product_description: value?.product_description,
                        variants: value?.variants
                    },
                    supplier_product_purchase_id: parseInt(supplierPurchaseId),
                    inr_purchase_rate: value?.purchase_rate,
                    converted_rate: value.converted_rate,
                    mrp: parseInt(value?.mrp),
                    purchase_rate: parseInt(value?.mrp),
                    sale_rate: value?.sale_rate,
                    shipping_rate: value?.shipping_rate,
                    total_quantity: totalQuantity,
                    total_amount: totalAmount
                }
                addProductPurchase(payload).then((response) => {
                    if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        toast.success(response.message);
                        navigate(`/productPurchase/${supplierPurchaseId}`)
                        validation.resetForm();
                    } else {
                        toast.error(response.message);
                    }
                })
                    .catch((err) => {
                        toast.error(title.somethingWrong);
                        return err;
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                editAddedProduct(viewAddedProductData?.id, { ...payloadOfEditProduct })
                    .then((response) => {
                        if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                            toast.success(response.message);
                            navigate(`/productPurchase/${viewAddedProductData?.supplier_product_purchase_id?.id}`);
                            validation.resetForm();
                        } else {
                            toast.error(response.message);
                        }
                    })
                    .catch((err) => {
                        toast.error(title.somethingWrong);
                        return err;
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    });

    const handleRemoveVariants = (index: number, variantsId: number) => {
        if (productId) {
            deleteAddedProduct({ product_purchase_id: viewAddedProductData?.id, purchase_variant_id: variantsId }).then((response) => {
                if (
                    response.statusCode &&
                    ResponseStatusEnum.SUCCESS.includes(response?.statusCode)
                ) {
                    toast.success(response.message);
                    const updatedVariants = [...validation.values.variants];
                    updatedVariants.splice(index, 1);
                    validation.setFieldValue('variants', updatedVariants);
                } else {
                    toast.error(response.message);
                }
            })
                .catch((err) => {
                    toast.error(title.somethingWrong);
                    return err;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            const updatedVariants = [...validation.values.variants];
            updatedVariants.splice(index, 1);
            validation.setFieldValue('variants', updatedVariants);
        }
    };

    // const calculateMRP = (purchase_rate: number, shipping_rate: number, converted_rate: number) => {
    //     const mrp = ((purchase_rate + shipping_rate) / converted_rate).toFixed(2);
    //     validation.setFieldValue('mrp', mrp);
    // };

    const calculateMRP = (purchase_rate: number, shipping_rate: number, converted_rate: number) => {
        const mrp = ((purchase_rate + shipping_rate) / converted_rate).toFixed(2);
        validation.setFieldValue('mrp', mrp);
    };

    const handlePurchaseRateChange = (newValue: number) => {
        const { shipping_rate, converted_rate } = validation.values;
        calculateMRP(newValue, shipping_rate, converted_rate);
    };

    const handleShippingRateChange = (newValue: number) => {
        const { purchase_rate, converted_rate } = validation.values;
        calculateMRP(purchase_rate, newValue, converted_rate);
    };

    const handleConvertedRateChange = (newValue: number) => {
        const { purchase_rate, shipping_rate } = validation.values;
        calculateMRP(purchase_rate, shipping_rate, newValue);
    };


    const conversionRateOptions = Array.from({ length: 6 }, (_, i) => ({
        value: i + productPurchaseEntry.convertedRate,
        label: `${i + productPurchaseEntry.convertedRate}`,
    }));

    const [variants, setVariants] = useState([]);
    const totalAmount = validation?.values?.variants?.map((variant: ValidationVariants) => { // here i have add the total amount logic
        const saleRate = parseFloat(variant.sale_rate);
        const quantity = parseFloat(variant.quantity);
        return saleRate * quantity;
    })?.reduce((total: number, total_amount: number) => total + total_amount, 0);

    const totalQuantity = validation?.values?.variants?.reduce((total: number, variant: ValidationVariants) => { // here i have add the total quantity logic
        const quantity = parseFloat(variant.quantity);
        return total + quantity;
    }, 0);

    const closeModal = () => {
        setProductDetailsDataCode([])
        validation.resetForm()
    }

    const getOptionsWithSelected = (options: OptionType[] | undefined, selectedValue: string | undefined): OptionType[] => {
        if (Array.isArray(options) && selectedValue && !options.some((option: OptionType) => option.value === selectedValue)) {
            return [...options, { value: selectedValue, label: selectedValue }];
        }
        return options || [];
    };

    // Add the selected code to the options if necessary
    const productDetailsDataCodeWithSelected = getOptionsWithSelected(productDetailsDataCode, initialValues?.code);
    const productCategoryDataWithSelected = getOptionsWithSelected(productCategoryData, initialValues.category_id);
    const productSubCategoryDataWithSelected = getOptionsWithSelected(productSubCategoryData, initialValues.sub_category_id);
    const productHsnDataWithSelected = getOptionsWithSelected(productHsnData, initialValues.hsn_code_id);

    return (
        <div className='page-content'>
            {isLoading ? <Loader /> :
                <Container>
                    <Row className="mb-4">
                        <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                            <h5 className="fw-semibold mb-0">{productId ? "Update Product Purchase" : "Add Product Purchase/Product"}  </h5>
                        </div>
                        <div className="col-auto order-2 order-sm-3 ms-auto">
                            <div className="hstack gap-2">
                                <div className="form-check form-switch form-switch-lg">
                                    <Input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckRightDisabled" checked={isOpen} onChange={toggleForm} defaultChecked={false} disabled={productId !== undefined} />
                                    <Label className="form-check-label" for="flexSwitchCheckRightDisabled">If you have add the product then switch toggle.</Label>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {isOpen ? (
                        <AddProduct isOpen={isOpen} />
                    ) : (
                        <Card>
                            <CardBody>
                                <Form onSubmit={validation.handleSubmit}>
                                    <Row>
                                        {isProductLoading ? <LoaderBlur /> : <Col lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="product-title-input">
                                                    Product Code
                                                </Label>
                                                <Select
                                                    id="product-code-select"
                                                    name="product_code"
                                                    options={productDetailsDataCodeWithSelected}
                                                    value={productDetailsDataCodeWithSelected?.find((option: OptionType) => option?.value === validation.values.code)}
                                                    onChange={(selectedOption: OptionType) => {
                                                        validation.setFieldValue('code', selectedOption?.value)
                                                        setProductCodeData(selectedOption?.value)
                                                        fetchProductDetailsCode(selectedOption?.value);
                                                    }}
                                                    placeholder="Select product code"
                                                    isDisabled={viewAddedProductData}
                                                />
                                            </div>
                                        </Col>}

                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="product-title-input">
                                                    Product Name
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="product-title-input"
                                                    placeholder="Enter product name"
                                                    name="product_name"
                                                    value={validation.values.product_name || ""}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={!!(validation.errors.product_name && validation.touched.product_name)}
                                                    disabled
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="product-title-input">
                                                    Product Description
                                                </Label>
                                                <Input
                                                    type="textarea"
                                                    className="form-control"
                                                    id="product-title-input"
                                                    placeholder="Enter product description"
                                                    name="product_description"
                                                    value={validation.values.product_description || ""}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    invalid={!!(validation.errors.product_description && validation.touched.product_description)}
                                                    rows={1}
                                                    disabled
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label className="form-label" htmlFor="product-title-input">
                                                    Product Category
                                                </Label>
                                                <Select
                                                    value={productCategoryDataWithSelected?.find((option: OptionType) => option.value === validation.values.category_id)}
                                                    onChange={(selectedOption: OptionType) => validation.setFieldValue('category_id', selectedOption?.value || '')}
                                                    options={productCategoryDataWithSelected}
                                                    isDisabled
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label className="form-label" htmlFor="product-title-input">
                                                    Product Sub-Category
                                                </Label>
                                                <Select
                                                    value={productSubCategoryDataWithSelected?.find((option: OptionType) => option.value === validation.values.sub_category_id)}
                                                    onChange={(selectedOption: OptionType) => validation.setFieldValue('sub_category_id', selectedOption?.value || '')}
                                                    options={productSubCategoryDataWithSelected}
                                                    isDisabled
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label className="form-label" htmlFor="product-title-input">
                                                    HSN
                                                </Label>
                                                <Select
                                                    value={productHsnDataWithSelected?.find((option: OptionType) => option.value === validation.values.hsn_code_id)}
                                                    onChange={(selectedOption: OptionType) => validation.setFieldValue('hsn_code_id', selectedOption?.value || '')}
                                                    options={productHsnDataWithSelected}
                                                    isDisabled
                                                />
                                            </div>
                                        </Col>
                                        <hr />
                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label htmlFor="purchase_rate">Purchase Rate ({productPurchase.inrMark})</Label>
                                                <Input
                                                    type="number"
                                                    id="purchase_rate"
                                                    name="purchase_rate"
                                                    value={validation.values.purchase_rate}
                                                    min="0"
                                                    onChange={(e) => {
                                                        const newValue = parseFloat(e.target.value);
                                                        if (newValue >= 0 || e.target.value === "") {
                                                            validation.handleChange(e);
                                                            handlePurchaseRateChange(newValue);
                                                        }
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    placeholder="Enter Purchase Rate"
                                                    disabled={isPublish === "Publish"}
                                                />
                                                {validation.touched.purchase_rate && validation.errors.purchase_rate ? (
                                                    <div className="text-danger">{validation.errors.purchase_rate}</div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label>Shipping Rate</Label>
                                                <Input
                                                    type="number"
                                                    title="shipping_rate"
                                                    name="shipping_rate"
                                                    min="0"
                                                    placeholder={PlaceHolderFormat('shipping rate')}
                                                    value={validation.values.shipping_rate}
                                                    onBlur={validation.handleBlur}
                                                    onChange={(e) => {
                                                        const newValue = parseFloat(e.target.value);
                                                        if (newValue >= 0 || e.target.value === "") {
                                                            validation.handleChange(e);
                                                            handleShippingRateChange(newValue);
                                                        }
                                                    }}
                                                    disabled={isPublish === "Publish"}
                                                    invalid={!!(validation.touched.shipping_rate &&
                                                        validation.errors.shipping_rate)}
                                                ></Input>
                                                {validation.touched.shipping_rate &&
                                                    validation.errors.shipping_rate ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.shipping_rate}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label htmlFor="converted_rate">Converted Rate</Label>
                                                <Select
                                                    className={`${validation.touched.converted_rate && validation.errors.converted_rate && "is-invalid"}`}
                                                    id="converted_rate"
                                                    name="converted_rate"
                                                    options={conversionRateOptions}
                                                    value={conversionRateOptions.find((option: any) => option.value === validation.values.converted_rate)}
                                                    onChange={(selectedOption: ConvertedRateOptionType) => {
                                                        validation.setFieldValue('converted_rate', selectedOption.value);
                                                        handleConvertedRateChange(selectedOption.value);
                                                    }}
                                                    isDisabled={isPublish === "Publish"}
                                                    placeholder="Select..."
                                                />

                                                {validation.touched.converted_rate && validation.errors.converted_rate ? (
                                                    <div className="text-danger">{validation.errors.converted_rate}</div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label htmlFor="mrp">Purchase Rate ({productPurchase.audMark})</Label>
                                                <Input
                                                    type="number"
                                                    id="mrp"
                                                    name="mrp"
                                                    value={validation.values.mrp}
                                                    readOnly
                                                    placeholder='MRP'
                                                    disabled={isPublish === "Publish"}
                                                />
                                                {validation.touched.mrp && validation.errors.mrp ? (
                                                    <div className="text-danger">{validation.errors.mrp}</div>
                                                ) : null}
                                            </div>
                                        </Col>
                                        {/* <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label>Total Amount</Label>
                                                <Input
                                                    type="number"
                                                    title="total_amount"
                                                    name="total_amount"
                                                    placeholder={PlaceHolderFormat('total amount')}
                                                    value={totalAmount || viewAddedProductData?.total_amount}
                                                    onBlur={validation.handleBlur}
                                                    readOnly
                                                    onChange={validation.handleChange}
                                                    disabled={isPublish === "Publish"}
                                                    invalid={!!(validation.touched.total_amount &&
                                                        validation.errors.total_amount)}
                                                ></Input>
                                                {validation.touched.total_amount &&
                                                    validation.errors.total_amount ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.total_amount}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col> */}
                                        {/* <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label>Sale Rate</Label>
                                                <Input
                                                    type="number"
                                                    title="sale_rate"
                                                    name="sale_rate"
                                                    placeholder={PlaceHolderFormat('sale rate')}
                                                    value={validation.values.sale_rate}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    disabled={isPublish === "Publish"}
                                                    invalid={!!(validation.touched.sale_rate &&
                                                        validation.errors.sale_rate)}
                                                ></Input>
                                                {validation.touched.sale_rate &&
                                                    validation.errors.sale_rate ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.sale_rate}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col> */}
                                        <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label>Total Quantity</Label>
                                                <Input
                                                    type="number"
                                                    title="total_quantity"
                                                    name="total_quantity"
                                                    placeholder={PlaceHolderFormat('total quantity')}
                                                    value={totalQuantity || viewAddedProductData?.total_quantity}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    disabled
                                                    invalid={!!(validation.touched.total_quantity &&
                                                        validation.errors.total_quantity)}
                                                ></Input>
                                                {validation.touched.total_quantity &&
                                                    validation.errors.total_quantity ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.total_quantity}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        {/* <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label>Sale Rate</Label>
                                                <Input
                                                    type="number"
                                                    title="sale_rate"
                                                    name="sale_rate"
                                                    placeholder={PlaceHolderFormat('sale rate')}
                                                    value={validation.values.sale_rate}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    disabled={isPublish === "Publish"}
                                                    invalid={!!(validation.touched.sale_rate &&
                                                        validation.errors.sale_rate)}
                                                ></Input>
                                                {validation.touched.sale_rate &&
                                                    validation.errors.sale_rate ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.sale_rate}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col> */}
                                        <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label>Total Amount</Label>
                                                <Input
                                                    type="number"
                                                    title="total_amount"
                                                    name="total_amount"
                                                    placeholder={PlaceHolderFormat('total amount')}
                                                    value={totalAmount || viewAddedProductData?.total_amount}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    disabled
                                                    invalid={!!(validation.touched.total_amount &&
                                                        validation.errors.total_amount)}
                                                ></Input>
                                                {validation.touched.total_amount &&
                                                    validation.errors.total_amount ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.total_amount}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col lg={6}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Variant</th>
                                                        <th>MRP</th>
                                                        <th>Sale Rate</th>
                                                        <th>Quantity</th>
                                                        <th>Purchase Rate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {validation?.values?.variants?.map((variant: VariantsProps, index: number) => (
                                                        <tr key={index}>
                                                            <td>{variant.color} / {variant.size}</td>
                                                            <td>
                                                                <Input
                                                                    type="number"
                                                                    placeholder="MRP"
                                                                    value={variant.mrp}
                                                                    onChange={(e) => {
                                                                        const updatedVariants = [...validation.values.variants];
                                                                        updatedVariants[index].mrp = e.target.value;
                                                                        validation.setFieldValue('variants', updatedVariants);
                                                                    }}
                                                                    disabled={isPublish === "Publish"}
                                                                    onBlur={validation.handleBlur}
                                                                />
                                                                {validation.touched.variants?.[index]?.mrp && validation.errors.variants?.[index]?.mrp && (
                                                                    <div className="text-danger">{validation.errors.variants[index].mrp}</div>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    type="number"
                                                                    placeholder="Sale Rate"
                                                                    value={variant.sale_rate}
                                                                    onChange={(e) => {
                                                                        const updatedVariants = [...validation.values.variants];
                                                                        updatedVariants[index].sale_rate = e.target.value;
                                                                        validation.setFieldValue('variants', updatedVariants);
                                                                    }}
                                                                    disabled={isPublish === "Publish"}
                                                                    onBlur={validation.handleBlur}
                                                                />
                                                                {validation.touched.variants?.[index]?.sale_rate && validation.errors.variants?.[index]?.sale_rate && (
                                                                    <div className="text-danger">{validation.errors.variants[index].sale_rate}</div>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    type="number"
                                                                    placeholder="Quantity"
                                                                    value={variant.quantity}
                                                                    onChange={(e) => {
                                                                        const newValue = parseFloat(e.target.value);
                                                                        if (newValue >= 0 || e.target.value === "") {
                                                                            const updatedVariants = [...validation.values.variants];
                                                                            updatedVariants[index].quantity = e.target.value;
                                                                            validation.setFieldValue('variants', updatedVariants);
                                                                            validation.handleChange(e);
                                                                        }
                                                                    }}
                                                                    disabled={isPublish === "Publish"}
                                                                    onBlur={validation.handleBlur}
                                                                />
                                                                {validation.touched.variants?.[index]?.quantity && validation.errors.variants?.[index]?.quantity && (
                                                                    <div className="text-danger">{validation.errors.variants[index].quantity}</div>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    type="number"
                                                                    placeholder="Purchase Rate"
                                                                    value={variant.purchase_rate !== undefined ? variant.purchase_rate : validation.values.mrp}
                                                                    onChange={(e) => {
                                                                        const newValue = parseFloat(e.target.value);
                                                                        if (newValue >= 0 || e.target.value === "") {
                                                                            const updatedVariants = [...validation.values.variants];
                                                                            updatedVariants[index].purchase_rate = e.target.value;
                                                                            validation.setFieldValue('variants', updatedVariants);
                                                                            validation.handleChange(e);
                                                                        }
                                                                    }}
                                                                    disabled={isPublish === "Publish"}
                                                                    onBlur={validation.handleBlur}
                                                                />

                                                                {validation.touched.variants?.[index]?.purchase_rate && validation.errors.variants?.[index]?.purchase_rate && (
                                                                    <div className="text-danger">{validation.errors.variants[index].purchase_rate}</div>
                                                                )}
                                                            </td>
                                                            <div className="col-1">
                                                                <Button type="button" color="danger"
                                                                    disabled={isPublish === "Publish"}
                                                                    onClick={() => handleRemoveVariants(index, variant?.id)}
                                                                >
                                                                    <i className="ri-delete-bin-5-fill align-bottom"></i>
                                                                </Button>
                                                            </div>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </Col>
                                    </Row>

                                    <div className="text-end mb-3">
                                        <button
                                            color="none"
                                            className="btn btn-ghost-success m-2"
                                            onClick={() => {
                                                productId ?
                                                    navigate(`/productPurchase/${viewAddedProductData?.supplier_product_purchase_id?.id}`)
                                                    :
                                                    navigate(`/productPurchase/${supplierPurchaseId}`)
                                            }}
                                        >
                                            {categoryLabels.cancel}
                                        </button>
                                        <button type="submit" className="btn btn-primary w-sm"
                                            disabled={isPublish === "Publish"}
                                        >
                                            {productId ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    )}
                </Container>
            }
        </div>
    )
}

export default AddProductPurchase