import { Card, CardBody, Col } from 'reactstrap';
import CountUp from 'react-countup';

interface Props {
  title: string,
  count: number | undefined,
  iconClass: string,
  bgColorClass: string,
  iconTextClass: string,
  decimals: number
}

const DataCard = ({ title, count, iconClass, bgColorClass, iconTextClass, decimals }: Props) => {
  return (
    <Col xl={4} md={6}>
      <Card className="card-animate">
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{title}</p>
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-between mt-4">
            <div>
              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                <span className="counter-value">
                  <CountUp
                    start={0}
                    end={count ?? 0}
                    duration={10}
                    decimals={decimals}
                    decimal="."
                    separator=","
                  />
                </span>
              </h4>
            </div>
            <div className="avatar-sm flex-shrink-0">
              <span className={`avatar-title rounded fs-3 ${bgColorClass}`}>
                <i className={`${iconTextClass} ${iconClass}`}></i>
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DataCard;
