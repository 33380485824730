import LoaderBlur from 'Components/Common/BlurLoader';
import { listOfColor, listOfSizeRange } from 'api/colorAndSize';
import { deleteProduct } from 'api/productApi';
import { sizeRangeData } from 'api/productPurchase';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { Button, Col, Form, Input, Label, Row, Table } from 'reactstrap';
import { categoryLabels, productPurchase, productPurchaseEntry, salesLabels, sizeRangeLabels } from 'utils/helpers/constant';
import { RolesEnum } from 'utils/helpers/enums';
import { getRole } from 'utils/helpers/sessionStores';
import { PlaceHolderFormat, RequiredField } from 'utils/helpers/validationRequiredMessages';
import * as Yup from "yup";

interface SelectOptionSize {
    id: number,
    size_range: string
}

interface SelectOption {
    value: number;
    label: string;
}

interface OptionType {
    label: string;
    value: number;
}
interface Props {
    purchaseRateAUD: any,
    onUpdate?: any;
    updateData: any,
    isOpen: boolean,
    onFormSubmit: any,
    purchaseRate: undefined | null | number,
    status: any
}
interface ColorOption {
    value: string;
    label: string;
}
interface ColorGroup {
    color: ColorOption;
    quantities: { [key: string]: { quantity: number, id: number } };
}

interface Variant {
    id: number;
    color: string;
    size: string;
    quantity: number;
}
interface SizeData {
    id: number;
    size_range: string;
    from_size: string;
    to_size: string;
    sizes: string[];
}
const DynamicColumnWithProduct = ({ purchaseRateAUD, onUpdate, updateData, purchaseRate, isOpen, onFormSubmit, status }: Props) => {
    const { productId }: any = useParams();
    const [sizesOptions, setSizesOptions] = useState<{ value: number; label: string }[]>([]);
    const [sizesData, setSizesData] = useState<SizeData>();
    const [selectedSize, setSelectedSize] = useState<number>();
    const [splitSizeData, setSplitSizeData] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [listOfColorData, setListOfColorData] = useState();
    const [fromAndToSizeData, setFromAndToSizeData] = useState();
    const role = getRole();

    const fetchSize = () => {
        setIsLoading(true);
        listOfSizeRange({}).then((response) => {
            setSizesOptions(response?.data?.map((size: SelectOptionSize) => ({ value: size.id, label: size.size_range })));
            setFromAndToSizeData(response?.data)
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const fetchColor = () => {
        setIsLoading(true);
        listOfColor({}).then((response) => {
            setListOfColorData(response?.data?.map((color: { id: number, color_name: string }) => ({ value: color.color_name, label: color.color_name })))
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (updateData?.size_range_id?.id) {
            const preFilledValue = updateData?.size_range_id?.id;

            setSelectedSize(preFilledValue);

            const preFilledOption = sizesOptions?.find((option: SelectOption) => option?.value === preFilledValue);

            if (preFilledOption) {
                handleSizeChange(preFilledOption);
            }
        }
    }, [updateData, sizesOptions]);
    // As of now i have commented code because of its giving the empty fields but i need in future.
    // useEffect(() => {
    //     if (updateData) {
    //         // Prefill saleRate and mrp with values from updateData if available
    //         const saleRate = updateData?.product_variant?.[0]?.sale_rate || '';
    //         const mrp = updateData?.product_variant?.[0]?.mrp || '';
    //         setInitialValues((prevValues: any) => ({
    //             ...prevValues,
    //             saleRate: !isNaN(parseFloat(saleRate)) ? parseFloat(saleRate) : purchaseRateAUD,
    //             mrp: !isNaN(parseFloat(mrp)) ? parseFloat(mrp) : purchaseRateAUD,
    //         }));
    //     } else {
    //         // If there's no updateData, prefills with purchaseRateAUD
    //         setInitialValues((prevValues: any) => ({
    //             ...prevValues,
    //             saleRate: !isNaN(parseFloat(purchaseRateAUD)) ? parseFloat(purchaseRateAUD) : '',
    //             mrp: !isNaN(parseFloat(purchaseRateAUD)) ? parseFloat(purchaseRateAUD) : '',
    //         }));
    //     }
    // }, [updateData, purchaseRateAUD]);

    const handleSizeChange = (selectedOption: SelectOption) => {
        setIsLoading(true);
        setSelectedSize(selectedOption?.value);

        const selectedSizeOption = sizesOptions?.find((option: { value: number }) => option.value === selectedOption.value);

        if (selectedSizeOption) {
            sizeRangeData(selectedSizeOption.value).then((response) => {
                setSizesData(response?.data);
                setFromAndToSizeData(response?.data)
            }).catch((err) => {
                return err;
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
            setSizesData(undefined);
        }
    };

    useEffect(() => {
        Promise.all([
            fetchSize(),
            fetchColor()
        ])
    }, []);

    const validateColor = (selectedColors: string[], index: number, value: string) => {
        // Get colors in the same row except the current one
        const colorsInRow = validation.values.colorList
            ?.filter((_: any, i: number) => i !== index)
            ?.map((colorGroup: any) => colorGroup.color);

        // Check if the selected color is unique within the row
        return !colorsInRow?.includes(value);
    };

    // Update the validation schema for colorList
    Yup.object().shape({
        size: Yup.string().required(RequiredField(sizeRangeLabels.size)),
        saleRate: Yup.string().required(RequiredField(productPurchase.saleRate)),
        mrp: Yup.string().required(RequiredField(productPurchase?.mrp)),
        colorList: Yup.array().of(
            Yup.object().shape({
                color: Yup.string()
                    .required(RequiredField(productPurchase?.color))
                    .test('uniqueColor', 'Please select a different color in this row.', function (value) {
                        return validateColor(this.parent.colorList?.map((color: ColorGroup) => color.color), this.parent.indexOf(this), value);
                    }),
                quantities: Yup.object().test('atLeastOneQuantity', RequiredField(productPurchase?.atLeastOneQuantity), (quantities) => {
                    return Object.values(quantities).some((quantity: any) => quantity.quantity > 0);
                })
            })
        )
    })


    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [initialValues, setInitialValues] = useState({
        size: '',
        fromSize: '',
        toSize: '',
        purchaseRate: purchaseRateAUD || purchaseRate,
        saleRate: updateData?.product_variant?.[0]?.sale_rate || (purchaseRateAUD && purchaseRateAUD) || '',
        mrp: updateData?.product_variant?.[0]?.mrp || purchaseRateAUD || '',
        colorList: [{ color: '', quantities: {} }],
    });
    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            size: Yup.string().required(RequiredField(sizeRangeLabels.size)),
            saleRate: Yup.string().required(RequiredField(productPurchase.saleRate)),
            mrp: Yup.string().required(RequiredField(productPurchase?.mrp)),
            colorList: Yup.array().of(
                Yup.object().shape({
                    color: Yup.string().required(RequiredField(productPurchase?.color)),
                    quantities: Yup.object().test('atLeastOneQuantity', RequiredField(productPurchase?.atLeastOneQuantity), (quantities) => {
                        return Object.values(quantities).some((quantity: any) => quantity.quantity > 0);
                    })
                })
            )
        }),
        onSubmit: (value) => {
            // Handle form submission here
        }
    });

    useEffect(() => {
        if (updateData && !initialDataLoaded) {
            const colorGroups: { [key: string]: ColorGroup } = {};
            updateData?.product_variant?.forEach((item: Variant) => {
                const { id, color, size, quantity }: any = item;

                if (!colorGroups[color]) {
                    colorGroups[color] = {
                        color: color,
                        quantities: {}, // Object to store quantities for each size
                    };
                }
                colorGroups[color].quantities[size] = { quantity, id }; // Include id if variant exists
            });

            // Merge pre-filled quantities into colorGroups if available
            if (updateData?.colorList) {

                updateData?.colorList?.forEach((colorItem: any) => {
                    const { color, quantities } = colorItem?.color;

                    if (colorGroups[color]) {
                        colorGroups[color].quantities = { ...colorGroups[color].quantities, ...quantities };
                    }
                });
            }

            const groupedVariants: any = Object?.values(colorGroups);

            setInitialValues({
                size: updateData?.size_range_id?.id || '',
                fromSize: '',
                toSize: '',
                purchaseRate: purchaseRateAUD || purchaseRate,
                saleRate: updateData?.product_variant?.[0]?.sale_rate || '', //here i do the hard code because the BE give that and that is never change.
                mrp: updateData?.product_variant?.[0]?.mrp || '',
                colorList: groupedVariants,
            });

            setInitialDataLoaded(true);
        }
    }, [updateData, purchaseRateAUD, initialDataLoaded, purchaseRate]);

    useEffect(() => {
        validation.setFieldValue('mrp', validation.values.saleRate);
    }, [validation.values.saleRate]);

    useEffect(() => {
        if (onFormSubmit === true) {
            validation.handleSubmit();
        }
    }, [onFormSubmit === true])

    useEffect(() => {
        onUpdate(validation.values);
    }, [validation.values, onUpdate]);
    const handleAddReplicaRow = () => {
        validation.handleSubmit();
        if (validation?.isValid) {
            const lastColor = validation.values.colorList[validation.values.colorList?.length - 1]; //here i have add the logic id its first row then not hide just empty that row.
            const newQuantities: { [key: string]: { quantity: number, id: string } } = {};

            // Copy quantities from the last row without the id field
            Object.keys(lastColor.quantities).forEach((size) => {
                newQuantities[size] = { quantity: lastColor.quantities[size].quantity, id: '' };
            });

            const newColor = { color: '', quantities: newQuantities };

            validation.setFieldValue('colorList', [...validation.values.colorList, newColor]);
        }
    };

    const handleQuantityChange = (colorIndex: number, size: string, quantity: number) => {
        if (quantity >= 0 || isNaN(quantity)) {
            const newColorList = [...validation.values.colorList];
            const colorToUpdate = newColorList[colorIndex];
            const updatedQuantities = { ...colorToUpdate.quantities };

            // Update or add the quantity for the size
            updatedQuantities[size] = { ...(updatedQuantities[size] || {}), quantity };

            const updatedColor = { ...colorToUpdate, quantities: updatedQuantities };
            newColorList[colorIndex] = updatedColor;
            validation.setFieldValue('colorList', newColorList);
        }
    };

    const handleDeleteVariant = async (colorIndex: number) => {
        const colorToDelete = validation.values.colorList[colorIndex];
        const colorName = colorToDelete.color;
        if (updateData) {
            setIsLoading(true);
            const payload = {
                product_id: parseInt(productId),
                color: colorName
            }
            deleteProduct(payload).then((res) => {
                const newColorList = [...validation.values.colorList];
                newColorList.splice(colorIndex, 1);
                validation.setFieldValue('colorList', newColorList);
                toast.success(res?.message);
            }).catch((err) => {
                toast.error(err?.message)
            }).finally(() => {
                setIsLoading(false)
            })
        } else {
            // If updateData is not present, delete the variant locally
            const newColorList = [...validation.values.colorList];
            if (colorIndex === 0) {
                // If it's the first row, empty the quantities of that row
                newColorList[colorIndex].quantities = {};
                newColorList[colorIndex].color = '';
            } else {
                // If it's not the first row, remove the entire row
                newColorList.splice(colorIndex, 1);
            }
            validation.setFieldValue('colorList', newColorList);
        }
    };
    const [selectedColors, setSelectedColors] = useState<string[]>([]);
    const handleColorChange = (index: number, selectedColor: string) => {
        // Check if the selected color is already present in colorList
        const isColorSelectedInList = validation.values.colorList.some((colorGroup: any, i: number) => i !== index && colorGroup.color === selectedColor);

        if (isColorSelectedInList) {
            // Set validation error message if the color is already selected in the list
            validation.setFieldError(`colorList[${index}].color`, "This color has already been selected in a previous row.");
        } else {
            validation.setFieldValue(`colorList[${index}].color`, selectedColor);
            validation.setFieldTouched(`colorList[${index}].color`, true, false); // Mark color field as touched
            validation.validateField(`colorList[${index}].color`); // Trigger validation for the color field

            // Add the selected color to the list of selected colors
            setSelectedColors(prevSelectedColors => [...prevSelectedColors, selectedColor]);
        }
    };


    const getOptionsWithSelected = (options: any, selectedValue: string): SelectOption[] => {
        if (selectedValue && !options?.some((option: any) => option?.value === selectedValue)) {
            return [...options, { value: selectedValue, label: selectedValue }];
        }
        return options;
    };

    const productSizeSelected = getOptionsWithSelected(sizesOptions, validation.values.size);


    return (
        <div>
            <Row>
                <Col lg={4}>
                    <div className="mb-3">
                        <Label>{productPurchase?.selectSize}</Label>
                        <Select
                            className={`${validation.touched.size && validation.errors.size && "is-invalid"}`}
                            value={sizesOptions?.find((option: SelectOption) => option.value === validation.values.size)}
                            onChange={(selectedOption: SelectOption) => {
                                setSplitSizeData(selectedOption?.label)
                                validation.setFieldValue('size', selectedOption?.value || '');
                                handleSizeChange(selectedOption);
                            }}
                            options={sizesOptions}
                            isDisabled={role === RolesEnum?.STAFF || updateData?.product_status === productPurchaseEntry?.onlineStatus && status !== "Online"}
                        />
                        {validation.touched.size && validation.errors.size && (
                            <div className="error-message-drop">{validation.errors.size}</div>
                        )}
                    </div>
                </Col>
                <Col lg={4}>
                    <div className='mb-3'>
                        <Label>{productPurchase?.fromSize}</Label>
                        <Input
                            type="text"
                            name="fromSize"
                            id="fromSize"
                            placeholder={PlaceHolderFormat(productPurchase?.fromSize)}
                            value={(updateData ? updateData?.size_range_id?.from_size : sizesData?.from_size)}
                            disabled={role === RolesEnum?.STAFF || status !== "Online"}
                        />
                    </div>
                </Col>
                <Col lg={4}>
                    <div className='mb-3'>
                        <Label>{productPurchase?.toSize}</Label>
                        <Input
                            type="text"
                            name="toSize"
                            id="toSize"
                            placeholder={PlaceHolderFormat(productPurchase?.toSize)}
                            value={(updateData ? updateData?.size_range_id?.to_size : sizesData?.to_size)}
                            disabled={role === RolesEnum?.STAFF || status !== "Online"}
                        />
                    </div>
                </Col>
            </Row>
            {role !== RolesEnum?.STAFF && <Row>
                {(isOpen === true || updateData !== undefined) && <Col md={4}>
                    <Label for="purchaseRate">{productPurchase?.purchaseRate}</Label>
                    <Input
                        type="number"
                        name="purchaseRate"
                        id="purchaseRate"
                        placeholder={PlaceHolderFormat(productPurchase?.purchaseRate)}
                        value={purchaseRateAUD || purchaseRate}
                        disabled={status === "Online"}
                    />
                </Col>}
                <Col md={4}>
                    <Label for="saleRate">{productPurchase?.saleRate}</Label>
                    <Input
                        type="number"
                        name="saleRate"
                        placeholder={PlaceHolderFormat(productPurchase?.saleRate)}
                        id="saleRate"
                        value={validation.values.saleRate}
                        onChange={validation?.handleChange}
                        onBlur={validation?.handleBlur}
                        disabled={updateData?.product_status === productPurchaseEntry?.onlineStatus && status === "Online"}
                        invalid={!!(validation.touched.saleRate &&
                            validation.errors.saleRate)}
                    />
                    {validation.touched.saleRate && validation.errors.saleRate && (
                        <div className="error-message-drop">{validation.errors.saleRate}</div>
                    )}
                </Col>
                <Col md={4}>
                    <Label for="mrp">{productPurchase?.mrp} ({productPurchase.comparativeRate})</Label>
                    <Input
                        type="number"
                        name="mrp"
                        placeholder={PlaceHolderFormat(productPurchase?.mrp)}
                        id="mrp"
                        value={validation?.values?.mrp}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        disabled={updateData?.product_status === productPurchaseEntry?.onlineStatus && status === "Online"}
                        invalid={!!(validation.touched.mrp &&
                            validation.errors.mrp)}
                    />
                    {validation.touched.mrp && validation.errors.mrp && (
                        <div className="error-message-drop">{validation.errors.mrp}</div>
                    )}
                </Col>
            </Row>}
            {isLoading ? <LoaderBlur /> : (
                <>{selectedSize !== undefined && (
                    <div className='my-3'>
                        <h5> {productPurchase?.sizeList}</h5>
                        <Table responsive className='mb-0 align-middle table-border'>
                            <thead className='table-light text-muted table-wrap'>
                                <tr>
                                    <th>{productPurchase?.delete}</th>
                                    <th>{productPurchase?.color}</th>
                                    {sizesData?.sizes?.map((size, index) => (
                                        <th key={size}>{size}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {validation.values.colorList?.map((color: ColorGroup, index: number) => (
                                    <tr key={index}>
                                        <td><Button color="danger" disabled={role === RolesEnum?.STAFF || updateData?.product_status === productPurchaseEntry?.onlineStatus && status !== "Online"} onClick={() => { handleDeleteVariant(index) }}> <i className="ri-delete-bin-5-fill align-bottom" /></Button></td>
                                        <td>
                                            <CreatableSelect
                                                value={{ value: color.color, label: color.color }}
                                                // onChange={(newValue: any) => validation.setFieldValue(`colorList[${index}].color`, newValue.value)}  need this line for future
                                                onChange={(newValue: any) => handleColorChange(index, newValue.value)}
                                                options={listOfColorData}
                                                placeholder="Select..."
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: '150px',
                                                    }),
                                                }}
                                                isDisabled={role === RolesEnum?.STAFF || updateData?.product_status === productPurchaseEntry?.onlineStatus && status !== "Online"}
                                                menuPortalTarget={document.body}
                                            />
                                            {validation.touched.colorList && validation.errors.colorList && validation.errors.colorList[index]?.color && (
                                                <div className="error-message-drop">{validation.errors.colorList[index].color}</div>
                                            )}
                                        </td>
                                        {sizesData?.sizes?.map((size, sizeIndex) => (
                                            <td key={sizeIndex}>
                                                <Input
                                                className='dynamicColumnWidth'
                                                    type="number"
                                                    value={(color.quantities[size] && color.quantities[size].quantity) || ''}
                                                    onChange={(e) => handleQuantityChange(index, size, parseInt(e.target.value))}
                                                    disabled={role === RolesEnum?.STAFF || updateData?.product_status === productPurchaseEntry?.onlineStatus && status !== "Online"}
                                                />
                                                {sizeIndex === 0 && 
                                                    validation.touched.colorList &&
                                                    validation.errors.colorList &&
                                                    validation.errors.colorList[index]?.quantities &&
                                                    validation.errors.colorList[index] && (
                                                        <div className="error-message-drop">{validation.errors.colorList[index]?.quantities}</div>
                                                    )
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}</>
            )}
            <Row>
                <div className='d-flex justify-content-end align-items-center w-100'>
                    <Button className='btn-soft-success m-2' disabled={role === RolesEnum?.STAFF || updateData?.product_status === productPurchaseEntry?.onlineStatus && status !== "Online"} onClick={handleAddReplicaRow}>{productPurchase?.addReplica}</Button>
                </div>
            </Row>
        </div>
    )
}

export default DynamicColumnWithProduct;
