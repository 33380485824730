import React, { useEffect, useState } from "react";
import TableContainer from "Components/Common/TableContainer";
import Flatpickr from "react-flatpickr";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Label,
    Row,
    Button,
} from "reactstrap";
import Select from "react-select";
import {
    ResponseStatusEnum,
    orderTypeOption,
} from "../../utils/helpers/enums";
import {
    listOfStaff,
    salesReport,
    customerReport,
    supplierReport,
    listOfCustomer,
} from "../../api/reportApi";
import { dateFormat, dateFormatChange } from "utils/helpers/comman";
import { notFound, purchaseProductLabels, salesLabels, customerLabels, supplierReportLabels, currencyFormat, billLabels } from "utils/helpers/constant";
import { listOfSuppliesOptions } from "api/dropDownApi";
import { listOfProduct } from "api/productApi";
import LoaderBlur from "Components/Common/BlurLoader";

interface OptionType {
    value: string;
    label: string;
}

interface Payload {
    startDate?: string;
    endDate?: string;
    orderStatus?: string;
    orderType?: string;
    saleAccordingRole?: string;
    customerName?: string;
    supplierId?: string;
    productName?: string;
    pageNumber?: number;
    pageSize: number;
}

interface ReportFilterTableProps {
    reportType: 'sales' | 'customer' | 'supplier';
}

const Report: React.FC<ReportFilterTableProps> = ({ reportType }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [orderStatus, setOrderStatus] = useState<OptionType | null>(null);
    const [orderTypeStatus, setOrderTypeStatus] = useState<OptionType | null>(null);
    const [filterByNameOption, setFilterByNameOption] = useState<OptionType[] | []>([]);
    const [filterByNames, setFilterByNames] = useState<OptionType | null>(null);
    const [reportData, setReportData] = useState([]);
    const [date, setDate] = useState('');
    const [refreshKey, setRefreshKey] = useState(0);
    const [supplierNameList, setSupplierNameList] = useState<OptionType[] | []>([]);
    const [selectedSupplierName, setSelectedSupplierName] = useState<OptionType | null>(null);
    const [productNameList, setProductNameList] = useState<OptionType[] | []>([]);
    const [selectedProductName, setSelectedProductName] = useState<OptionType | null>(null);
    const [customerListData, setCustomerListData] = useState<OptionType[] | []>([]);
    const [customerName, setCustomerName] = useState<OptionType | null>(null);
    const [startDate, setStartDate] = useState<Date | any>('');
    const [endDate, setEndDate] = useState<Date | any>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);

    const getReportColumns = () => {
        switch (reportType) {
            case "sales":
                return [
                    {
                        header: salesLabels.orderDate, accessorKey: `${salesLabels.orderDateAccessKey}`, enableColumnFilter: false,
                        cell: (cell: { row: { original: { orderDate: string } } }) => {
                            const formattedDate = dateFormat(cell?.row?.original?.orderDate)
                            return <>{formattedDate ?? notFound?.nullData}</>;
                        },
                    },
                    { header: salesLabels.totalProducts, accessorKey: `${salesLabels.totalProductsAccessKey}`, enableColumnFilter: false },
                    { header: salesLabels.totalOrders, accessorKey: `${salesLabels.totalOrdersAccessKey}`, enableColumnFilter: false },
                    {
                        header: salesLabels.totalAmount, accessorKey: `${salesLabels.totalAmountAccessKey}`, enableColumnFilter: false,
                        cell: (cell: { row: { original: { totalAmount: number } } }) => (
                            <span>{currencyFormat?.currency}{cell.row.original.totalAmount}</span>
                        ),
                    },
                ];
            case "customer":
                return [
                    {
                        header: customerLabels.registerDate, accessorKey: `${billLabels.date}`, enableColumnFilter: false,
                        cell: (cell: { row: { original: { date: string } } }) => {
                            const formattedDate = dateFormat(cell?.row?.original?.date)
                            return <>{formattedDate ?? notFound?.nullData}</>;
                        },
                    },
                    {
                        header: customerLabels.customerName, accessorKey: `${customerLabels.customerNameAccessKey}`, enableColumnFilter: false, cell: (cell: { row: { original: { customerName: string | null } } }) => {
                            const customerName = cell?.row?.original?.customerName
                            return <> {customerName ?? notFound?.nullData}</>;
                        },
                    },
                    {
                        header: customerLabels.email, accessorKey: `${customerLabels.emailAccessKey}`, enableColumnFilter: false, cell: (cell: { row: { original: { email: string | null } } }) => {
                            const email = cell?.row?.original?.email
                            return <> {email !== '' ? email : notFound?.nullData}</>;
                        },
                    },
                    {
                        header: customerLabels.customerContactNo, accessorKey: `${customerLabels.customerContactNoAccessKey}`, enableColumnFilter: false,
                        cell: (cell: { row: { original: { customerContactNo: string | null } } }) => {
                            const customerContactNo = cell?.row?.original?.customerContactNo
                            return <> {customerContactNo ?? notFound?.nullData}</>;
                        },
                    },
                    {
                        header: customerLabels.orderCount, accessorKey: `${customerLabels.orderCountAccessKey}`, enableColumnFilter: false
                    },
                    {
                        header: customerLabels.productCount, accessorKey: `${customerLabels.productCountAccessKey}`, enableColumnFilter: false
                    },
                    {
                        header: customerLabels.totalAmount, accessorKey: `${customerLabels.totalAmountAccessKey}`, enableColumnFilter: false,
                        cell: (cell: { row: { original: { totalAmount: number } } }) => (
                            <span>{currencyFormat?.currency}{cell.row.original.totalAmount}</span>
                        ),
                    },
                ];
            case "supplier":
                return [
                    {
                        header: customerLabels.billDate, accessorKey: `${customerLabels.billDateAccessKey}`, enableColumnFilter: false,
                        cell: (cell: { row: { original: { billDate: string } } }) => {
                            const formattedDate = dateFormat(cell?.row?.original?.billDate)
                            return <>{formattedDate ?? notFound?.nullData}</>;
                        },
                    },
                    {
                        header: supplierReportLabels.supplierName,
                        accessorKey: `${supplierReportLabels.supplierNameAccessKey}`,
                        enableColumnFilter: false,
                    },
                    {
                        header: supplierReportLabels.productName,
                        accessorKey: `${supplierReportLabels.productNameAccessKey}`,
                        enableColumnFilter: false,
                    },
                    {
                        header: supplierReportLabels.quantity,
                        accessorKey: `${supplierReportLabels.totalQuantityAccessKey}`,
                        enableColumnFilter: false,
                    },
                    {
                        header: supplierReportLabels.totalAmount,
                        accessorKey: `${supplierReportLabels.totalAmountAccessKey}`,
                        enableColumnFilter: false,
                        cell: (cell: { row: { original: { totalAmount: number } } }) => (
                            <span>{currencyFormat?.currency}{cell.row.original.totalAmount}</span>
                        ),
                    },
                ];
            default:
                return [];
        }
    };

    const fetchReportData = async (payload: any) => {
        setIsLoading(true);
        try {
            let data;
            switch (reportType) {
                case "sales":
                    data = await salesReport(payload);
                    break;
                case "customer":
                    data = await customerReport(payload);
                    break;
                case "supplier":
                    data = await supplierReport(payload);
                    break;
                default:
                    console.error("Unknown report type:", reportType);
                    return;
            }
            if (data?.statusCode && ResponseStatusEnum.SUCCESS.includes(data.statusCode)) {
                let reportData = data?.data?.listOfOrderData;
                setTotalRecords(data?.data?.totalRecordsCount)
                setTotalPages(data?.data?.totalPages)
                setTotalNumberOfRows(data?.data?.numberOfRows)
                setReportData(reportData);
            } else {
                setReportData([]);
            }
        } catch (error) {
            return error;
            setReportData([]);
        } finally {
            setIsLoading(false);
        }
    };

    const listOfStaffData = () => {
        listOfStaff()
            .then((response) => {
                if (response.statusCode && ResponseStatusEnum.SUCCESS.includes(response.statusCode)) {
                    const data = response.data.map((item: any) => ({
                        value: item.staff_name,
                        label: item.staff_name,
                    }));
                    setFilterByNameOption(data);
                } else {
                    setFilterByNameOption([]);
                }
            })
            .catch(() => setFilterByNameOption([]));
    };

    const listOfCustomerData = () => {
        listOfCustomer().then((response) => {
            if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                const listdata = response?.data?.map((item: any) => ({
                    value: item.id,
                    label: item.contact_name
                }));
                setCustomerListData(listdata);
            } else setCustomerListData([]);
        }).catch((err) => setCustomerListData([]));
    };

    const listOfSuppliersName = () => {
        listOfSuppliesOptions({})
            .then((response) => {
                if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    const data = response?.data?.map((item: any) => ({
                        value: item.id,
                        label: item.supplier_name
                    }));
                    setSupplierNameList(data);
                } else setSupplierNameList([]);
            }).catch((err) => setSupplierNameList([]));
    }

    const listOfProductName = () => {
        listOfProduct({})
            .then((response) => {
                if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    const data = response?.data?.listOfProductData?.map((item: any) => ({
                        value: item.id,
                        label: item.product_name
                    }));
                    setProductNameList(data);
                } else setProductNameList([]);
            }).catch((err) => setProductNameList([]));
    }

    useEffect(() => {
        if (reportType === "customer") {
            listOfCustomerData();
        }
    }, [reportType, refreshKey])

    useEffect(() => {
        if (reportType === "supplier") {
            Promise.all([listOfProductName(), listOfSuppliersName()]);
        }
        const formatDateString = (date: Date | null) => {
            return date ? dateFormatChange(date) : undefined;
        };

        Promise.all([fetchReportData({
            sortOrder: {
                id: -1
            },
            orderStatus: orderStatus?.label,
            orderType: orderTypeStatus?.label,
            customerName: customerName?.label,
            saleAccordingRole: filterByNames?.label,
            supplierId: selectedSupplierName?.value,
            productName: selectedProductName?.label,
            pageNumber: currentPage,
            pageSize: 5,
            startDate: formatDateString(startDate),
            endDate: formatDateString(endDate),

        })])
    }, [reportType, refreshKey, currentPage]);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const formatDateString = (date: Date | null) => {
            return date ? dateFormatChange(date) : undefined;
        };

        const payload: Payload = {
            orderStatus: orderStatus?.label,
            orderType: orderTypeStatus?.label,
            customerName: customerName?.label,
            saleAccordingRole: filterByNames?.label,
            supplierId: selectedSupplierName?.value,
            productName: selectedProductName?.label,
            pageNumber: currentPage,
            pageSize: 5,
            startDate: formatDateString(startDate),
            endDate: formatDateString(endDate),
        };
        fetchReportData(payload);
    };

    const formatReportType = (type: string) => {
        const cleanType = type.replace(/^\//, '');
        const titleCase = cleanType.replace(/([A-Z])/g, " $1").trim();
        return titleCase.replace(/\b\w/g, char => char.toUpperCase());
    };

    const handleRefresh = () => {
        setRefreshKey(prevKey => prevKey + 1);
        setDate('');
        setEndDate('');
        setStartDate('')
        setOrderStatus(null);
        setSelectedProductName(null);
        setSelectedSupplierName(null);
        setOrderTypeStatus(null);
        setFilterByNames(null);
        setCustomerName(null);
        setCurrentPage(0)
    };

    const handleFetchData = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className="page-content">
            {isLoading && <LoaderBlur />}
            <Container>
                <Row className="mb-3">
                    <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0 d-flex justify-content-between">
                        <h5 className="fw-semibold mb-0">
                            {formatReportType(reportType)}
                        </h5>
                    </div>
                </Row>
                <Card>
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Col sm={12} md={3} lg={2} className="mb-3 mb-md-0">
                                    <Label>Start Date</Label>
                                    <Flatpickr
                                        className="form-control"
                                        placeholder="Enter Start Date"
                                        options={{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', maxDate: new Date() }}
                                        value={startDate}
                                        onChange={(date) => setStartDate(date[0] || null)}
                                    />
                                </Col>
                                <Col sm={12} md={3} lg={2} className="mb-3 mb-md-0">
                                    <Label>End Date</Label>
                                    <Flatpickr
                                        className="form-control"
                                        placeholder="Enter End Date"
                                        options={{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', maxDate: new Date() }}
                                        value={endDate}
                                        onChange={(date) => setEndDate(date[0] || null)}
                                    />
                                </Col>
                                {reportType === "sales" && (
                                    <>
                                        <Col sm={12} md={3} lg={2} className="mb-3 mb-md-0">
                                            <Label>Order Type</Label>
                                            <Select
                                                name="order_type"
                                                className="mb-3"
                                                options={orderTypeOption}
                                                onChange={(selectedOption: OptionType) => {
                                                    if (selectedOption?.value === "OFFLINE") {
                                                        listOfStaffData();
                                                    }
                                                    setOrderTypeStatus(selectedOption);
                                                }}
                                                value={orderTypeStatus}
                                                isClearable
                                            />
                                        </Col>
                                        {orderTypeStatus?.value === "OFFLINE" && <Col sm={12} md={3} lg={2} className="mb-3 mb-md-0">
                                            <Label>Sales By</Label>
                                            <Select
                                                id="saleAccordingRole"
                                                name="saleAccordingRole"
                                                options={filterByNameOption}
                                                placeholder="Filter By Name"
                                                classNamePrefix="select2-selection"
                                                onChange={setFilterByNames}
                                                value={filterByNames}
                                                isClearable
                                            />
                                        </Col>}
                                    </>
                                )}
                                {reportType === "supplier" && (
                                    <>
                                        <Col sm={12} md={3} lg={2} className="mb-3 mb-md-0">
                                            <Label>{supplierReportLabels?.supplierName}</Label>
                                            <Select
                                                name="supplier_name"
                                                className="mb-3"
                                                options={supplierNameList}
                                                onChange={(selectedOption: OptionType) => setSelectedSupplierName(selectedOption)}
                                                value={selectedSupplierName}
                                                isClearable
                                            />
                                        </Col>
                                        <Col sm={12} md={3} lg={2} className="mb-3 mb-md-0">
                                            <Label>{supplierReportLabels?.productName}</Label>
                                            <Select
                                                name="product_name"
                                                className="mb-3"
                                                options={productNameList}
                                                onChange={(selectedOption: OptionType) => setSelectedProductName(selectedOption)}
                                                value={selectedProductName}
                                                isClearable
                                            />
                                        </Col>
                                    </>
                                )}
                                {reportType === "customer" && (
                                    <>
                                        <Col sm={12} md={3} lg={2} className="mb-3 mb-md-0">
                                            <Label>{customerLabels?.customerName}</Label>
                                            <Select
                                                name="customer_name"
                                                className="mb-3"
                                                options={customerListData}
                                                onChange={(selectedOption: OptionType) => {
                                                    setCustomerName(selectedOption);
                                                }}
                                                value={customerName}
                                                isClearable
                                            />
                                        </Col>
                                        <Col sm={12} md={3} lg={2} className="mb-3 mb-md-0">
                                            <Label>{purchaseProductLabels?.orderType}</Label>
                                            <Select
                                                name="orderType"
                                                className="mb-3"
                                                options={orderTypeOption}
                                                onChange={(selectedOption: OptionType) => {
                                                    if (selectedOption?.value === 'OFFLINE') {
                                                        listOfStaffData();
                                                    }
                                                    setOrderTypeStatus(selectedOption);
                                                }}
                                                value={orderTypeStatus}
                                                isClearable
                                            />
                                        </Col>
                                    </>
                                )}
                                {/* I need this code */}
                                {/* {reportType === "customer" &&
                                    orderTypeStatus?.value === "OFFLINE" && <Col sm={12} md={3} lg={2} className="mb-3 mb-md-0">
                                        <Label>Sales By</Label>
                                        <Select
                                            id="saleAccordingRole"
                                            name="saleAccordingRole"
                                            options={filterByNameOption}
                                            placeholder="Filter By Name"
                                            classNamePrefix="select2-selection"
                                            onChange={setFilterByNames}
                                            value={filterByNames}
                                            isClearable
                                        />
                                    </Col>} */}
                                <Col sm={12} md={2} lg={2} className="mb-3 mb-md-0 d-flex align-items-end">
                                    <button type="submit" className="btn btn-primary w-100 buttonReport">
                                        <i className="ri-equalizer-fill me-2 align-bottom"></i>
                                        Apply Filters
                                    </button>
                                </Col>
                                <Col sm={12} md={1} lg={1} className="mb-3 mb-md-0 d-flex align-items-end">
                                    <Button color="success" onClick={handleRefresh} className="buttonReport">
                                        <i className="ri-refresh-line"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <hr />
                        <div className="card-body pt-0">
                            {reportData && reportData?.length > 0 ? (
                                <TableContainer
                                    columns={getReportColumns()}
                                    data={reportData}
                                    isGlobalFilter={false}
                                    customPageSize={5}
                                    divClass="table-responsive mb-1"
                                    tableClass="mb-0 align-middle table-border table-wrap"
                                    theadClass="table-light text-muted"
                                    manualPagination={true}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalPages={totalPages}
                                    totalRecords={totalRecords}
                                    totalNumberOfRows={totalNumberOfRows}
                                    fetchData={handleFetchData}
                                    manualSorting={false}
                                />
                            ) : (
                                <div className="py-4 text-center">
                                    <div>
                                        <i className="ri-search-line display-5 text-success"></i>
                                    </div>

                                    <div className="mt-4">
                                        <h5>{notFound.loadingContent}</h5>
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default Report;
