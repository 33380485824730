import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { categoryLabels, financialYear } from 'utils/helpers/constant';
import { yearOptions } from 'utils/helpers/enums';
import { getFinancialYear } from 'utils/helpers/sessionStores';

const FinancialYear = () => {
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState<any>();
  const [savedYear, setSavedYear] = useState<any>();

  useEffect(() => {
    const savedYearValue = getFinancialYear();
    if (savedYearValue) {
      const foundYearOption = yearOptions?.find((option: any) => option.value === savedYearValue);
      setSavedYear(foundYearOption);
      setSelectedYear(foundYearOption);
    }
  }, []);

  const handleYearChange = (selectedOption: any) => {
    setSelectedYear(selectedOption);
  };

  const handleSubmit = () => {
    if (selectedYear) {
      sessionStorage.setItem('financialYear', selectedYear.value);
      setSavedYear(selectedYear);
      navigate('/dashboard');
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  document.title = financialYear.title

  return (
    <div className='page-content'>
      <Container>
        <Row className="mb-3">
          <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
            <h5 className="fw-semibold mb-0">
              {financialYear?.title}
              {savedYear && <span> (Selected FY: {savedYear.label})</span>}
            </h5>
          </div>
          <div className="col-auto order-2 order-sm-3 ms-auto">
            <div className="hstack gap-2">
              <button
                className="btn btn-primary createTask"
                type="button"
                onClick={handleCancel}
              >
                {financialYear?.back}
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <Col lg={5}>
            <Card>
              <CardBody>
                <Label className=''>{financialYear?.selectFinancialYear}</Label>
                <Select
                  name="financial_year"
                  className="m-1 mb-3"
                  options={yearOptions}
                  onChange={handleYearChange}
                  value={selectedYear}
                  placeholder="Select..."
                />
                <div className="text-end">
                  <Button color="btn btn-primary w-sm" onClick={handleSubmit} className="me-2">{categoryLabels?.submit}</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FinancialYear;
