import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import Select from 'react-select';
import { Card, CardBody, Col, Input, Label, Row, Form, FormFeedback, Spinner, Container } from "reactstrap";
import { PlaceHolderFormat, RequiredField } from 'utils/helpers/validationRequiredMessages';
import { listOfCountry, listOfState, listOfCity } from "api/commanApi";
import { addStaffList, editStaffList, staffList } from "api/staffApi";
import { toast } from 'react-toastify';
import { categoryLabels, staffLabels, supplierLabels, validationMessages } from "utils/helpers/constant";
import { ResponseStatusEnum } from "utils/helpers/enums";
import { emailRegex, passwordRegex } from "utils/helpers/regexPatterns";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoaderBlur from "Components/Common/BlurLoader";

interface StaffData {
    id: any;
    staff_name: string,
    email: string,
    password: string,
    confirmPassword: string,
    contact_no: number,
    address_line_1: string,
    address_line_2: string,
    country_id: {
        id: number,
        country_name: string
    };
    province_id: {
        id: number,
        province_name: string
    };
    city_id: {
        id: number,
        city_name: string
    };
    zip_code: number
}

type OptionType = {
    label: string;
    value: string;
};

interface CountryOptions {
    country_name: string;
    id: number;
    value: number;
};

interface StateOptions {
    province_name: string;
    id: number;
    value: number;
};

interface CityOptions {
    city_name: string;
    id: number;
    value: number;
};

const AddStaff = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [staffData, setStaffData] = useState<StaffData | undefined>();
    const [editStaffData, setEditStaffData] = useState<StaffData>();
    const [listOfCountryOptions, setListOfCountryOptions] = useState<CountryOptions[]>([]);
    const [listOfStateOptions, setListOfStateOptions] = useState<StateOptions[]>([]);
    const [listOfCityOptions, setListOfCityOptions] = useState<CityOptions[]>([]);
    const history = useNavigate();
    const { id } = useParams();
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [confPasswordShow, setConfPasswordShow] = useState<boolean>(false);
    const [cityIsLoading, setCityIsLoading] = useState(false);
    const [stateIsLoading, setStateIsLoading] = useState(false);
    const [countryIsLoading, setCountryIsLoading] = useState(false);
    useEffect(() => {
        fetchCountryData();
        if (id) {
            setIsLoading(true);
            let data = {
                condition: {
                    id: id
                }
            };
            staffList(data)
                .then((response) => {
                    const staffData = response?.data?.listOfStaffData?.[0];
                    setStaffData(staffData);
                    if (staffData && staffData.country_id) {
                        fetchStateData(staffData.country_id.id.toString());
                        if (staffData.province_id) {
                            fetchCityData(staffData.province_id.id.toString());
                        }
                    }
                })
                .catch((err) => err)
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, []);

    const fetchCountryData = () => {
        setCountryIsLoading(true);
        listOfCountry({})
            .then((response) => {
                setListOfCountryOptions(
                    response?.data?.map((country: CountryOptions) => ({
                        value: country?.id,
                        label: country?.country_name,
                    }))
                );
            })
            .catch((error) => {
                return error;
            })
            .finally(() => {
                setCountryIsLoading(false);
            });
    };

    const fetchStateData = (countryId: string) => {
        setStateIsLoading(true);
        listOfState({
            condition: {
                country_id: countryId,
            },
        }).then((response) => {
            setListOfStateOptions(
                response?.data?.map((state: StateOptions) => ({
                    value: state?.id,
                    label: state?.province_name,
                }))
            );
        }).catch((error) => {
            return error;
        }).finally(() => {
            setStateIsLoading(false);
        });
    };

    const fetchCityData = (stateId: string) => {
        setCityIsLoading(true);
        listOfCity({
            condition: {
                province_id: stateId,
            },
        }).then((response) => {
            setListOfCityOptions(
                response?.data?.map((state: CityOptions) => ({
                    value: state?.id,
                    label: state?.city_name,
                }))
            );
        }).catch((error) => {
            return error;
        }).finally(() => {
            setCityIsLoading(false);
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            staff_name: staffData?.staff_name ?? '',
            email: staffData?.email ?? '',
            password: '',
            confirmPassword: '',
            contact_no: staffData?.contact_no ?? '',
            address_line_1: staffData?.address_line_1 ?? '',
            address_line_2: staffData?.address_line_2 ?? '',
            country_id: staffData?.country_id?.id ?? '',
            province_id: staffData?.province_id?.id ?? '',
            city_id: staffData?.city_id?.id ?? '',
            zip_code: staffData?.zip_code ?? ''
        },
        validationSchema: Yup.object().shape({
            staff_name: Yup.string().required(RequiredField(staffLabels.name)),
            email: Yup.string()
                .email(validationMessages.format(staffLabels.email))
                .matches(emailRegex, validationMessages.format(staffLabels.email))
                .required(validationMessages.required(staffLabels.email)),
            password: !id ? Yup.string()
                .matches(passwordRegex, validationMessages.format(staffLabels.password))
                .required(validationMessages.required(staffLabels.password)) : Yup.string(),
            confirmPassword: !id ? Yup.string()
                .oneOf([Yup.ref('password')], validationMessages.passwordsMatch(staffLabels.confirmPassword))
                .required(validationMessages.required(staffLabels.confirmPassword)) : Yup.string(),
            contact_no: Yup.string()
                .min(10, validationMessages.maxLength(staffLabels.contact, 10))
                .max(10, validationMessages.maxLength(staffLabels.contact, 10))
                .required(RequiredField(staffLabels.contact)),
            address_line_1: Yup.string().required(RequiredField(staffLabels.addressLine1)),
            address_line_2: Yup.string().required(RequiredField(staffLabels.addressLine2)),
            country_id: Yup.string().required(RequiredField(staffLabels.country)),
            province_id: Yup.string().required(RequiredField(staffLabels.state)),
            city_id: Yup.string().required(RequiredField(staffLabels.city)),
            zip_code: Yup.string()
                .required(RequiredField(supplierLabels.zipCodeName))
                .min(4, validationMessages.maxLength(supplierLabels.zipCodeName, 4))
                .max(4, validationMessages.maxLength(supplierLabels.zipCodeName, 4)),
        }),
        onSubmit: (values) => {
            setIsLoading(true);
            if (id) {
                handleEditStaff(id, values);
            } else {
                handleAddStaff(values);
            }
        },
    });

    const handleEditStaff = (id: any, values: any) => {
        const { password, confirmPassword, email, ...payload } = values;
        editStaffList(id, payload).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                history("/staff");
                toast.success(response.message);
                validation.resetForm();
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(err.somethingWrong);
            return err;
        }).finally(() => {
            setIsLoading(false);
            setEditStaffData(undefined);
        });
    }

    const handleAddStaff = (values: object) => {
        addStaffList(values)
            .then((response) => {
                if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    history("/staff");
                    toast.success(response.message);
                    validation.resetForm();
                } else {
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                toast.error(err.somethingWrong);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className="page-content">
            <Row className="mb-2">
                <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                    <h5 className="fw-semibold mb-0">{id ? `${categoryLabels?.editCategory} ${staffLabels?.title}` : `${staffLabels?.addStaff} ${staffLabels?.title}`}</h5>
                </div>
                <div className="col-auto order-2 order-sm-3 ms-auto">
                    <div className="hstack gap-2">
                        <Link to={"/staff"} className="btn btn-primary mx-2">Back</Link>
                    </div>
                </div>
            </Row>
            <Container>
                <Card>
                    <CardBody>
                        {isLoading && <LoaderBlur />}
                        <Form onSubmit={validation.handleSubmit}>
                            <Row>
                                <Col sm={4} className='mb-2'>
                                    <Label className="form-label" htmlFor="product-title-input">
                                        {staffLabels.name}
                                    </Label>
                                    <Input
                                        type="text"
                                        name="staff_name"
                                        title={staffLabels.name}
                                        placeholder={PlaceHolderFormat(staffLabels.name)}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.staff_name}
                                        onChange={validation.handleChange}
                                        invalid={validation.touched.staff_name &&
                                            validation.errors.staff_name
                                            ? true
                                            : false}
                                    />
                                    {validation.touched.staff_name &&
                                        validation.errors.staff_name ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.staff_name}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                                <Col sm={4} className='mb-2'>
                                    <Label className="form-label" htmlFor="product-title-input">
                                        {staffLabels.email}
                                    </Label>
                                    <Input
                                        type="text"
                                        name="email"
                                        title={staffLabels.email}
                                        placeholder={PlaceHolderFormat(staffLabels.email)}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email}
                                        onChange={validation.handleChange}
                                        invalid={validation.touched.email &&
                                            validation.errors.email
                                            ? true
                                            : false}
                                        disabled={!!id}
                                    />
                                    {validation.touched.email &&
                                        validation.errors.email ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.email}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                                <Col sm={4} className='mb-2'>
                                    <Label className="form-label" htmlFor="product-title-input">
                                        {staffLabels.contact}
                                    </Label>
                                    <Input
                                        type="text"
                                        name="contact_no"
                                        title={staffLabels.contact}
                                        placeholder={PlaceHolderFormat(staffLabels.contact)}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.contact_no}
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                validation.handleChange(e);
                                            }
                                        }}
                                        maxLength={10}
                                        invalid={validation.touched.contact_no &&
                                            validation.errors.contact_no
                                            ? true
                                            : false}
                                    />
                                    {validation.touched.contact_no &&
                                        validation.errors.contact_no ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.contact_no}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} className='mb-2'>
                                    <Label className="form-label" htmlFor="product-title-input">
                                        {staffLabels.addressLine1}
                                    </Label>
                                    <Input
                                        type="text"
                                        name="address_line_1"
                                        title={staffLabels.addressLine1}
                                        placeholder={PlaceHolderFormat(staffLabels.addressLine1)}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.address_line_1}
                                        onChange={validation.handleChange}
                                        invalid={validation.touched.address_line_1 &&
                                            validation.errors.address_line_1
                                            ? true
                                            : false}
                                    />
                                    {validation.touched.address_line_1 &&
                                        validation.errors.address_line_1 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.address_line_1}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                                <Col sm={6} className='mb-2'>
                                    <Label className="form-label" htmlFor="product-title-input">
                                        {staffLabels.addressLine2}
                                    </Label>
                                    <Input
                                        type="text"
                                        name="address_line_2"
                                        title={staffLabels.addressLine2}
                                        placeholder={PlaceHolderFormat(staffLabels.addressLine2)}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.address_line_2}
                                        onChange={validation.handleChange}
                                        invalid={validation.touched.address_line_2 &&
                                            validation.errors.address_line_2 ? true
                                            : false}
                                    />
                                    {validation.touched.address_line_2 &&
                                        validation.errors.address_line_2 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.address_line_2}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} className='mb-2'>
                                    {countryIsLoading ?
                                        <div className="d-flex align-items-center justify-content-center spinner">
                                            <Spinner size="sm" />
                                        </div>
                                        :
                                        <>
                                            <Label className="form-label" htmlFor="product-title-input">
                                                {staffLabels.country}
                                            </Label>
                                            <Select
                                                value={listOfCountryOptions?.find((option: any) => option.value === validation.values.country_id)} //here i have add the any type because of i need to add the type if here i add the value and label in object then conflict with the CountryOption Interface same as well as the in city, state
                                                onChange={(selectedOption: OptionType) => {
                                                    validation.setFieldValue(
                                                        "country_id",
                                                        selectedOption?.value || ""
                                                    );
                                                    fetchStateData(selectedOption?.value);
                                                }}
                                                className='select-border'
                                                options={listOfCountryOptions}
                                                invalid={!!(validation.errors.country_id && validation.touched.country_id)}
                                            />
                                            {validation.errors.country_id &&
                                                validation.touched.country_id ? (
                                                <div className="error-message-drop">
                                                    {validation.errors.country_id}
                                                </div>
                                            ) : null}
                                        </>
                                    }
                                </Col>
                                <Col sm={6} className='mb-2'>
                                    {stateIsLoading ?
                                        <div className="d-flex align-items-center justify-content-center spinner">
                                            <Spinner size="sm" />
                                        </div>
                                        :
                                        <>
                                            <Label className="form-label" htmlFor="product-title-input">
                                                {staffLabels.state}
                                            </Label>
                                            <Select
                                                value={listOfStateOptions?.find((option: any) => option.value === validation.values.province_id)}
                                                onChange={(selectedOption: OptionType) => {
                                                    validation.setFieldValue(
                                                        "province_id",
                                                        selectedOption?.value || ""
                                                    );
                                                    fetchCityData(selectedOption?.value);
                                                }}
                                                className='select-border'
                                                options={listOfStateOptions}
                                                invalid={!!(validation.errors.province_id && validation.touched.province_id)}
                                            />
                                            {validation.errors.province_id &&
                                                validation.touched.province_id ? (
                                                <div className="error-message-drop">
                                                    {validation.errors.province_id}
                                                </div>
                                            ) : null}
                                        </>}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} className='mb-2'>
                                    {cityIsLoading ?
                                        <div className="d-flex align-items-center justify-content-center spinner">
                                            <Spinner size="sm" />
                                        </div>
                                        :
                                        <>
                                            <Label className="form-label" htmlFor="product-title-input">
                                                {staffLabels.city}
                                            </Label>
                                            <Select
                                                value={listOfCityOptions?.find((option: any) => option.value === validation.values.city_id)}
                                                onChange={(selectedOption: OptionType) =>
                                                    validation.setFieldValue("city_id", selectedOption?.value || "")
                                                }

                                                options={listOfCityOptions}
                                                invalid={!!(validation.errors.city_id && validation.touched.city_id)}
                                            />
                                            {validation.errors.city_id && validation.touched.city_id ? (
                                                <div className="error-message-drop">
                                                    {validation.errors.city_id}
                                                </div>
                                            ) : null}
                                        </>
                                    }
                                </Col>
                                <Col sm={6} className='mb-2'>
                                    <Label className="form-label" htmlFor="product-title-input">
                                        {supplierLabels.zipCodeName}
                                    </Label>
                                    <Input
                                        type="text"
                                        name="zip_code"
                                        maxLength={4}
                                        title={supplierLabels.zipCodeName}
                                        placeholder={PlaceHolderFormat(supplierLabels.zipCodeName)}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.zip_code}
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                validation.handleChange(e);
                                            }
                                        }}
                                        invalid={validation.touched.zip_code &&
                                            validation.errors.zip_code
                                            ? true
                                            : false}
                                    />
                                    {validation.touched.zip_code &&
                                        validation.errors.zip_code ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.zip_code}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </Row>
                            {!id && (
                                <Row>
                                    <Col sm={6} className='mb-2'>
                                        <div className="mb-3">
                                            <Label
                                                className="form-label"
                                                htmlFor="password-input"
                                            >
                                                {staffLabels.password}
                                            </Label>
                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                <Input
                                                    name="password"
                                                    title={staffLabels.password}
                                                    value={validation.values.password || ""}
                                                    type={passwordShow ? "text" : "password"}
                                                    className="form-control pe-5"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    invalid={!!(validation.touched.password && validation.errors.password)}
                                                    placeholder={PlaceHolderFormat(staffLabels.password)}
                                                />
                                                {validation.touched.password &&
                                                    validation.errors.password ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.password}
                                                    </FormFeedback>
                                                ) : null}
                                                <button
                                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted ml-6 pr-6"
                                                    type="button"
                                                    id="password-addon"
                                                    onClick={() => setPasswordShow(!passwordShow)}
                                                >
                                                    {passwordShow ?
                                                        <i className="ri-eye-line align-middle" style={{ paddingRight: "20px" }}></i> :
                                                        <i className="ri-eye-off-line align-middle" style={{ paddingRight: "20px" }}></i>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} className='mb-2'>
                                        <div className="mb-3">
                                            <Label
                                                className="form-label"
                                                htmlFor="password-input"
                                            >
                                                {staffLabels.password}
                                            </Label>
                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                <Input
                                                    name="confirmPassword"
                                                    title={staffLabels.confirmPassword}
                                                    value={validation.values.confirmPassword}
                                                    type={confPasswordShow ? "text" : "password"}
                                                    className="form-control pe-5"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    invalid={validation.touched.confirmPassword &&
                                                        validation.errors.confirmPassword
                                                        ? true
                                                        : false}
                                                    placeholder={PlaceHolderFormat(staffLabels.confirmPassword)}
                                                />
                                                {validation.touched.confirmPassword &&
                                                    validation.errors.confirmPassword ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.confirmPassword}
                                                    </FormFeedback>
                                                ) : null}
                                                <button
                                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted ml-6 pr-6"
                                                    type="button"
                                                    id="password-addon"
                                                    onClick={() => setConfPasswordShow(!confPasswordShow)}
                                                >
                                                    {confPasswordShow ?
                                                        <i className="ri-eye-line align-middle" style={{ paddingRight: "20px" }}></i> :
                                                        <i className="ri-eye-off-line align-middle" style={{ paddingRight: "20px" }}></i>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <div className="text-end">
                                <Link to={"/staff"} color="none"
                                    className="btn btn-ghost-success mx-2">Cancel</Link>
                                <button
                                    className="btn btn-primary createTask"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};
export default AddStaff;