import TableContainer from "Components/Common/TableContainer";
import Loader from "Components/Common/loader";
import Flatpickr from "react-flatpickr";
import { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap";
import { notFound, purchaseProductLabels } from "utils/helpers/constant";
import Select from "react-select";
import {
  ResponseStatusEnum,
  orderStatusOption,
  orderTypeOption,
} from "../../utils/helpers/enums";
import { listOfStaff, purchaseProductReport } from "../../api/reportApi";
import { dateFormatChange } from "utils/helpers/comman";
interface OptionType {
  value: string;
  label: string;
}

interface Payload{
  startDate?: string,
  endDate?: string,
  orderStatus?: string,
  orderType?: string,
  saleAccordingRole?: string,
}

const PuchaseProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState<OptionType | null>(null);
  const [orderTypeStatus, setOrderTypeStatus] = useState<OptionType | null>(null);
  const [filterByNameOption,setFilterByNameOption] = useState<OptionType[] | []>([]);
  const [filterByNames, setFilterByNames] = useState<OptionType | null>(null);
  const [purchaseProductData, setPurchaseProductData] = useState([]);
  const [date, setDate] = useState();

  const PurchaseProductColumns = useMemo(
    () => [
      {
        header: purchaseProductLabels.productName,
        accessorKey: `${purchaseProductLabels.productNameAccessKey}`,
        enableColumnFilter: false,
      },
      {
        header: purchaseProductLabels.quantity,
        accessorKey: `${purchaseProductLabels.quantityAccessKey}`,
        enableColumnFilter: false,
      },
      {
        header: purchaseProductLabels.totalPrice,
        accessorKey: `${purchaseProductLabels.totalPriceAccessKey}`,
        enableColumnFilter: false,
      },
    ],
    []
  );

  const PurchaseProductReport = (payload: any) => {
    setIsLoading(true);
    purchaseProductReport(payload).then((response) => {
      if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
        setPurchaseProductData(response?.data);
      } else setPurchaseProductData([]);
    }).catch((err)=>setPurchaseProductData([])).finally(()=>setIsLoading(false));
  };

  const listOfStaffdata = ()=>{
    listOfStaff().then((response)=>{
      if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
      const data = response?.data?.map((item:any)=>({
        value:item?.staff_name,
        label:item?.staff_name
      }));
      setFilterByNameOption(data);
    }else setFilterByNameOption([]);
    }).catch((err)=>setFilterByNameOption([]))
  }

  useEffect(() => {
    setIsLoading(true);
    PurchaseProductReport({});
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const payload:Payload = {
      orderStatus: orderStatus?.label,
      orderType: orderTypeStatus?.label,
      saleAccordingRole: filterByNames?.label,
    };
    if(date){
      payload.startDate=dateFormatChange(date?.[0]);
      payload.endDate=dateFormatChange(date?.[1]);
    }
    PurchaseProductReport(payload);
  };

  return (
    <div className="page-content">
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <Row className="mb-3">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">
                {purchaseProductLabels.title}
              </h5>
            </div>
          </Row>
          <Card>
            <CardBody>
              <Form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <Row className="mb-3">
                  <Col sm={2} md={3} lg={2}>
                    <Label>{purchaseProductLabels?.startDateEndDate}</Label>
                    <Flatpickr
                      name="date"
                      id="date"
                      className="form-control"
                      placeholder="Enter Date"
                      options={{
                        mode: "range",
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        maxDate:new Date(),
                      }}
                      onChange={(orderDate: any) => setDate(orderDate)}
                      value={date}
                    />
                  </Col>
                  <Col sm={2} md={3} lg={2}>
                    <Label>{purchaseProductLabels?.orderStatus}</Label>
                    <Select
                      name="product_status"
                      className="mb-3"
                      options={orderStatusOption}
                      onChange={(selectedOption: OptionType) =>
                        setOrderStatus(selectedOption)
                      }
                      value={orderStatus}
                    />
                  </Col>
                  <Col sm={2} md={3} lg={2}>
                    <Label>{purchaseProductLabels?.orderType}</Label>
                    <Select
                      name="orderType"
                      className="mb-3"
                      options={orderTypeOption}
                      onChange={(selectedOption: OptionType) =>{
                          if(selectedOption.value === 'OFFLINE') {
                            listOfStaffdata();
                          }
                          setOrderTypeStatus(selectedOption)
                        }
                      }
                      value={orderTypeStatus}
                    />
                  </Col>

                  {orderTypeStatus?.value === "OFFLINE" && (
                    <Col sm={2} md={3} lg={2}>
                      {" "}
                      <Label>{purchaseProductLabels?.filterByName}</Label>
                      <Select
                        name="orderType"
                        className="mb-3"
                        options={filterByNameOption}
                        onChange={(selectedOption: OptionType) =>
                          setFilterByNames(selectedOption)
                        }
                        value={filterByNames}
                      />
                    </Col>
                  )}
                  <Col sm={2} md={3} lg={2} className="mt-1">
                    <button
                      type="submit"
                      className="btn btn-primary w-100 mt-4"
                    >
                      {" "}
                      <i className="ri-equalizer-fill me-2 align-bottom"></i>
                      Filters
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              {purchaseProductData && purchaseProductData?.length > 0 ? (
                <TableContainer
                  columns={PurchaseProductColumns}
                  data={purchaseProductData || []}
                  isGlobalFilter={false}
                  customPageSize={5}
                  divClass="table-responsive mb-1"
                  tableClass="mb-0 align-middle table-border table-wrap"
                  theadClass="table-light text-muted"
                  manualPagination={false}
                  manualSorting={false}
                />
              ) : (
                <div className="py-4 text-center">
                  <div>
                    <i className="ri-search-line display-5 text-success"></i>
                  </div>

                  <div className="mt-4">
                    <h5>{notFound.loadingContent}</h5>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default PuchaseProduct;
