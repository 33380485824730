import React, { createContext, useContext, useState } from 'react';

interface FilterData {
    totalBusinessAmount: number;
    totalQuantity: number;
    totalFootfall: number;
}

interface GraphData {
    onlinePayments: string,
    COD: string
}

interface RecentOrderData {
    category: string,
    quantity: number,
    amount: number,
    percentage: number
}

interface OrderData {
    date: string,
    totalAmount: string
}

interface ContextProps {
    filterData: FilterData;
    setFilterData: React.Dispatch<React.SetStateAction<FilterData>>;
    graphData: GraphData;
    setGraphData: React.Dispatch<React.SetStateAction<GraphData>>;
    recentOrderData: RecentOrderData[];
    setRecentOrderData: React.Dispatch<React.SetStateAction<RecentOrderData[]>>;
    orderData: OrderData[];
    setOrderData: React.Dispatch<React.SetStateAction<OrderData[]>>;
}

const MainContext = createContext<ContextProps | undefined>(undefined);

export const ContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [filterData, setFilterData] = useState<FilterData>({
        totalBusinessAmount: 0,
        totalQuantity: 0,
        totalFootfall: 0
    });
    const [graphData, setGraphData] = useState<GraphData>({ onlinePayments: '0', COD: '0' });
    const [recentOrderData, setRecentOrderData] = useState<RecentOrderData[]>([]);
    const [orderData, setOrderData] = useState<OrderData[]>([]);

    return (
        <MainContext.Provider value={{ filterData, setFilterData, graphData, setGraphData, recentOrderData, setRecentOrderData, orderData, setOrderData }}>
            {children}
        </MainContext.Provider>
    );
};

export const useMainContext = () => {
    const context = useContext(MainContext);
    if (!context) {
        throw new Error('UseContext must be used within a ContextProvider');
    }
    return context;
};