import TableContainer from 'Components/Common/TableContainer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import dummyImg from '../../assets/images/users/user-dummy-img.jpg'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { PlaceHolderFormat, RequiredField } from 'utils/helpers/validationRequiredMessages';
import { addCategory, deleteCategory, editCategory, fileUploads, listOfCategory } from 'api/categoryApi';
import { ResponseStatusEnum } from 'utils/helpers/enums';
import { toast } from 'react-toastify';
import { IMAGE_URL } from 'api/apiServices';
import { categoryLabels, notFound, title } from 'utils/helpers/constant';
import DeleteModal from 'Components/Common/DeleteModal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import LoaderBlur from 'Components/Common/BlurLoader';
import imageCompression from 'browser-image-compression';
interface EditCategoryData {
    id: number,
    category_name: string,
    category_image: string,
    category_description: string,
    discount_in_percentage: string;
    sizeChartImage: string;
    size_chart_image?: string;
}

const Category = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [categoryModal, setCategoryModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [categoryData, setCategoryData] = useState([]);
    const [searchCategoryData, setSearchCategoryData] = useState([]);
    const [editCategoryData, setEditCategoryData] = useState<EditCategoryData | undefined>();
    const [editCategorySizeChart, setEditCategorySizeChart] = useState("");
    const [selectedImage, setSelectedImage] = useState<string>();
    const [categoryDeleteModal, setCategoryDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState<number>();
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [customPageSize, setCustomPageSize] = useState(5);
    const [columnName, setColumnName] = useState("id");
    const [sortOrder, setSortOrder] = useState("DESC");

    const handleFetchSorting = (page: number, id: string, order: string) => {
        setCurrentPage(page);
        setColumnName(id);
        setSortOrder(order);
    };

    const handleSearchValueChange = (value: string) => {
        if (value !== searchValue) {
            setSearchValue(value);
            setCurrentPage(1);
        }
    };
    const fetchCategory = () => {
        setIsLoading(true);
        const payload = {
            sortOrder:  { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize,
        };

        listOfCategory(payload)
            .then((response) => {
                setTotalRecords(response.data.totalRecordsCount)
                setTotalPages(response?.data?.totalPages)
                setCategoryData(response?.data?.listOfCategory);
                setTotalNumberOfRows(response?.data?.numberOfRows)
                if (searchValue) {
                    fetchSearchData();
                }
            })
            .catch((err) => err)
            .finally(() => {
                setIsLoading(false);
            });
    }

    const fetchSearchData = () => {
        setIsLoading(true);
        const payload = {
            sortOrder:  { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize,
            searchBar: searchValue
        };

        listOfCategory(payload)
            .then((response) => {
                setTotalRecords(response.data.totalRecordsCount)
                setTotalPages(response?.data?.totalPages)
                setSearchCategoryData(response?.data?.listOfCategory)
                setTotalNumberOfRows(response?.data?.numberOfRows)
            })
            .catch((err) => {
                setSearchCategoryData([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleFetchData = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (searchValue) {
            fetchSearchData();
        } else {
            fetchCategory();
        }
    }, [currentPage, searchValue, customPageSize, sortOrder, columnName])

    useEffect(() => {
        if (searchValue === '') {
            setCurrentPage(1);
        }
    }, [searchValue]);

    const toggle = useCallback(() => {
        if (categoryModal) {
            setCategoryModal(false);
            setIsEdit(false);
            setSelectedImage('');
            setEditCategorySizeChart("");
            setEditCategoryData(undefined);
            validation.resetForm();
        } else {
            setCategoryModal(true);
            setSelectedImage('');
            setEditCategorySizeChart("");
            validation.resetForm();
        }
    }, [categoryModal]);

    const handleEditMode = (cell: any) => {
        setIsEdit(true);
        setEditCategoryData(cell)
        setEditCategorySizeChart(cell?.size_chart_image);
        setCategoryModal(true);
    }

    const handleReadMore = (cell: any) => {
        setIsEdit(true);
        setEditCategoryData(cell)
        setCategoryModal(true);
    }

    const onClickDelete = (id: number) => {
        setSelectedId(id)
        setCategoryDeleteModal(true);
    };

    const handleDeleteCategory = () => {
        setIsLoading(true);
        deleteCategory({ category_id: selectedId }).then((response) => {
            if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                setCategoryDeleteModal(false);
                fetchCategory();
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            return err;
            toast.error(title.somethingWrong);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handleEditCategory = (categoryId: number, values: any) => {
        const numericValues = {
            ...values,
            discount_in_percentage: parseFloat(values.discount_in_percentage),
        };
        editCategory(categoryId, numericValues).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                fetchCategory();
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(title.somethingWrong);
            return err;
        }).finally(() => {
            setIsLoading(false);
            setIsEdit(false);
            setSelectedImage('');
            setEditCategoryData(undefined);
            setCategoryModal(false);
            validation.resetForm();
        });
    }

    const handleAddCategory = (values: any) => {
        const numericValues = {
            ...values,
            discount_in_percentage: parseFloat(values.discount_in_percentage),
        };
        addCategory(numericValues)
            .then((response) => {
                if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    toast.success(response.message);
                    fetchCategory();
                    setCategoryModal(false);
                    validation.resetForm();
                } else {
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                return err;
                toast.error(title.somethingWrong);
            })
            .finally(() => {
                setIsLoading(false);
                setSelectedImage('');
            });
    }

    const CategoryColumns = useMemo(
        () =>
            [{
                header: categoryLabels.categoryName,
                accessorKey: `${categoryLabels.accessKeyCategory}`,
                enableColumnFilter: false,
            },
            {
                header: categoryLabels.description,
                accessorKey: `${categoryLabels.accessKeyDescription}`,
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <div>
                        {cell?.row?.original?.category_description?.length > 50 ? (
                            <>
                                {cell?.row?.original?.category_description?.substring(0, 50)}
                                <span
                                    className="text-primary read-more"
                                    onClick={() => handleReadMore(cell?.row?.original)}
                                >
                                    Read More {">>"}
                                </span>
                            </>
                        ) : (
                            cell?.row?.original?.category_description
                        )}
                    </div>
                ),
            },
            {
                header: categoryLabels.discount_in_percentage,
                accessorKey: `${categoryLabels.percentageAccess}`,
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <div>
                        {cell?.row?.original?.discount_in_percentage != null ? (
                            <>
                                {cell?.row?.original?.discount_in_percentage}%
                            </>
                        ) : null}
                    </div>
                ),
            },
            {
                header: categoryLabels.image,
                accessorKey: `${categoryLabels.imageAccessKey}`,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cell: any) => (
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                            <div className="imageDivWrap avatar-sm bg-light rounded p-1">
                                <img
                                    src={`${IMAGE_URL}/${cell.row.original.category_image}`}
                                    alt=""
                                    className="img-fluid d-block imageWrap"
                                    onError={(e: any) => {
                                        e.target.onerror = null;
                                        e.target.src = dummyImg;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                header: categoryLabels.actions,
                enableSorting: false,
                cell: (cell: { row: { original: { id: number } } }) => (
                    <div className="hstack gap-2">
                        <button
                            id={`editMode-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => handleEditMode(cell?.row?.original)}
                        >
                            <i className="ri-pencil-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content="Edit"
                                anchorId={`editMode-${cell?.row?.original?.id}`}
                            />
                        </button>
                        <button
                            id={`delete-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-danger remove-list"
                            onClick={() => { onClickDelete(cell?.row?.original?.id) }}
                        >
                            <i className="ri-delete-bin-5-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="error"
                                content="Delete"
                                anchorId={`delete-${cell?.row?.original?.id}`}
                            />
                        </button>
                    </div>
                ),
            },
            ],
        []
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: editCategoryData?.category_name ?? '',
            description: editCategoryData?.category_description ?? '',
            image: editCategoryData?.category_image ?? '',
            discount_in_percentage: editCategoryData?.discount_in_percentage ?? '',
            sizeChartImage: editCategoryData?.size_chart_image ?? '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(RequiredField(categoryLabels.nameOfCategory)),
            description: Yup.string().required(RequiredField(categoryLabels.description)),
            image: Yup.string().required(RequiredField(`${categoryLabels.category} ${categoryLabels.categoryImage}`)),
            discount_in_percentage: Yup.number()
                .required(RequiredField(categoryLabels.discountPercentage))
                .min(0, categoryLabels?.minimumDiscount)
                .max(100, categoryLabels?.maximumDiscount)
        }),
        onSubmit: (values) => {
            setIsLoading(true);
            if (editCategoryData) {
                handleEditCategory(editCategoryData?.id, values);
            } else {
                handleAddCategory(values);
            }
        },
    });

    const isFormDirty = () => {
        return Object.keys(validation.dirty)?.length > 0;
    };

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const fileSizeLimit = 1000000;
        if (file.size > fileSizeLimit) {
            toast.error('File size exceeds the limit (1MB)');
            return;
        }
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
        };

        try {
            const compressedFile = await imageCompression(file, options);
            //preserv file name
            const newFile = new File([compressedFile], file.name, {
                type: compressedFile.type,
                lastModified: compressedFile.lastModified,
            });

            const formData = new FormData();
            formData.append('files', newFile);

            setIsImageUploading(true);

            const response = await fileUploads(formData);
            const files = response?.data?.[0];

            validation.setFieldValue('image', files);
            setSelectedImage(files);
        } catch (error) {
            toast.error('Image compression failed');
        } finally {
            setIsImageUploading(false);
        }
    };

    const handleSizeChartImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
    
        const fileSizeLimit = 1000000;
        if (file.size > fileSizeLimit) {
          toast.error("File size exceeds the limit (1MB)");
          return;
        }
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        };
    
        try {
          const compressedFile = await imageCompression(file, options);
          //preserv file name
          const newFile = new File([compressedFile], file.name, {
            type: compressedFile.type,
            lastModified: compressedFile.lastModified,
          });
    
          const formData = new FormData();
          formData.append("files", newFile);
    
          setIsLoading(true);
    
          const response = await fileUploads(formData);
          const files = response?.data?.[0];
    
          validation.setFieldValue("sizeChartImage", files);
          setEditCategorySizeChart(files);
        } catch (error) {
          toast.error("Image compression failed");
        } finally {
          setIsLoading(false);
        }
      };

    document.title = categoryLabels.category;

    return (
        <><div className='page-content'>
            <DeleteModal
                show={categoryDeleteModal}
                onDeleteClick={handleDeleteCategory}
                onCloseClick={() => setCategoryDeleteModal(false)} />

            <Container>
                <Row className="mb-3">
                    <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                        <h5 className="fw-semibold mb-0">{categoryLabels.category}</h5>
                    </div>
                    <div className="col-auto order-2 order-sm-3 ms-auto">
                        <div className="hstack gap-2">
                            <button
                                className="btn btn-primary createTask"
                                type="button"
                                onClick={() => { setCategoryModal(true); }}
                            >
                                + {categoryLabels.addCategory}
                            </button>
                        </div>
                    </div>
                </Row>
                <Card>
                    <CardBody>
                        {isLoading && <LoaderBlur />}
                        {categoryData && categoryData?.length > 0 ? (
                            <TableContainer
                                columns={CategoryColumns}
                                data={searchValue ? searchCategoryData : categoryData || []}
                                isGlobalFilter={true}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                totalRecords={totalRecords}
                                fetchData={handleFetchData}
                                onSearch={handleSearchValueChange}
                                fetchSortingData={handleFetchSorting}
                                customPageSize={customPageSize}
                                setCustomPageSize={setCustomPageSize}
                                divClass="table-responsive mb-1"
                                tableClass="mb-0 align-middle table-border"
                                theadClass="table-light text-muted table-wrap"
                                SearchPlaceholder="Search Category"
                                manualPagination={true}
                                manualFiltering={true}
                                manualSorting={true}
                                totalNumberOfRows={totalNumberOfRows}
                            />
                        ) : (
                            <div className="py-4 text-center">
                                <div>
                                    <i className="ri-search-line display-5 text-success"></i>
                                </div>

                                <div className="mt-4">
                                    <h5>{notFound.loadingContent}</h5>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </div>
            <Modal
                isOpen={categoryModal}
                toggle={toggle}
                modalClassName="zoomIn"
                centered
            >
                <Form onSubmit={validation.handleSubmit}>
                    <ModalHeader toggle={toggle} className="p-3 bg-light p-3">
                        {isEdit ? categoryLabels.update : categoryLabels.addCategory} {categoryLabels.category}
                    </ModalHeader>
                    <ModalBody className='category-modal'>
                        <Row>
                            <div className="text-center">
                                <div className="position-relative d-inline-block">
                                    <div className="position-absolute bottom-0 end-0">
                                        <Label htmlFor="category-image-input" className="mb-0">
                                            <div className="avatar-xs cursor-pointer">
                                                <div className="avatar-title bg-light border rounded-circle text-muted">
                                                    {(editCategoryData?.category_image || selectedImage) ? (
                                                        <i className="ri-pencil-fill"></i>
                                                    ) : (
                                                        <i className="ri-camera-fill"></i>
                                                    )}
                                                </div>
                                            </div>
                                        </Label>
                                        <Input
                                            className="form-control d-none"
                                            id="category-image-input"
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={handleImageChange}
                                            invalid={!!(validation.touched.image && validation.errors.image)} />
                                    </div>
                                    {isImageUploading ? (
                                        <Spinner className='me-2' size="sm" />
                                    ) : (
                                        <div className="avatar-lg p-1">
                                            <div className="avatar-title bg-light rounded-circle">
                                                <img
                                                    src={selectedImage ? `${IMAGE_URL}/${selectedImage}` : `${IMAGE_URL}/${editCategoryData?.category_image || dummyImg}`}
                                                    alt="categoryImage"
                                                    id="category-image"
                                                    className="avatar-md rounded-circle object-fit-cover"
                                                    onError={(e: any) => {
                                                        e.target.onerror = null;
                                                        e.target.src = dummyImg;
                                                    }} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {validation.errors.image && validation.touched.image ? (
                                    <FormFeedback type="invalid" className='d-block'>{validation.errors.image}</FormFeedback>
                                ) : null}
                            </div>

                            <div className='p-2'>
                                <Col lg={12}>
                                    <Label>{categoryLabels.categoryName}</Label>
                                    <Input
                                        type="text"
                                        title={categoryLabels.categoryName}
                                        name="name"
                                        maxLength={150}
                                        placeholder={PlaceHolderFormat(categoryLabels.categoryName)}
                                        value={validation.values.name}
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={validation.touched.name &&
                                            validation.errors.name
                                            ? true
                                            : false}
                                    />
                                    {validation.touched.name &&
                                        validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.name}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </div>

                            <div className='p-2'>
                                <Col lg={12}>
                                    <Label className='text-capitalize'>{categoryLabels.discountPercentage} (%)</Label>
                                    <Input
                                        type="number"
                                        title={categoryLabels.discountPercentage}
                                        name="discount_in_percentage"
                                        maxLength={5}
                                        placeholder={PlaceHolderFormat(categoryLabels.discountPercentage)}
                                        value={validation.values.discount_in_percentage}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) => {
                                            const newValue = parseFloat(e.target.value);
                                            if (newValue >= 0 || e.target.value === "") {
                                                validation.handleChange(e);
                                            }
                                        }}
                                        invalid={validation.touched.discount_in_percentage &&
                                            validation.errors.discount_in_percentage
                                            ? true
                                            : false}
                                    />
                                    {validation.touched.discount_in_percentage &&
                                        validation.errors.discount_in_percentage ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.discount_in_percentage}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </div>

                            <div className='p-2'>
                                <Col lg={12}>
                                    <Label>{categoryLabels.description}</Label>
                                    <Input
                                        type="textarea"
                                        title={categoryLabels.description}
                                        name="description"
                                        placeholder={PlaceHolderFormat(categoryLabels.description)}
                                        value={validation.values.description}
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={validation.touched.description &&
                                            validation.errors.description
                                            ? true
                                            : false}
                                    />
                                    {validation.touched.description &&
                                        validation.errors.description ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.description}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </div>
                            <div className="p-2">
                <Col lg={12}>
                  <Label className="text-capitalize">
                    {categoryLabels.sizeChart}
                  </Label>
                  <Input
                    type="file"
                    title={categoryLabels.sizeChart}
                    name="sizeChartImage"
                    maxLength={5}
                    onBlur={validation.handleBlur}
                    onChange={handleSizeChartImageChange}
                    invalid={
                      validation.touched.sizeChartImage &&
                      validation.errors.sizeChartImage
                        ? true
                        : false
                    }
                  />
                  {editCategorySizeChart && (
                    <div className="avatar-lg p-1">
                      <div className="avatar-title bg-light">
                        <img
                          src={`${IMAGE_URL}/${editCategorySizeChart}`}
                          alt="categoryImage"
                          id="category-image"
                          title={categoryLabels.viewImage}
                          className="avatar-md object-fit-cover cursor-pointer"
                          onClick={() =>
                            window.open(
                              `${IMAGE_URL}/${editCategorySizeChart}`,
                              "_blank"
                            )
                          }
                          onError={(e: any) => {
                            e.target.onerror = null;
                            e.target.src = dummyImg;
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </div>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="none"
                            className="btn btn-ghost-success"
                            onClick={toggle}
                        >
                            {categoryLabels.cancel}
                        </Button>
                        <Button type="submit" color="primary" disabled={isEdit && !validation.dirty}>
                            {isLoading ? (
                                <Spinner size="sm" className="me-2" />
                            ) :
                                isEdit ? categoryLabels.update : categoryLabels.submit
                            }
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
            {/*Allow Here Its for the Common Modal */}
            {/* <CategoryModal
                isOpen={categoryModal}
                toggle={toggle}
                data={editCategoryData}
                onSubmit={editCategoryData ? handleEditCategory : handleAddCategory}
                initialValues={editCategoryData}
                isEdit={isEdit}
            /> */}
        </>
    )
}

export default Category
