import { LIST_OF_SUPPLIER, SUPPLIER } from "./apiRoutes";
import { adminUsersServices } from "./apiServices";

export const listOfSuppliers = async (data: object) => {
    const response = await adminUsersServices.post(LIST_OF_SUPPLIER, data);
    return response?.data;
};

export const addSupplier = async (data: object) => {
    const response = await adminUsersServices.post(SUPPLIER, data);
    return response?.data;
};

export const editSupplier = async (supplierId: number, data: object) => {
    const response = await adminUsersServices.put(`${SUPPLIER}/${supplierId}`, data);
    return response?.data;
};

export const deleteSupplier = async (supplierId: number | undefined) => {
    const response = await adminUsersServices.delete(`${SUPPLIER}/${supplierId}`);
    return response?.data;
};

