// ErrorDisplay.jsx
import React from 'react';

const ErrorDisplay = ({ errors }: any) => {
  if (!errors) return null;

  return (
    <div className="error-message-drop">
      {Array.isArray(errors) ? (
        errors.map((error, index) => <div key={index}>{error}</div>)
      ) : (
        <div>{errors}</div>
      )}
    </div>
  );
};

export default ErrorDisplay;
