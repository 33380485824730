import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";
import { graphLabels } from "utils/helpers/constant";

const RevenueCharts = ({ dataColors, series }: any) => {
  var linechartcustomerColors = getChartColorsArray(dataColors);

  var options: any = {
    chart: {
      height: 370,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
      dashArray: [0, 0, 8],
      width: [2, 0, 2.2],
    },
    fill: {
      opacity: [0.1, 0.9, 1],
    },
    markers: {
      size: [0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        barHeight: "70%",
      },
    },
    colors: linechartcustomerColors,
    tooltip: {
      shared: true,
      y: [
        {
          formatter: function (y: any) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          },
        },
        {
          formatter: function (y: any) {
            if (typeof y !== "undefined") {
              return "$" + y.toFixed(2) + "k";
            }
            return y;
          },
        },
        {
          formatter: function (y: any) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " Sales";
            }
            return y;
          },
        },
      ],
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr"
        options={options}
        series={series}
        type="line"
        height="370"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const StoreVisitsCharts = ({ dataColors, graphData }: any) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  const series = [parseFloat(graphData?.onlinePayments), parseFloat(graphData?.COD)];
  var options: any = {
    labels: [graphLabels?.onlinePayments, graphLabels?.cash],
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr"
        options={options}
        series={series}
        type="donut"
        height="333"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const SimplePie = ({ dataColors, graphData, hasData }: any) => {
  if (!hasData) {
    return <div>No data available</div>;
  }
  var chartPieBasicColors = getChartColorsArray(dataColors);
  const series = [parseFloat(graphData?.onlinePayments), parseFloat(graphData?.COD)];
  var options: any = {
    chart: {
      height: 220,
      type: 'pie',
    },
    labels: ['Card', 'Cash'],
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      }
    },
    colors: chartPieBasicColors
  };
  return (
    <ReactApexChart dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="pie"
      height={220}
    />
  )
}

const SalesComparisonChart = ({ dataColors, orderData }: any) => {
  if (!orderData || orderData.length === 0) {
    return <div>No data available</div>;
  }
  var chartColumnDistributedColors = getChartColorsArray(dataColors);
  const totalAmounts = orderData?.map((order: { totalAmount: number }) => order?.totalAmount);
  const dates = orderData?.map((order: { date: string }) => {
    const dateObj = new Date(order.date);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' });
    return [`${day}`, `${month}`];
  });
  const series = [{
    data: totalAmounts
  }];
  var options: any = {
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function (chart: any, w: any, e: any) {
        }
      }
    },
    colors: chartColumnDistributedColors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: dates,
      labels: {
        style: {
          colors: [
            '#038edc',
          ],
          fontSize: '12px'
        }
      }
    }
  };

  return <ReactApexChart dir="ltr" className="apex-charts" series={series} options={options} type="bar" height={350} />;
};

export { RevenueCharts, StoreVisitsCharts, SimplePie, SalesComparisonChart };
