import DeleteModal from "Components/Common/DeleteModal";
import TableContainer from "Components/Common/TableContainer";
import { addHSN, deleteHSN, editHSN, listOfHSN } from "api/HSN";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from "reactstrap";
import {
    categoryLabels,
    hsnLabels,
    loginLabels,
    notFound,
    title,
} from "utils/helpers/constant";
import { ResponseStatusEnum } from "utils/helpers/enums";
import {
    PlaceHolderFormat,
    RequiredField,
} from "utils/helpers/validationRequiredMessages";
import * as Yup from "yup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import LoaderBlur from "Components/Common/BlurLoader";
interface HSNCodeData {
    id: number;
    description: string;
    code: string;
}
interface EditHSNData {
    id: number;
    code: string;
    description: string;
}

const HSN = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [hsnData, setHsnData] = useState([]);
    const [hsnModal, setHsnModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedId, setSelectedId] = useState<number>();
    const [editSubHsnData, setEditSubHsnData] = useState<HSNCodeData>();
    const [hsnDeleteModal, setHsnDeleteModal] = useState(false);

    const toggle = useCallback(() => {
        if (hsnModal) {
            setHsnModal(false);
            setIsEdit(false);
            setEditSubHsnData(undefined);
            validation.resetForm();
        } else {
            setHsnModal(true);
        }
    }, [hsnModal]);

    const hsnFetchData = () => {
        setIsLoading(true);
        listOfHSN({
            sortOrder: {
                id: -1
            },
        })
            .then((response) => {
                setHsnData(response?.data);
            })
            .catch((err) => {
                return err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        hsnFetchData();
    }, []);

    const handleReadMore = (cell: any) => {
        setIsEdit(true);
        setEditSubHsnData(cell)
        setHsnModal(true);
    }

    const HSNColumns = useMemo(
        () => [
            {
                header: `${hsnLabels?.hsnCode}`,
                accessorKey: `${hsnLabels.hsnCodeAccessorKey}`,
                enableColumnFilter: false,
            },
            {
                header: categoryLabels.description,
                accessorKey: `${categoryLabels.descriptionAccessKey}`,
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <div>
                        {cell?.row?.original?.description?.length > 50 ? (
                            <>
                                {cell?.row?.original?.description?.substring(0, 50)}
                                <span
                                    className="text-primary read-more"
                                    onClick={() => handleReadMore(cell?.row?.original)}
                                >
                                    Read More {">>"}
                                </span>
                            </>
                        ) : (
                            cell?.row?.original?.description ?? notFound?.nullData
                        )}
                    </div>
                ),
            },
            {
                header: `${categoryLabels?.actions}`,
                cell: (cell: { row: { original: EditHSNData } }) => (
                    <div className="hstack gap-2">
                        <button
                            id={`editMode-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => {
                                handleEditMode(cell?.row?.original);
                            }}
                        >
                            <i className="ri-pencil-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content="Edit"
                                anchorId={`editMode-${cell?.row?.original?.id}`}
                            />
                        </button>
                        <button
                            id={`delete-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-danger remove-list"
                            onClick={() => {
                                onClickDelete(cell?.row?.original?.id);
                            }}
                        >
                            <i className="ri-delete-bin-5-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="error"
                                content="Delete"
                                anchorId={`delete-${cell?.row?.original?.id}`}
                            />
                        </button>
                    </div>
                ),
            }], []
    );

    const handleEditMode = (cell: EditHSNData) => {
        setIsEdit(true);
        setEditSubHsnData(cell);
        setHsnModal(true);
    };

    const onClickDelete = (id: number) => {
        setSelectedId(id);
        setHsnDeleteModal(true);
    };

    const handleDeleteHSN = () => {
        setIsLoading(true);
        deleteHSN(selectedId)
            .then((response) => {
                if (
                    response.statusCode &&
                    ResponseStatusEnum.SUCCESS.includes(response?.statusCode)
                ) {
                    toast.success(response.message);
                    hsnFetchData();
                    setHsnDeleteModal(false);
                } else {
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                toast.error(title.somethingWrong);
                return err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleEditHSN = (hsnId: number, payload: object) => {
        editHSN(hsnId, payload)
            .then((response) => {
                if (
                    response?.statusCode &&
                    ResponseStatusEnum.SUCCESS.includes(response?.statusCode)
                ) {
                    toast.success(response?.message);
                    hsnFetchData();
                    setIsEdit(false);
                    setHsnModal(false);
                    validation.resetForm();
                    setEditSubHsnData(undefined);
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((err) => {
                toast.error(title.somethingWrong);
                return err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleAddHSN = (payload: object) => {
        addHSN(payload)
            .then((response) => {
                if (
                    response?.statusCode &&
                    ResponseStatusEnum.SUCCESS.includes(response?.statusCode)
                ) {
                    toast.success(response?.message);
                    hsnFetchData();
                    setHsnModal(false);
                    validation.resetForm();
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((err) => {
                toast.error(title.somethingWrong);
                return err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            code: editSubHsnData?.code || "",
            description: editSubHsnData?.description || "",
        },
        validationSchema: Yup.object().shape({
            code: Yup.string()
                .max(6)
                .min(6)
                .required(RequiredField(`${hsnLabels.hsn} ${hsnLabels.hsnCodeAccessorKey}`)),

            description: Yup.string().required(
                RequiredField(categoryLabels.description)
            ),
        }),
        onSubmit: (value) => {
            const payload = {
                code: value.code,
                description: value.description,
            };
            setIsLoading(true);
            if (editSubHsnData) {
                handleEditHSN(editSubHsnData?.id, payload);
            } else {
                handleAddHSN(payload);
            }
        },
    });

    document.title = hsnLabels?.hsn;

    return (
        <Row>
            <Col lg={12}>
                <div className="page-content">
                    <DeleteModal
                        show={hsnDeleteModal}
                        onDeleteClick={handleDeleteHSN}
                        onCloseClick={() => setHsnDeleteModal(false)} />
                    <Container>
                        <Row Row className="mb-3">
                            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                                <h5 className="fw-semibold mb-0">{hsnLabels.hsnCode}</h5>
                            </div>
                            <div className="col-auto order-2 order-sm-3 ms-auto">
                                <div className="hstack gap-2">
                                    <button
                                        className="btn btn-primary createTask"
                                        type="button"
                                        onClick={() => {
                                            setHsnModal(true);
                                        }}
                                    >
                                        + {categoryLabels.addCategory}
                                    </button>
                                </div>
                            </div>
                        </Row>
                        <Card>
                            <CardBody>
                                {isLoading && <LoaderBlur />}
                                {hsnData && hsnData?.length > 0 ? (
                                    <TableContainer
                                        columns={HSNColumns}
                                        data={hsnData || []}
                                        isGlobalFilter={true}
                                        customPageSize={5}
                                        divClass="table-responsive mb-1"
                                        tableClass="mb-0 align-middle table-border"
                                        theadClass="table-light text-muted table-wrap"
                                        SearchPlaceholder="Search HSN Code"
                                        manualPagination={false}
                                        manualSorting={false}
                                    />
                                ) : (
                                    <div className="py-4 text-center">
                                        <div>
                                            <i className="ri-search-line display-5 text-success"></i>
                                        </div>

                                        <div className="mt-4">
                                            <h5>{notFound?.loadingContent}</h5>
                                        </div>
                                    </div>
                                )}
                            </CardBody>
                        </Card>

                    </Container>
                </div>
                <Modal
                    isOpen={hsnModal}
                    toggle={toggle}
                    modalClassName="zoomIn"
                    centered
                >
                    <Form onSubmit={validation.handleSubmit}>
                        <ModalHeader toggle={toggle} className="p-3 bg-light p-3">
                            {isEdit ? categoryLabels?.update : categoryLabels?.create}{" "}
                            {hsnLabels?.hsnCode}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <div className="p-2">
                                    <Col lg={12}>
                                        <Label>{hsnLabels?.hsnCode}</Label>
                                        <Input
                                            type="text"
                                            title={hsnLabels?.hsnCodeAccessorKey}
                                            name={hsnLabels?.hsnCodeAccessorKey}
                                            placeholder={`${loginLabels.enter} ${hsnLabels?.hsn} ${hsnLabels?.hsnCodeAccessorKey}`}
                                            value={validation.values.code}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.touched.code && validation.errors.code)}
                                        ></Input>
                                        {validation.touched.code && validation.errors.code ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.code}
                                            </FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className="p-2">
                                    <Col lg={12}>
                                        <Label>{categoryLabels?.description}</Label>
                                        <Input
                                            type="textarea"
                                            title={categoryLabels?.description}
                                            name={categoryLabels?.descriptionAccessKey}
                                            placeholder={PlaceHolderFormat(
                                                categoryLabels?.description
                                            )}
                                            value={validation.values.description}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(
                                                validation.touched.description &&
                                                validation.errors.description
                                            )}
                                        ></Input>
                                        {validation.touched.description &&
                                            validation.errors.description ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.description}
                                            </FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="none"
                                className="btn btn-ghost-success"
                                onClick={toggle}
                            >
                                {categoryLabels?.cancel}
                            </Button>
                            <Button type="submit" color="primary" disabled={isLoading}>
                                {isLoading ? (
                                    <Spinner size="sm" className="me-2" />
                                ) :
                                    isEdit ? categoryLabels?.update : categoryLabels?.submit}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Col>
        </Row>
    );
};

export default HSN;
