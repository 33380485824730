import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//import images
import logo from "../../assets/images/logo/logosm.png";
import { getRole } from "utils/helpers/sessionStores";
import { sectionLabels } from "utils/helpers/constant";
import { RolesEnum } from "utils/helpers/enums";

const ProfileDropdown = () => {
  const [userName, setUserName] = useState("Admin");
  const role = getRole();
  const sellerRole = role === sectionLabels?.seller ? sectionLabels?.admin : role;

  useEffect(() => {
    const authUSer: any = sessionStorage.getItem("authUser");
    if (authUSer) {
      setUserName("Admin");
    }
  }, [userName]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <Dropdown
      isOpen={isProfileDropdown}
      toggle={toggleProfileDropdown}
      className="ms-sm-3 header-item topbar-user"
    >
      <DropdownToggle tag="button" type="button" className="btn">
        <span className="d-flex align-items-center">
          <img
            className="rounded-circle header-profile-user"
            src={logo}
            alt="Header Avatar"
          />
          <span className="text-start ms-xl-2">
            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
              {" "}
              {userName || sectionLabels?.admin}
            </span>
            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
              {sellerRole}
            </span>
          </span>
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <h6 className="dropdown-header">Welcome {sellerRole}!</h6>
        <DropdownItem className="p-0">
          <Link to="/financialYear" className="dropdown-item">
            <i className="ri-calendar-event-line text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Configuration
            </span>
          </Link>
        </DropdownItem>
        {role === RolesEnum.SELLER && (
          <DropdownItem className="p-0">
            <Link to="/change-password" className="dropdown-item">
              <i className="ri-lock-password-line text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Change Password
              </span>
            </Link>
          </DropdownItem>
        )}
        <DropdownItem className="p-0">
          <Link to="/login" className="dropdown-item" onClick={() => {
            sessionStorage.clear();
            localStorage.clear();
          }}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
