import { createAsyncThunk } from "@reduxjs/toolkit"; // Update the path as per your file structure
import {
  addProduct,
  deleteProduct,
  editProduct,
  listOfProduct,
} from "api/productApi";
import { toast } from "react-toastify";

export const getProducts = createAsyncThunk(
  "ecommerce/getProducts",
  async () => {
    try {
      const response = await listOfProduct({}); // Call listOfProduct instead of getProductsApi
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addNewProduct = createAsyncThunk(
  "",
  async (product: any) => {
    try {
      const response = addProduct(product);
      const data = await response;
      toast.success(data?.message, { autoClose: 3000 });
      return data;
    } catch (error: any) {
      toast.error(error?.message, { autoClose: 3000 });
      return error;
    }
  }
);

export const updateProduct = createAsyncThunk(
  "ecommerce/updateProduct",
  async ({ productId, payload }: any) => {
    try {
      const response = editProduct(productId, payload);
      const data = await response;
      toast.success(data?.message, { autoClose: 3000 });
      return data;
    } catch (error: any) {
      toast.error(error?.message, { autoClose: 3000 });
      return error;
    }
  }
);

export const deleteProducts = createAsyncThunk(
  "ecommerce/deleteProducts",
  async (product: any) => {
    try {
      const response = deleteProduct(product);
      const data = await response;
      toast.success(data?.message, { autoClose: 3000 });
      return { product, ...response };
    } catch (error: any) {
      toast.error(error?.message, { autoClose: 3000 });
      return error;
    }
  }
);
