import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;
export const authURL = process.env.REACT_APP_AUTH_URL;
export const catalogBaseURL = process.env.REACT_APP_CATALOG_BASE_URL;
export const catalogColorAndSizeURL =
  process.env.REACT_APP_CATALOG_COLOR_AND_SIZE_URL;
export const adminUserURL = process.env.REACT_APP_ADMIN_USER_URL;
export const addressURL = process.env.REACT_APP_ADDRESS_URL;
export const reportURL = process.env.REACT_APP_REPORT_URL;
export const searchURL = process.env.REACT_APP_SEARCH_URL;
export const orderURL = process.env.REACT_APP_ORDER_URL;
export const printLabelURl = process.env.REACT_APP_PRINT_LABEL_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const supportSearch = process.env.REACT_APP_SEARCH_SUPPORT_URL;

export const nonAuthServices = axios.create({
  baseURL,
});

export const nonOrderServices = axios.create({
  baseURL: orderURL,
});

export const authServices = axios.create({
  baseURL: authURL,
  headers: {
    Accept: "application/json",
  },
});

export const catalogServices = axios.create({
  baseURL: catalogBaseURL,
  headers: {
    Accept: "application/json",
  },
});

export const catalogColorAndSizeServices = axios.create({
  baseURL: catalogColorAndSizeURL,
  headers: {
    Accept: "application/json",
  },
});

export const adminUsersServices = axios.create({
  baseURL: adminUserURL,
  headers: {
    Accept: "application/json",
  },
});

export const addressServices = axios.create({
  baseURL: addressURL,
  headers: {
    Accept: "application/json",
  },
});

export const reportServices = axios.create({
  baseURL: reportURL,
  headers: {
    Accept: "application/json",
  },
});

export const orderServices = axios.create({
  baseURL: orderURL,
  headers: {
    Accept: "application/json",
  },
});

export const formDataServices = axios.create({
  baseURL: catalogBaseURL,
  headers: {
    Accept: "multipart/form-data",
  },
});

export const searchServices = axios.create({
  baseURL: searchURL,
  headers: {
    Accept: "application/json",
  },
});

authServices.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("authUser");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

catalogServices.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("authUser");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

catalogColorAndSizeServices.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("authUser");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

adminUsersServices.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("authUser");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

addressServices.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("authUser");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

reportServices.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("authUser");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

orderServices.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("authUser");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

formDataServices.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("authUser");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

searchServices.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("authUser");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export const supportServices = axios.create({
  baseURL: supportSearch,
  headers: {
    Accept: "application/json",
  },
});
