import { IMAGE_URL } from "api/apiServices";
import React from "react";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg"
import { notFound } from "utils/helpers/constant";

const EcommerceOrderProduct = (props: any) => {
  return (
    <tr>
        <td>
          <div className="d-flex">
            <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
              <img
                src={`${IMAGE_URL}/${props.product.product_id?.product_images?.image}`}
                alt=""
                className="img-fluid d-block"
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = dummyImg;
                }}
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="fs-15">
                <a
                  href="apps-ecommerce-product-details"
                  className="link-primary"
                >
                  {props.product.product_name ?? notFound?.nullData}
                </a>
              </h5>
              <p className="text-muted mb-0">
                Code: <span className="fw-medium">{props?.product?.product_code ?? notFound?.nullData}</span>
              </p>
              <p className="text-muted mb-0">
                Color: <span className="fw-medium">{props.product.color ?? notFound?.nullData}</span>
              </p>
              <p className="text-muted mb-0">
                Size: <span className="fw-medium">{props.product.size ?? notFound?.nullData}</span>
              </p>
            </div>
          </div>
        </td>
        <td>{props?.product?.barcode ?? notFound?.nullData}</td>
        <td>A${props.product.price ?? notFound?.nullData}</td>
        <td>{props.product.quantity ?? notFound?.nullData}</td>
        <td className="fw-medium text-end">A${props.product.totalAmount ?? notFound?.nullData}</td>
      </tr>
  );
};

export default EcommerceOrderProduct;
