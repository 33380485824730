import Report from "./combinationOfReport";

const Sales = () => {
  document.title = "Sales Report"
  return (
    <Report reportType="sales"/>
  );
};

export default Sales;
