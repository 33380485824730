import React from "react";
import Route from "./Routes/index";
import './assets/scss/themes.scss';
import { ContextProvider } from "Context/Context";

function App() {
  return (
    <React.Fragment>
      <ContextProvider>
        <Route />
      </ContextProvider>
    </React.Fragment>
  );
}

export default App;
