import {
  COUNT_OF_ORDER,
  LIST_OF_RECENT_ORDER,
  GRAPH_OF_PAYMENT,
  COUNT_OF_STAFF_ORDER,
  STAFF_RECENT_ORDER_LIST,
  TOTAL_BUSINESS_AMOUNT,
  LAST_TEN_ORDERS,
  CATEGORYWISE_ANALYSIS,
} from "./apiRoutes";
import { adminUsersServices } from "./apiServices";

export const countOfOrder = async (data: object) => {
  const response = await adminUsersServices.post(`${COUNT_OF_ORDER}`, data);
  return response?.data;
};

export const listOfRecentOrder = async () => {
  const response = await adminUsersServices.get(`${LIST_OF_RECENT_ORDER}`);
  return response?.data;
};

export const graphOfPayment = async (data: object) => {
  const response = await adminUsersServices.post(`${GRAPH_OF_PAYMENT}`, data);
  return response?.data;
};

export const graphOfFilterPayment = async (data: {}) => {
  const response = await adminUsersServices.post(`${GRAPH_OF_PAYMENT}`, data);
  return response?.data;
};

export const countOfStaffOrder = async () => {
  const response = await adminUsersServices.get(`${COUNT_OF_STAFF_ORDER}`);
  return response?.data;
};

export const listOfStaffRecentOrder = async () => {
  const response = await adminUsersServices.get(`${STAFF_RECENT_ORDER_LIST}`);
  return response?.data;
};

export const dashboardWigets = async (data: object) => {
  const response = await adminUsersServices.post(`${TOTAL_BUSINESS_AMOUNT}`, data);
  return response?.data;
};

export const filterDashboardWigets = async (data: {}) => {
  const response = await adminUsersServices.post(
    `${TOTAL_BUSINESS_AMOUNT}`,
    data
  );
  return response?.data;
};

export const lastTenOrder = async () => {
  const response = await adminUsersServices.post(`${LAST_TEN_ORDERS}`);
  return response?.data;
};

export const lastTenOrderFilter = async (data: {}) => {
  const response = await adminUsersServices.post(`${LAST_TEN_ORDERS}`, data);
  return response?.data;
};

export const categoryWiseAnalysis = async (data: object) => {
  const response = await adminUsersServices.post(`${CATEGORYWISE_ANALYSIS}`, data);
  return response?.data;
};

export const categoryWiseFilterAnalysis = async (data: {}) => {
  const response = await adminUsersServices.post(`${CATEGORYWISE_ANALYSIS}`, data);
  return response?.data;
};
