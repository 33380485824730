import { CUSTOMER_ORDER, LIST_OF_CUSTOMER, LIST_OF_PRODUCT_NAME, LIST_OF_PRODUCT_SUPPLIERWISE, LIST_OF_STAFF, LIST_OF_SUPPLIER_NAME, PURCHASE_PRODUCT, SALES_REPORT } from "./apiRoutes";
import { reportServices } from "./apiServices";

export const salesReport = async (data: {}) => {
    const response = await reportServices.post(SALES_REPORT, data);
    return response?.data;
};

export const customerReport = async (data: {}) => {
    const response = await reportServices.post(CUSTOMER_ORDER, data);
    return response?.data;
};

export const purchaseProductReport = async (data: {}) => {
    const response = await reportServices.post(PURCHASE_PRODUCT, data);
    return response?.data;
};

export const supplierReport = async (data: {}) => {
    const response = await reportServices.post(LIST_OF_PRODUCT_SUPPLIERWISE, data);
    return response?.data;
};

export const listOfCustomer = async () => {
    const response = await reportServices.get(LIST_OF_CUSTOMER);
    return response?.data;
};

export const listOfStaff = async () => {
    const response = await reportServices.get(LIST_OF_STAFF);
    return response?.data;
};

export const listOfSupplier = async () => {
    const response = await reportServices.get(LIST_OF_SUPPLIER_NAME);
    return response?.data;
};

export const listOfProduct = async (data: {}) => {
    const response = await reportServices.post(LIST_OF_PRODUCT_NAME, data);
    return response?.data;
};