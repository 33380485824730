import { ADMIN_RESET_PASSWORD, CHANGE_PASSWORD, LOGIN, START } from "./apiRoutes";
import { authServices } from "./apiServices";

export const login = async (data: object) => {
  const response = await authServices.post(`${LOGIN}`, data);
  return response?.data;
};

export const start = () => {
  return authServices
    .get(START)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const changePasswordApi = async (data: object) => {
  const response = await authServices.post(`${CHANGE_PASSWORD}`, data);
  return response?.data;
};

export const adminResetPasswordApi = async (data: object) => {
  const response = await authServices.post(`${ADMIN_RESET_PASSWORD}`, data);
  return response?.data;
};