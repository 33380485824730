import { LIST_OF_SUPPORT_QUERY, UPDATE_SUPPORT } from "./apiRoutes";
import { supportServices } from "./apiServices";

export const listOfSupportApi = async (data: object) => {
  const response = await supportServices.post(LIST_OF_SUPPORT_QUERY, data);
  return response?.data;
};

export const updateSupportApi = async (id: any, data: {}) => {
  const response = await supportServices.put(`${UPDATE_SUPPORT}/${id}`, data);
  return response?.data;
};
