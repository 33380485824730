import {
  ADD_SALES_ORDER_CUSTOMER,
  CREATE_SALES_ORDER,
  DELETE_ORDER,
  DELETE_ORDER_ITEM,
  GENERATE_NUMBER,
  LIST_OF_ORDER,
  LIST_OF_SALES_ORDER_CUSTOMER,
  SCAN_BARCODE,
  UPDATE_DELETE_ORDER,
  UPDATE_STATUS_OF_ORDER,
  VIEW_AND_STORE_ORDER,
  VIEW_ORDERS,
} from "./apiRoutes";
import { orderServices } from "./apiServices";

export const listOfOrders = async (data: object) => {
  const response = await orderServices.post(LIST_OF_ORDER, data);
  return response?.data;
};

export const viewOrders = async (id: any) => {
  if (id !== undefined) {
    const response = await orderServices.get(`${VIEW_ORDERS}/${id}`);
    return response?.data;
  }
};

export const generateBillNumber = async () => {
  const response = await orderServices.get(GENERATE_NUMBER);
  return response?.data;
};

export const scanBarCode = async (data: object) => {
  const response = await orderServices.post(SCAN_BARCODE, data);
  return response?.data;
};

export const listOfSalesOrderCustomer = async (data: object) => {
  const response = await orderServices.post(LIST_OF_SALES_ORDER_CUSTOMER, data);
  return response?.data;
};

export const addSalesOrderCustomer = async (data: object) => {
  const response = await orderServices.post(ADD_SALES_ORDER_CUSTOMER, data);
  return response?.data;
};

export const createSaleOrder = async (data: object) => {
  const response = await orderServices.post(CREATE_SALES_ORDER, data);
  return response?.data;
};

export const deleteScanItem = async (orderItemId: number | undefined) => {
  const response = await orderServices.delete(
    `${UPDATE_DELETE_ORDER}/${orderItemId}`
  );
  return response?.data;
};

export const deleteOrderItem = async (orderId: number | undefined) => {
  const response = await orderServices.delete(
    `${DELETE_ORDER_ITEM}/${orderId}`
  );
  return response?.data;
};

export const deleteOrder = async (id: number | undefined) => {
  const response = await orderServices.delete(`${DELETE_ORDER}/${id}`);
  return response?.data;
};

export const updateSalesOrder = async (
  orderId: number | string | undefined,
  data: object
) => {
  const response = await orderServices.put(`${VIEW_ORDERS}/${orderId}`, data);
  return response?.data;
};

export const updateStatusOfOrder = async (
  orderId: number | undefined | string,
  data: object
) => {
  const response = await orderServices.put(
    `${UPDATE_STATUS_OF_ORDER}/${orderId}`,
    data
  );
  return response?.data;
};

export const viewAndStoreOrders = async (data: object) => {
  const response = await orderServices?.post(`${VIEW_AND_STORE_ORDER}`, data);
  return response?.data;
};
