import { LIST_OF_CATEGORY, LIST_OF_CATEGORY_DROPDOWN_DATA, LIST_OF_CUSTOMER_DROPDOWN_DATA, LIST_OF_HSN_DROPDOWN_DATA, LIST_OF_SUB_CATEGORY_DROPDOWN_DATA, LIST_OF_SUPPLIER_DROPDOWN_DATA } from "./apiRoutes";
import {
  adminUsersServices,
  catalogServices,
  orderServices,
} from "./apiServices";

export const listOfCategoryOptions = async () => {
  const response = await catalogServices.get(LIST_OF_CATEGORY_DROPDOWN_DATA);
  return response?.data;
};

export const listOfSubCategoryOptions = async (data: object) => {
  const response = await catalogServices.post(
    LIST_OF_SUB_CATEGORY_DROPDOWN_DATA,
    data
  );
  return response?.data;
};

export const listOfHsnOptions = async () => {
  const response = await catalogServices.get(LIST_OF_HSN_DROPDOWN_DATA);
  return response?.data;
};

export const listOfSuppliesOptions = async (data: object) => {
  const response = await adminUsersServices.post(
    LIST_OF_SUPPLIER_DROPDOWN_DATA,
    data
  );
  return response?.data;
};

export const listOfCustomerOptions = async (data: object) => {
  const response = await orderServices.post(
    LIST_OF_CUSTOMER_DROPDOWN_DATA,
    data
  );
  return response?.data;
};
