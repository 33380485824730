
import Report from "./combinationOfReport";

const Supplier = () => {
  document.title = "Supplier Report"
  return (
    <Report reportType="supplier" />
  );
};

export default Supplier;
