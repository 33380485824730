import { CATEGORY, FILE_UPLOADS, LIST_OF_CATEGORY } from "./apiRoutes";
import { catalogServices } from "./apiServices";

export const fileUploads = async (fileData: object) => {
    const response = await catalogServices.post(FILE_UPLOADS, fileData);
    return response?.data;
};

export const listOfCategory = async (data: object) => {
    const response = await catalogServices.post(LIST_OF_CATEGORY, data);
    return response?.data;
};

export const addCategory = async (data: object) => {
    const response = await catalogServices.post(CATEGORY, data);
    return response.data;
};

export const editCategory = async (categoryId: number, data: object) => {
    const response = await catalogServices.put(`${CATEGORY}/${categoryId}`, data);
    return response.data;
};

export const deleteCategory = async (data: object) => {
    const response = await catalogServices.delete(CATEGORY, { data });
    return response.data;
};