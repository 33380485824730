// Login
export const LOGIN = "login";

// ImageURL
export const FILE_UPLOADS = "category/fileUpload";

// Category
export const CATEGORY = "category";
export const LIST_OF_CATEGORY = "category/list";

// Sub-Category
export const LIST_OF_SUB_CATEGORY = "subCategory/list";
export const SUB_CATEGORY = "subCategory";

// HSN
export const LIST_OF_HSN = "hsn-code/list";
export const HSN = "hsn-code";

// Supplier
export const LIST_OF_SUPPLIER = "supplier/list";
export const SUPPLIER = "supplier";

// Address Related
export const LIST_OF_COUNTRY = "listOfCountry";
export const LIST_OF_STATE = "listOfState";
export const LIST_OF_CITY = "listOfCity";

// Product
export const LIST_OF_PRODUCT = "product/list";
export const PRODUCT = "product";
export const VIEW_PRODUCT = "product/viewProduct";
export const DELETE_PRODUCT_IMAGE = "product/image";
export const COUNTS_OF_PRODUCT_STATUS = "product/counts";

// Product Purchase
export const SUPPLIER_PRODUCT_PURCHASE =
  "product-purchase/supplierProductPurchase";
export const PRODUCT_DETAILS = "product-purchase/productDetails";
export const ADD_PRODUCT = "product-purchase/addProduct";
export const LIST_OF_PRODUCT_PURCHASE = "product-purchase/list";
export const LIST_OF_ADDED_PRODUCT = "product-purchase/product/list";
export const VIEW_PRODUCT_PURCHASE = "product-purchase";
export const VIEW_ADDED_PRODUCT = "product-purchase/product";
export const DELETE_ADDED_PRODUCT = "product-purchase/delete/product";
export const LIST_OF_PRODUCT_CODE = "catalog/searchProductCode";
export const CREATE_BILL_NO = "product-purchase/generate/billNumber";
export const EDIT_PRODUCT = "product-purchase/product/edit";
export const DELETE_ADDED_PRODUCT_VARIANTS = "product-purchase/delete/product";
export const GENERATE_PRODUCT_BARCODE = "product/generateBarcode";

// Tags
export const LIST_OF_TAGS = "product/listOfTag";
export const DELETE_TAGS = "product/tag";

// Color
export const LIST_OF_COLOR = "product/listColor";

// Size
export const LIST_OF_SIZE = "product/listSize";

//Report
export const SALES_REPORT = "/salesReport";
export const CUSTOMER_ORDER = "/customerOrderReport";
export const PURCHASE_PRODUCT = "/productPurchaseReport";
export const LIST_OF_PRODUCT_SUPPLIERWISE = "/listOfProductSupplierWise";
export const LIST_OF_CUSTOMER = "listOfCustomer";
export const LIST_OF_STAFF = "listOfStaff";
export const LIST_OF_SUPPLIER_NAME = "listOfSupplier";
export const LIST_OF_PRODUCT_NAME = "listOfProduct";

//sales-order-return
export const LIST_OF_SALES_RETURN = "sales-order/listOfSalesReturn";
export const LIST_OF_SALES_ORDER_CUSTOMER = "sales-order/customer/list";
export const GENERATE_DOC_NO = "sales-order/generateDocNo";
export const SCAN_BARCODE_RETURN = "sales-order/scan/scanBarcode";
export const LIST_OF_SCAN_PRODUCT = "sales-order/scan/listSCanProduct";
export const DELETE_SCAN_PRODUCT = "sales-order/scan";
export const ADD_SALES_RETURN = "sales-order/salesReturn";
export const ADD_SALES_CUSTOMER = "sales-order/customer";
export const SALES_RETURN = "sales-order/salesReturn";
export const DELETE_SALES_RETURN_ITEM = "sales-order";
export const DELETE_SALES_RETURN = "sales-order/deleteSalesReturn";
export const SALES_ORDER_DISCOUNT = "sales-order/applyDiscount";
export const SCAN_ITEM_APPLY_DISCOUNT = "sales-order/scanItemApplyDiscount";

//Color,Size and Size range
export const COLOR = "color";
export const LIST_COLOR = "color/list";

export const SIZE = "size";
export const LIST_SIZE = "size/list";

export const LIST_SIZE_RANGE = "size-range/list";
export const SIZE_RANGE = "size-range";

// Order
export const LIST_OF_ORDER = "sales-order/list";
export const VIEW_ORDERS = "/sales-order";
export const GENERATE_NUMBER = "/sales-order/generateBillNo"; 
export const SCAN_BARCODE = "/sales-order/scanBarcode";
export const CREATE_SALES_ORDER = "sales-order";
export const DELETE_ORDER = "sales-order/order";
export const DELETE_ORDER_ITEM = "sales-order/scan";
export const UPDATE_DELETE_ORDER = "sales-order/deleteItem";
export const UPDATE_STATUS_OF_ORDER = "editOrderStatus";
export const VIEW_AND_STORE_ORDER = "sales-order/viewScanItems";

// Start
export const START = "start";

//dashboard seller
export const COUNT_OF_ORDER = "dashboard/admin-users/countOfOrder";
export const LIST_OF_RECENT_ORDER = "dashboard/listOfRecentOrder";
export const GRAPH_OF_PAYMENT = "dashboard/admin-users/graphOfPayment";
export const TOTAL_BUSINESS_AMOUNT = "dashboard/filterCards";
export const LAST_TEN_ORDERS = "dashboard/getLast10DaysOrders";
export const CATEGORYWISE_ANALYSIS = "dashboard/getSubCategoryWiseSalesAnalysis";

//dashboard staff
export const COUNT_OF_STAFF_ORDER = "staff/countOfOrder";
export const STAFF_RECENT_ORDER_LIST = "staff/listOfRecentOrder";

//staff
export const STAFF_LIST = "staff/list";
export const STAFF = "staff";
export const STAFF_RESET_PASSWORD = "staff/resetPassword/"

// Customer
export const ADD_SALES_ORDER_CUSTOMER = "sales-order/customer";

// Dropdown
export const LIST_OF_CATEGORY_DROPDOWN_DATA = 'category/dropDown';
export const LIST_OF_SUB_CATEGORY_DROPDOWN_DATA = 'subCategory/dropDown';
export const LIST_OF_HSN_DROPDOWN_DATA = 'hsn-code/dropDown';
export const LIST_OF_SUPPLIER_DROPDOWN_DATA = 'supplier/dropDown';
export const LIST_OF_CUSTOMER_DROPDOWN_DATA = 'sales-order/customer/dropDown';

// Stock Details
export const LIST_OF_STOCK_DETAILS = 'product/stock/list';
export const COUNT_OF_STOCK_DETAILS = 'product/stock/count';

//Support 
export const LIST_OF_SUPPORT_QUERY = 'search/support-query/listOfSupportQuery';
export const UPDATE_SUPPORT = 'search/support-query/updateStatus';

//change password
export const CHANGE_PASSWORD = "staff/changePassword";
export const ADMIN_RESET_PASSWORD = "resetPassword";