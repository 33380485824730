import { Spinner } from 'reactstrap';

const LoaderBlur = (props: any) => {
    return (
        <div className="loader-blur">
            <Spinner color="primary" />
        </div>
    );
};

export default LoaderBlur;