import { catalogColorAndSizeServices, catalogServices } from "./apiServices";
import {
  COLOR,
  LIST_COLOR,
  SIZE,
  LIST_SIZE,
  LIST_SIZE_RANGE,
  SIZE_RANGE,
} from "./apiRoutes";

export const listOfColor = async (data: object) => {
  const response = await catalogColorAndSizeServices.post(LIST_COLOR, data);
  return response?.data;
};

export const listOfSize = async (data: object) => {
  const response = await catalogColorAndSizeServices.post(LIST_SIZE, data);
  return response?.data;
};

export const addColor = async (data: object) => {
  const response = await catalogColorAndSizeServices.post(COLOR, data);
  return response?.data;
};

export const addSize = async (data: object) => {
  const response = await catalogColorAndSizeServices.post(SIZE, data);
  return response?.data;
};

export const editColor = async (id: number, data: object) => {
  const response = await catalogColorAndSizeServices.put(
    `${COLOR}/${id}`,
    data
  );
  return response?.data;
};

export const editSize = async (id: number, data: object) => {
  const response = await catalogColorAndSizeServices.put(`${SIZE}/${id}`, data);
  return response?.data;
};

export const deleteColor = async (id: any) => {
  const response = await catalogColorAndSizeServices.delete(`${COLOR}/${id}`);
  return response?.data;
};

export const deleteSize = async (id: any) => {
  const response = await catalogColorAndSizeServices.delete(`${SIZE}/${id}`);
  return response?.data;
};

export const listOfSizeRange = async (data: object) => {
  const response = await catalogColorAndSizeServices.post(
    `${LIST_SIZE_RANGE}`,
    data
  );
  return response?.data;
};

export const addSizeRange = async (data: object) => {
  const response = await catalogColorAndSizeServices.post(
    `${SIZE_RANGE}`,
    data
  );
  return response?.data;
};

export const viewSizeRange = async (id: number) => {
  const response = await catalogColorAndSizeServices.get(
    `${SIZE_RANGE}/${id}`
  );
  return response?.data;
};

export const editSizeRange = async (id: number, data: object) => {
  const response = await catalogColorAndSizeServices.put(
    `${SIZE_RANGE}/${id}`,
    data
  );
  return response?.data;
};

export const deleteSizeRange = async (id: number | undefined) => {
  const response = await catalogColorAndSizeServices.delete(
    `${SIZE_RANGE}/${id}`
  );
  return response?.data;
};
