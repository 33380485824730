import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import RecentOrders from "./RecentOrders";
import Section from "./Section";
import StoreVisits from "./StoreVisits";
import { getFinancialYear, getRole } from "utils/helpers/sessionStores";
import { RolesEnum } from "utils/helpers/enums";
import { countOfOrder, countOfStaffOrder, graphOfPayment } from 'api/dashboardApi';
import LoaderBlur from 'Components/Common/BlurLoader';
import { SimplePie } from './DashboardEcommerceCharts';
import { useMainContext } from 'Context/Context';

const DashboardEcommerce = () => {
  document.title = "Dashboard";
  const role = getRole();
  const financialYear = getFinancialYear();
  const [loading, setLoading] = useState(false);
  const { graphData, setGraphData } = useMainContext();

  const hasData = parseFloat(graphData?.onlinePayments) > 0 || parseFloat(graphData?.COD) > 0;

  useEffect(() => {
    if (role === RolesEnum.SELLER) {
      setLoading(true);
      graphOfPayment({ financialYear })
        .then((resp) => {
          setGraphData(resp.data)
        })
        .catch((err) => {
          return err;
        }).finally(() => {
          setLoading(false);
        })
    }
  }, [])

  return (
    <Row>
      <Col lg={12}>
        {loading && <LoaderBlur />}
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col>
                <div className="h-100">
                  <Row>
                    <Section />
                  </Row>
                  <Row>
                    <Col xl={role === RolesEnum.SELLER ? 6 : 12}>
                      <Widget />
                    </Col>
                    {role === RolesEnum.SELLER && (
                      <Col xl={6}>
                        <Card className="card-height-100">
                          <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Paymode Wises Sales</h4>
                          </CardHeader>

                          <CardBody>
                            <SimplePie dataColors='[ "--vz-success","--vz-primary"]' graphData={graphData} hasData={hasData} />
                          </CardBody>
                        </Card>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {role === RolesEnum.SELLER && <StoreVisits />}
                    <RecentOrders />
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Col>
    </Row>
  );
};

export default DashboardEcommerce;

