import {  LIST_OF_SUB_CATEGORY, SUB_CATEGORY } from "./apiRoutes";
import { catalogServices } from "./apiServices";

export const listOfSubCategory = async (data: object) => {
    const response = await catalogServices.post(`${LIST_OF_SUB_CATEGORY}`, data);
    return response?.data;
};

export const addSubCategory = async (data: object) => {
    const response = await catalogServices.post(`${SUB_CATEGORY}`, data);
    return response?.data;
};

export const editSubCategory = async (subCategoryId: number, data: object) => {
    const response = await catalogServices.put(`${SUB_CATEGORY}/${subCategoryId}`, data);
    return response?.data;
};

export const deleteSubCategory = async (data: object) => {
    const response = await catalogServices.delete(`${SUB_CATEGORY}`, {data});
    return response?.data;
};