import { StatusCodes } from "http-status-codes";

export const ResponseStatusEnum = {
  SUCCESS: [StatusCodes.OK, StatusCodes.CREATED, StatusCodes.ACCEPTED],
  ERROR: [StatusCodes.BAD_REQUEST, StatusCodes.NOT_FOUND],
  DATANOTFOUND: StatusCodes.NOT_FOUND,
  DBSERVERERROR: StatusCodes.INTERNAL_SERVER_ERROR,
  DUPLICATE: StatusCodes.CONFLICT,
};

export const orderStatusOption = [
  {
    value: "PENDING",
    label: "Pending",
  },
  {
    value: "DELIVERED",
    label: "Delivered",
  },
  {
    value: "OUT-OF-STOCK",
    label: "Out-of-Stock",
  },
  {
    value: "RETURNED",
    label: "Returned",
  },
  {
    value: "PARTIAL-RETURNED",
    label: "Partial-Returned",
  },
];

export const orderTypeOption = [
  {
    value: "ALL",
    label: "All",
  },
  {
    value: "ONLINE",
    label: "Online",
  },
  {
    value: "OFFLINE",
    label: "Offline",
  },
];

export const RolesEnum = {
  SELLER: "Seller",
  STAFF: "Staff"
};

export const pageSizeOptions = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const yearOptions = [
  { value: "2022-23", label: '2022-23' },
  { value: "2023-24", label: '2023-24' },
  { value: "2024-25", label: '2024-25' },
  { value: "2025-26", label: '2025-26' },
];