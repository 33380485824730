import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { addressLabels } from "utils/helpers/constant";

interface CustomerData {
    zipCode: any;
    state: any;
    addressLine2: any;
    addressLine1: any;
    customerInfo: any;
    city: any; 
}

interface ShippingLabelPDFProps {
    customerData: CustomerData;
}

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: 10,
        height: "385px",
        width: "222px",
    },
    body: {
        margin: "15px"
    },
    header: {
        marginBottom: 10,
        textAlign: "center",
    },
    infoContainer: {
        marginBottom: 10,
        fontSize: 30,
    },
    customerInfo: {
        fontSize: 35,
        textDecoration: "underline",
    },
    value: {
        fontSize: 30,
        marginBottom: 10,
    },
    bottomEnd: {
        borderBottom: "2px solid #000",
        marginVertical: 5,
        paddingBottom: "10px",
    }
});

const ShippingLabelPDF: React.FC<ShippingLabelPDFProps> = ({ customerData }) => {
    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.header}>
                    <Text style={styles.customerInfo}>{addressLabels?.title}</Text>
                </View>
                <View style={styles.body}>
                    <View style={styles.infoContainer}>
                        <Text style={styles.value}>{addressLabels?.customer_name} {customerData.customerInfo?.customer_name}</Text>
                    </View>
                    <View style={styles.infoContainer}>
                        <Text style={styles.value}>{customerData.customerInfo?.customer_contact_no}</Text>
                    </View>
                    <View style={styles.infoContainer}>
                        <Text>{customerData?.addressLine1}</Text>
                        <Text>{customerData?.addressLine2}</Text>
                        <Text>{customerData?.city},{customerData?.state?.province_name},{customerData?.zipCode}</Text>
                    </View>
                </View>
                <View style={styles?.bottomEnd} />
            </Page>
        </Document>
    );
};

export default ShippingLabelPDF;
