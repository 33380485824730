import TableContainer from 'Components/Common/TableContainer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Container, Input, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { listOfSupportApi, updateSupportApi } from 'api/supportApi';
import { toast } from 'react-toastify';
import { categoryLabels, notFound, supportLabels } from 'utils/helpers/constant';
import { Tooltip as ReactTooltip } from "react-tooltip";
import LoaderBlur from 'Components/Common/BlurLoader';
import { useFormik } from 'formik';

const Support = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [supportData, setSupportData] = useState([]);
    const [searchStaffData, setSearchSupportData] = useState([]);
    const [supportModal, setSupportModal] = useState(false);
    const [selectedId, setSelectedId] = useState<number>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [supportType, setSupportType] = useState('');
    const [remarks, setRemarks] = useState('');

    const fetchSupportData = () => {
        setIsLoading(true);
        listOfSupportApi({
            pageNumber: currentPage,
            pageSize: 5,
        })
            .then((response) => {
                setSupportData(response?.data?.supportQuery);
                setTotalRecords(response?.data?.totalRecordsCount)
                setTotalPages(response?.data?.totalPages)
                setTotalNumberOfRows(response?.data?.numberOfRows)
            })
            .catch((err) => err)
            .finally(() => {
                setIsLoading(false);
            });
    }

    const fetchSearchData = () => {
        setIsLoading(true);
        listOfSupportApi({
            pageNumber: currentPage,
            pageSize: 5,
        }).then((response) => {
            setSearchSupportData(response?.data?.supportQuery);
            setTotalRecords(response?.data?.totalRecordsCount)
            setTotalPages(response?.data?.totalPages)
            setTotalNumberOfRows(response?.data?.numberOfRows)
        })
            .catch((err) => {
                return err
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleFetchData = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearchValueChange = (value: string) => {
        setSearchValue(value);
    };

    useEffect(() => {
        if (searchValue) {
            fetchSearchData();
        } else {
            fetchSupportData();
        }
    }, [currentPage, searchValue])

    const onClickResolved = (id: number) => {
        setSelectedId(id)
        setSupportModal(true);
        setSupportType('Approved');
    };

    const onClickReject = (id: number) => {
        setSelectedId(id)
        setSupportModal(true);
        setSupportType('Rejected');
    };

    const handleUpdateSupport = () => {
        let data = {
            status: supportType,
            reason: remarks
        }
        setUpdateLoading(true);
        updateSupportApi(selectedId, data)
            .then((resp) => {
                if (resp.statusCode === 202) {
                    toast.success(resp.message)
                } else {
                    toast.error(resp.message)
                }
            })
            .catch((err) => {
                return err;
            })
            .finally(() => {
                fetchSupportData();
                setSupportModal(false);
                setUpdateLoading(false);
            })
    }

    const getStatusLabel = (status: any) => {
        switch (status) {
            case "Approved":
                return <span className="badge bg-success">{status}</span>;
            case "Rejected":
                return <span className="badge bg-danger">{status}</span>;
            case "Pending":
                return <span className="badge bg-warning">{status}</span>;
            default:
                return <span>{status}</span>;
        }
    };

    const SupportColumns = useMemo(
        () => [
            {
                header: supportLabels.fullName,
                accessorKey: supportLabels.contact_name,
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const value = cell.getValue();
                    return value ? value : "-";
                },
            },
            {
                header: supportLabels.email,
                accessorKey: supportLabels.contact_email,
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const value = cell.getValue();
                    return value ? value : "-";
                },
            },
            {
                header: supportLabels.phoneNo,
                accessorKey: supportLabels.contact_no,
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const value = cell.getValue();
                    return value ? value : "-";
                },
            },
            {
                header: supportLabels.message,
                accessorKey: supportLabels.message_accessor,
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const value = cell.getValue();
                    const truncatedValue = value && value?.length > 20 ? `${value?.slice(0, 20)}...` : value;

                    return (
                        value ? (
                            <span data-tooltip-id={`tooltip-${cell.row.index}`} data-tooltip-content={value}>
                                {truncatedValue}
                                <ReactTooltip id={`tooltip-${cell.row.index}`} />
                            </span>
                        ) : "-"
                    );
                },
            },
            {
                header: supportLabels.remarks,
                accessorKey: supportLabels.reason,
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const value = cell.getValue();
                    const truncatedValue = value?.length > 25 ? `${value?.substring(0, 25)}...` : value;
                    return value ? (
                        <span id={`remarks-${cell.row.index}`}>
                            {truncatedValue}
                            {value.length > 25 && (
                                <ReactTooltip
                                    place="top"
                                    variant="info"
                                    content={value}
                                    anchorId={`remarks-${cell.row.index}`}
                                />
                            )}
                        </span>
                    ) : "-";
                },
            },
            {
                header: categoryLabels.actions,
                cell: (cell: any) => {
                    const status = cell.row.original.status;
                    if (status === "Pending") {
                        return (
                            <div className="hstack gap-2">
                                <button
                                    id={`resolve-${cell?.row?.original?.id}`}
                                    className="btn btn-sm btn-soft-info edit-list"
                                    onClick={() => { onClickResolved(cell?.row?.original?.id) }}
                                >
                                    <i className="ri-check-double-line align-bottom" />
                                    <ReactTooltip
                                        place="bottom"
                                        variant="info"
                                        content="Approve"
                                        anchorId={`resolve-${cell?.row?.original?.id}`}
                                    />
                                </button>
                                <button
                                    id={`reject-${cell?.row?.original?.id}`}
                                    className="btn btn-sm btn-soft-danger remove-list"
                                    onClick={() => { onClickReject(cell?.row?.original?.id) }}
                                >
                                    <i className="ri-close-line align-bottom" />
                                    <ReactTooltip
                                        place="bottom"
                                        variant="error"
                                        content="Reject"
                                        anchorId={`reject-${cell?.row?.original?.id}`}
                                    />
                                </button>
                            </div>
                        );
                    } else {
                        return getStatusLabel(status);
                    }
                },
            },
        ],
        []
    );

    const toggle = useCallback(() => {
        if (supportModal) {
            setSupportModal(false);
        } else {
            setSupportModal(true);
        }
    }, [supportModal]);

    document.title = supportLabels.title;

    return (
        <div className='page-content'>
            <Modal isOpen={supportModal}
                toggle={toggle}
                modalClassName="zoomIn"
                centered>
                <ModalBody className="py-3 px-2">
                    <div className="mt-2 text-center">
                        <i className="ri-error-warning-line display-5 text-warning"></i>
                        <div className="mt-1 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4 className='pb-3'>Do you want to {supportType === "Approved" ? "Approve" : "Reject"} ?</h4>
                            <Input
                                type="textarea"
                                placeholder='Remarks'
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            data-bs-dismiss="modal"
                            onClick={toggle}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className={`btn w-sm ${supportType === "Approved" ? "btn-success" : "btn-danger"}`}
                            id="delete-record"
                            onClick={handleUpdateSupport}
                        >
                            {updateLoading ? (
                                <Spinner size="sm" className="me-2" />
                            ) :
                                supportType === "Approved" ? "Approve" : "Reject"
                            }
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <Container>
                <Row className="mb-3">
                    <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                        <h5 className="fw-semibold mb-0">{supportLabels.title}</h5>
                    </div>
                </Row>
                <Card>
                    <CardBody>
                        {isLoading && <LoaderBlur />}
                        {supportData && supportData?.length > 0 ? (
                            <TableContainer
                                columns={SupportColumns}
                                data={supportData || []}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                totalRecords={totalRecords}
                                totalNumberOfRows={totalNumberOfRows}
                                fetchData={handleFetchData}
                                onSearch={handleSearchValueChange}
                                isGlobalFilter={false}
                                customPageSize={5}
                                divClass="table-responsive mb-1"
                                tableClass="mb-0 align-middle table-border table-wrap"
                                theadClass="table-light text-muted text-wrap"
                                SearchPlaceholder="Search Support"
                                manualPagination={true}
                                manualFiltering={true}
                                manualSorting={false}
                            />
                        ) : (
                            <div className="py-4 text-center">
                                <div>
                                    <i className="ri-search-line display-5 text-success"></i>
                                </div>

                                <div className="mt-4">
                                    <h5>{notFound.loadingContent}</h5>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default Support;