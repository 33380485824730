import { adminUsersServices } from "./apiServices";
import { STAFF_LIST, STAFF, STAFF_RESET_PASSWORD } from "./apiRoutes";

export const staffList = async (data: object) => {
  const response = await adminUsersServices.post(STAFF_LIST, data);
  return response?.data;
};

export const addStaffList = async (data: object) => {
  const response = await adminUsersServices.post(STAFF, data);
  return response?.data;
};

export const editStaffList = async (id: number,data: object) => {
  const response = await adminUsersServices.put(`${STAFF}/${id}`, data);
  return response?.data;
};

export const deleteStaff = async (id: number) => {
  const response = await adminUsersServices.delete(`${STAFF}/${id}`);
  return response?.data;
};

export const staffResetPassword = async (id: number, data: {}) => {
  const response = await adminUsersServices.put(`${STAFF_RESET_PASSWORD}${id}`,data);
  return response?.data;
};
