import TableContainer from 'Components/Common/TableContainer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { listOfColor, listOfSize, deleteColor, deleteSize, editColor, addColor, editSize, addSize, listOfSizeRange, addSizeRange, deleteSizeRange, editSizeRange, viewSizeRange } from "api/colorAndSize";
import { PlaceHolderFormat, RequiredField } from 'utils/helpers/validationRequiredMessages';
import { toast } from 'react-toastify';
import { categoryLabels, notFound, productPurchase, sizeRangeLabels, subCategoryLabels, title } from 'utils/helpers/constant';
import DeleteModal from 'Components/Common/DeleteModal';
import { ResponseStatusEnum } from 'utils/helpers/enums';
import { Tooltip as ReactTooltip } from "react-tooltip";
import Select from "react-select";
import LoaderBlur from 'Components/Common/BlurLoader';

interface EditColorData {
    color_name : string,
    id: number,
}
interface EditSizeData {
    size : string,
    id: number,
}

interface ColorData {
    color_name: string;
}

interface EditSizeRangeData {
    id: number,
    size_range: string,
    from_size: string,
    to_size: string,
    sizes: []
}
interface OptionType {
    value: string, label: string
}

interface AddSizeRangeData {
    fromSize: string | undefined,
    toSize: string | undefined,
    sizes: {
        value: string;
        label: string;
    }[] | undefined;
}

const ColorAndSize = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [colorData, setColorData] = useState([]);
    const [sizeData, setSizeData] = useState([]);
    const [sizeRangeData, setSizeRangeData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isSizeEdit, setIsSizeEdit] = useState(false);
    const [isSizeRangeEdit, setIsSizeRangeEdit] = useState(false);
    const [categoryDeleteModal, setCategoryDeleteModal] = useState(false);
    const [sizeDeleteModal, setSizeDeleteModal] = useState(false);
    const [sizeRangeDeleteModal, setSizeRangeDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState<number>();
    const [sizeSelectedId, setSizeSelectedId] = useState<number>();
    const [sizeRangeSelectedId, setSizeRangeSelectedId] = useState<number>();
    const [editSubCategoryData, setEditSubCategoryData] = useState<EditColorData>();
    const [editSizeIdData, setEditSizeIdData] = useState<EditSizeData>();
    const [subCategoryModal, setSubCategoryModal] = useState(false);
    const [sizeModal, setSizeModal] = useState(false);
    const [sizeRangeModal, setSizeRangeModal] = useState(false);
    const [editSizeRangeData, setEditSizeRangeData] = useState<EditSizeRangeData>();
    const [editSizeRangeId, setEditSizeRangeId] = useState<number>();
    const [sizeRangeLoading, setSizeRangeLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const [selectedSizeRange, setSelectedSizeRange] = useState(editSizeRangeData?.sizes?.map((size: string) => ({
        value: size,
        label: size
    })) || []);
    const fetchColor = () => {
        setIsLoading(true);
        listOfColor({})
            .then((resp: any) => {
                setColorData(resp?.data);
            })
            .catch((err: any) => err)
            .finally(() => {
                setIsLoading(false);
            });
    }
    const fetchSize = () => {
        setIsLoading(true);
        listOfSize({})
            .then((resp: any) => {
                setSizeData(resp?.data);
            })
            .catch((err: any) => err)
            .finally(() => {
                setIsLoading(false);
            });
    }
    const fetchSizeRange = () => {
        setSizeRangeLoading(true);
        listOfSizeRange({}).then((resp) => {
            setSizeRangeData(resp?.data);
        }).catch((err) => {
            return err;
        }).finally(() => {
            setSizeRangeLoading(false);
        });
    }

    const toggle = useCallback(() => {
        if (subCategoryModal) {
            setSubCategoryModal(false);
            setIsEdit(false);
            validation.resetForm();
            setEditSubCategoryData(undefined);
        } else {
            setSubCategoryModal(true);
        }
    }, [subCategoryModal]);

    const toggleSize = useCallback(() => {
        if (sizeModal) {
            setSizeModal(false);
            setIsSizeEdit(false);
            sizeValidation.resetForm();
            setEditSizeIdData(undefined);
        } else {
            setSizeModal(true);
        }
    }, [sizeModal]);

    const toggleSizeRange = useCallback(() => {
        if (sizeRangeModal) {
            setSizeRangeModal(false);
            setIsSizeRangeEdit(false);
            setEditSizeRangeData(undefined);
            setEditSizeRangeId(undefined);
            sizeRangeValidation.resetForm();
            setEditSizeRangeData(undefined);
        } else {
            setSizeRangeModal(true);
            setEditSizeRangeData(undefined);
        }
    }, [sizeRangeModal]);

    const handleEditMode = (cell: EditColorData) => {
        setIsEdit(true);
        setEditSubCategoryData(cell)
        setSubCategoryModal(true);
    }

    const handleSizeEditMode = (cell: EditSizeData) => {
        setIsSizeEdit(true);
        setEditSizeIdData(cell)
        setSizeModal(true);
    }

    const handleSizeRangeEditMode = (cell: EditSizeRangeData) => {
        setEditSizeRangeId(cell?.id)
        setIsSizeRangeEdit(true);
        setEditSizeRangeData(cell)
        setSizeRangeModal(true);
        setSizeRangeLoading(true);
        viewSizeRange(cell?.id)
            .then((resp) => {
                setEditSizeRangeData({ ...cell, ...resp?.data });
            })
            .catch((err) => err)
            .finally(() => {
                setSizeRangeLoading(false);
            });
    }

    const onClickDelete = (id: number) => {
        setSelectedId(id)
        setCategoryDeleteModal(true);
    };

    const onSizeClickDelete = (id: number) => {
        setSizeSelectedId(id)
        setSizeDeleteModal(true);
    };

    const onSizeRangeClickDelete = (id: number) => {
        setSizeRangeSelectedId(id);
        setSizeRangeDeleteModal(true);
    }

    const handleDeleteColor = () => {
        setCategoryDeleteModal(false);
        deleteColor(selectedId)
            .then((response) => {
                if (response.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    toast.success(response.message);
                    fetchColor();
                } else {
                    toast.error(response.message);
                }
            }).catch((err) => {
                toast.error(err.somethingWrong);
            }).finally(() => {
                setIsLoading(false);
                setCategoryDeleteModal(false);
            });
    };

    const handleDeleteSize = () => {
        setSizeDeleteModal(false);
        deleteSize(sizeSelectedId)
            .then((response) => {
                if (response.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    toast.success(response.message);
                    fetchSize();
                } else {
                    toast.error(response.message);
                }
            }).catch((err) => {
                toast.error(err.somethingWrong);
            }).finally(() => {
                setIsLoading(false);
                setSizeDeleteModal(false);
            });
    };

    const handleDeleteSizeRange = () => {
        setSizeRangeLoading(true);
        setSizeRangeDeleteModal(false);
        deleteSizeRange(sizeRangeSelectedId)
            .then((response) => {
                if (response.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    toast.success(response.message);
                    fetchSizeRange();
                } else {
                    toast.error(response.message);
                }
            }).catch((err) => {
                toast.error(err.somethingWrong);
            }).finally(() => {
                setSizeRangeLoading(false);
                setSizeRangeDeleteModal(false);
            });
    };

    const handleEditSubCategory = (selectedId: number, value: object) => {
        editColor(selectedId, value).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                fetchColor();
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(err.somethingWrong);
        }).finally(() => {
            setIsLoading(false);
            setIsEdit(false);
            setSubCategoryModal(false);
            validation.resetForm();
        });
    }

    const handleAddSubCategory = (value: object) => {
        addColor(value).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                fetchColor();
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(err.somethingWrong);
        }).finally(() => {
            setIsLoading(false);
            validation.resetForm();
        });
    }

    const handleEditSize = (sizeSelectedId: number, value: object) => {
        editSize(sizeSelectedId, value).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                fetchSize();
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(title.somethingWrong);
        }).finally(() => {
            setSizeModal(false);
            setIsSizeEdit(false)
            sizeValidation.resetForm();
            setIsLoading(false);
        });
    }

    const handleEditSizeRange = (sizeSelectedId: number, value: object) => {
        setBtnLoading(true);
        editSizeRange(sizeSelectedId, value).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                fetchSizeRange();
                sizeRangeValidation.resetForm();
                setEditSizeRangeData(undefined);
                setIsSizeRangeEdit(false);
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(title.somethingWrong);
        }).finally(() => {
            setSizeRangeLoading(false);
            setSizeRangeModal(false);
            sizeRangeValidation.resetForm();
            setBtnLoading(false);
            setEditSizeRangeData(undefined);
            setIsSizeRangeEdit(false);
        });
    }

    const handleAddSize = (value: object) => {
        addSize(value).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                fetchSize();
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(title.somethingWrong);
        }).finally(() => {
            setSizeModal(false);
            setIsLoading(false);
            sizeValidation.resetForm();
        });
    }

    const handleAddSizeRange = (value: AddSizeRangeData) => {
        const selectedSizesValue = value?.sizes;
        setBtnLoading(true);
        addSizeRange({ ...value, sizes: selectedSizesValue }).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                fetchSizeRange();
                sizeRangeValidation.resetForm();
                setSizeRangeModal(false);
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(title.somethingWrong);
            return err
        }).finally(() => {
            setSizeRangeLoading(false);
            setBtnLoading(false);
        });
    }

    useEffect(() => {
        Promise.all([
            fetchColor(),
            fetchSize(),
            fetchSizeRange(),
        ])
    }, [])

    const ColorColumns = useMemo(
        () => [
            {
                header: `Color`,
                accessorKey: `color_name`,
                enableColumnFilter: false,
            },
            {
                header: `${categoryLabels.actions}`,
                cell: (cell: { row: { original: EditColorData } }) => (
                    <div className="hstack gap-2">
                        <button
                            id={`editMode-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => { handleEditMode(cell?.row?.original) }}
                        >
                            <i className="ri-pencil-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content="Edit"
                                anchorId={`editMode-${cell?.row?.original?.id}`}
                            />
                        </button>
                        <button
                            id={`delete-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-danger remove-list"
                            onClick={() => { onClickDelete(cell?.row?.original?.id) }}
                        >
                            <i className="ri-delete-bin-5-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="error"
                                content="Delete"
                                anchorId={`delete-${cell?.row?.original?.id}`}
                            />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const SizeColumns = useMemo(
        () => [
            {
                header: `Size`,
                accessorKey: `size`,
                enableColumnFilter: false,
            },
            {
                header: `${categoryLabels.actions}`,
                cell: (cell: { row: { original: EditSizeData } }) => (
                    <div className="hstack gap-2">
                        <button
                            id={`editModeSize-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => { handleSizeEditMode(cell?.row?.original) }}
                        >
                            <i className="ri-pencil-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content="Edit"
                                anchorId={`editModeSize-${cell?.row?.original?.id}`}
                            />
                        </button>
                        <button
                            id={`deleteSize-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-danger remove-list"
                            onClick={() => { onSizeClickDelete(cell?.row?.original?.id) }}
                        >
                            <i className="ri-delete-bin-5-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="error"
                                content="Delete"
                                anchorId={`deleteSize-${cell?.row?.original?.id}`}
                            />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const SizeRangeColumns = useMemo(
        () => [
            {
                header: sizeRangeLabels?.sizeRange,
                accessorKey: `${sizeRangeLabels?.sizeRangeKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${categoryLabels.actions}`,
                cell: (cell: { row: { original: EditSizeRangeData } }) => (
                    <div className="hstack gap-2">
                        <button
                            id={`editModeOfSizeRange-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => { handleSizeRangeEditMode(cell?.row?.original) }}
                        >
                            <i className="ri-pencil-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content="Edit"
                                anchorId={`editModeOfSizeRange-${cell?.row?.original?.id}`}
                            />
                        </button>
                        <button
                            id={`deleteSizeRange-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-danger remove-list"
                            onClick={() => { onSizeRangeClickDelete(cell?.row?.original?.id) }}
                        >
                            <i className="ri-delete-bin-5-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="error"
                                content="Delete"
                                anchorId={`deleteSizeRange-${cell?.row?.original?.id}`}
                            />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            color_name:  editSubCategoryData?.color_name || '',
        },
        validationSchema: Yup.object().shape({
            color_name: Yup.string().required(RequiredField('Color')),
        }),
        onSubmit: (value) => {
            setIsLoading(true);
            setSubCategoryModal(false);
            if (editSubCategoryData) {
                handleEditSubCategory(editSubCategoryData?.id, value);
            } else {
                handleAddSubCategory(value);
            }
        }
    });

    const sizeValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            size: editSizeIdData?.size || '',
        },
        validationSchema: Yup.object().shape({
            size: Yup.string().required(RequiredField('Size')),
        }),
        onSubmit: (value) => {
            setIsLoading(true);
            setSubCategoryModal(false);
            if (editSizeIdData) {
                handleEditSize(editSizeIdData?.id, value);
            } else {
                handleAddSize(value);
            }
        }
    });

    const sizeRangeValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromSize: isSizeRangeEdit ? editSizeRangeData?.from_size : '',
            toSize: isSizeRangeEdit ? editSizeRangeData?.to_size : '',
            sizes: isSizeRangeEdit ? editSizeRangeData?.sizes : selectedSizeRange
        },
        validationSchema: Yup.object().shape({
            fromSize: Yup.string().required(RequiredField(productPurchase?.fromSize)),
            toSize: Yup.string().required(RequiredField(productPurchase?.toSize)),
            sizes: Yup.array().min(1, RequiredField(sizeRangeLabels?.sizes)).required(RequiredField(sizeRangeLabels?.sizes))
        }),
        onSubmit: (value) => {
            setSizeRangeLoading(true);
            setSubCategoryModal(false);
            if (editSizeRangeId) {
                handleEditSizeRange(editSizeRangeId, value);
            } else {
                handleAddSizeRange(value);
            }
        }
    });

    const sizeRangeOption = sizeData?.map((res: { id: number, size: string }) => {
        return {
            value: `${res?.size}`,
            label: `${res?.size}`,
        };
    });

    document.title = "Color & Size";

    return (
        <Row>
            <Col lg={12}>
                {isLoading && <LoaderBlur />}
                <div className='page-content'>
                    <DeleteModal
                        show={categoryDeleteModal}
                        onDeleteClick={handleDeleteColor}
                        onCloseClick={() => setCategoryDeleteModal(false)}
                    />

                    <DeleteModal
                        show={sizeDeleteModal}
                        onDeleteClick={handleDeleteSize}
                        onCloseClick={() => setSizeDeleteModal(false)}
                    />

                    <DeleteModal
                        show={sizeRangeDeleteModal}
                        onDeleteClick={handleDeleteSizeRange}
                        onCloseClick={() => setSizeRangeDeleteModal(false)}
                    />
                    <Container fluid>
                        <Row>
                            <Col lg={6}>
                                <Row className="mb-3">
                                    <Col xs={6}>
                                        <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                                            <h5 className="fw-semibold mb-0">Color</h5>
                                        </div>
                                    </Col>
                                    <Col xs={6} className='d-flex justify-content-end'>
                                        <div className="col-auto order-2 order-sm-3 ms-auto">
                                            <div className="hstack gap-2">
                                                <button className='btn btn-primary' onClick={() => { setSubCategoryModal(true) }}>
                                                    + Add Color
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Card>
                                    <CardBody>
                                        {colorData && colorData?.length > 0 ? (
                                            <TableContainer
                                                columns={ColorColumns}
                                                data={colorData || []}
                                                isGlobalFilter={true}
                                                customPageSize={5}
                                                divClass="table-responsive mb-1"
                                                tableClass="mb-0 align-middle table-border table-wrap"
                                                theadClass="table-light text-muted text-wrap"
                                                SearchPlaceholder="Search Color" 
                                                manualPagination={false}
                                                manualSorting={false}
                                            />
                                        ) : (
                                            <div className="py-4 text-center">
                                                <div>
                                                    <i className="ri-search-line display-5 text-success"></i>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>{notFound.loadingContent}</h5>
                                                </div>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>

                                <Modal
                                    isOpen={subCategoryModal}
                                    toggle={toggle}
                                    modalClassName="zoomIn"
                                    centered
                                >
                                    <Form onSubmit={validation.handleSubmit}>
                                        <ModalHeader toggle={toggle} className="p-3 bg-light p-3">
                                            {isEdit ? 'Edit color' : 'Add color'}
                                        </ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <div className='p-2'>
                                                    <Col lg={12}>
                                                        <Label>Color Name</Label>
                                                        <Input
                                                            type="text"
                                                            title="Name"
                                                            name='color_name'
                                                            maxLength={150}
                                                            placeholder='Enter color name'
                                                            value={validation.values.color_name}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={!!(validation.touched.color_name &&
                                                                validation.errors.color_name)}
                                                        ></Input>
                                                        {validation.touched.color_name &&
                                                            validation.errors.color_name ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.color_name}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                </div>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                color="none"
                                                className="btn btn-ghost-success"
                                                onClick={toggle}
                                            >
                                                {categoryLabels.cancel}
                                            </Button>
                                            <Button type="submit" color="primary">
                                                {isEdit ? categoryLabels.update : categoryLabels.submit}
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                            </Col>
                            <Col lg={6}>
                                <Row className="mb-3">
                                    <Col xs={6}>
                                        <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                                            <h5 className="fw-semibold mb-0">Size</h5>
                                        </div>
                                    </Col>
                                    <Col xs={6} className='d-flex justify-content-end'>
                                        <div className="col-auto order-2 order-sm-3 ms-auto">
                                            <div className="hstack gap-2">
                                                <button className='btn btn-primary' onClick={() => { setSizeModal(true) }}>
                                                    + Add Size
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Card>
                                    <CardBody>
                                        {sizeData && sizeData?.length > 0 ? (
                                            <TableContainer
                                                columns={SizeColumns}
                                                data={sizeData || []}
                                                isGlobalFilter={true}
                                                customPageSize={5}
                                                divClass="table-responsive mb-1"
                                                tableClass="mb-0 align-middle table-border"
                                                theadClass="table-light text-muted table-wrap"
                                                SearchPlaceholder="Search Size"
                                                manualPagination={false}
                                                manualSorting={false}
                                            />
                                        ) : (
                                            <div className="py-4 text-center">
                                                <div>
                                                    <i className="ri-search-line display-5 text-success"></i>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>{notFound.loadingContent}</h5>
                                                </div>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>

                                <Modal
                                    isOpen={sizeModal}
                                    toggle={toggleSize}
                                    modalClassName="zoomIn"
                                    centered
                                >
                                    <Form onSubmit={sizeValidation.handleSubmit}>
                                        <ModalHeader toggle={toggleSize} className="p-3 bg-light p-3">
                                            {isSizeEdit ? 'Edit size' : 'Add size'}
                                        </ModalHeader>
                                        <ModalBody>
                                            <Row>
                                                <div className='p-2'>
                                                    <Col lg={12}>
                                                        <Label>Size</Label>
                                                        <Input
                                                            type="text"
                                                            title="Size"
                                                            name='size'
                                                            maxLength={150}
                                                            placeholder='Enter size'
                                                            value={sizeValidation.values.size}
                                                            onBlur={sizeValidation.handleBlur}
                                                            onChange={sizeValidation.handleChange}
                                                            invalid={!!(sizeValidation.touched.size &&
                                                                sizeValidation.errors.size)}
                                                        ></Input>
                                                        {sizeValidation.touched.size &&
                                                            sizeValidation.errors.size ? (
                                                            <FormFeedback type="invalid">
                                                                {sizeValidation.errors.size}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </Col>
                                                </div>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                color="none"
                                                className="btn btn-ghost-success"
                                                onClick={toggleSize}
                                            >
                                                {categoryLabels.cancel}
                                            </Button>
                                            <Button type="submit" color="primary">
                                                {isEdit ? categoryLabels.update : categoryLabels.submit}
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                            </Col>

                            <Col lg={6}>
                                <Row className="mb-3">
                                    <Col xs={6}>
                                        <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                                            <h5 className="fw-semibold mb-0">{sizeRangeLabels?.sizeRange}</h5>
                                        </div>
                                    </Col>
                                    <Col xs={6} className='d-flex justify-content-end'>
                                        <div className="col-auto order-2 order-sm-3 ms-auto">
                                            <div className="hstack gap-2">
                                                <button className='btn btn-primary' onClick={() => { setSizeRangeModal(true) }}>
                                                    + {categoryLabels?.addCategory} {sizeRangeLabels?.sizeRange}
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Card>
                                    <CardBody>
                                        {sizeRangeData && sizeRangeData?.length > 0 ? (
                                            <TableContainer
                                                columns={SizeRangeColumns}
                                                data={sizeRangeData || []}
                                                isGlobalFilter={true}
                                                customPageSize={5}
                                                divClass="table-responsive mb-1"
                                                tableClass="mb-0 align-middle table-border"
                                                theadClass="table-light text-muted table-wrap"
                                                SearchPlaceholder="Search Size"
                                                manualPagination={false}
                                                manualSorting={false}
                                            />
                                        ) : (
                                            <div className="py-4 text-center">
                                                <div>
                                                    <i className="ri-search-line display-5 text-success"></i>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>{notFound.loadingContent}</h5>
                                                </div>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>

                                <Modal
                                    isOpen={sizeRangeModal}
                                    toggle={toggleSizeRange}
                                    modalClassName="zoomIn"
                                    centered
                                >
                                    {sizeRangeLoading && <LoaderBlur />}
                                    <Form onSubmit={sizeRangeValidation.handleSubmit}>
                                        <ModalHeader toggle={toggleSizeRange} className="p-3 bg-light p-3">
                                            {isSizeRangeEdit ? 'Edit size range' : 'Add size range'}
                                        </ModalHeader>
                                        <ModalBody>
                                            <Row>

                                                <Col sm={6}>
                                                    <Label>{productPurchase?.fromSize}</Label>
                                                    <Input
                                                        type="text"
                                                        title={productPurchase?.fromSize}
                                                        name="fromSize"
                                                        maxLength={150}
                                                        placeholder={PlaceHolderFormat(productPurchase?.fromSize)}
                                                        value={sizeRangeValidation.values.fromSize}
                                                        onBlur={sizeRangeValidation.handleBlur}
                                                        onChange={sizeRangeValidation.handleChange}
                                                        invalid={!!(sizeRangeValidation.touched.fromSize &&
                                                            sizeRangeValidation.errors.fromSize)}
                                                    ></Input>
                                                    {sizeRangeValidation.touched.fromSize &&
                                                        sizeRangeValidation.errors.fromSize ? (
                                                        <FormFeedback type="invalid">
                                                            {sizeRangeValidation.errors.fromSize}
                                                        </FormFeedback>
                                                    ) : null}
                                                </Col>

                                                <Col sm={6}>
                                                    <Label>To Size</Label>
                                                    <Input
                                                        type="text"
                                                        title={productPurchase?.toSize}
                                                        name="toSize"
                                                        maxLength={150}
                                                        placeholder={PlaceHolderFormat(productPurchase?.toSize)}
                                                        value={sizeRangeValidation.values.toSize}
                                                        onBlur={sizeRangeValidation.handleBlur}
                                                        onChange={sizeRangeValidation.handleChange}
                                                        invalid={!!(sizeRangeValidation.touched.toSize &&
                                                            sizeRangeValidation.errors.toSize)}
                                                    ></Input>
                                                    {sizeRangeValidation.touched.toSize &&
                                                        sizeRangeValidation.errors.toSize ? (
                                                        <FormFeedback type="invalid">
                                                            {sizeRangeValidation.errors.toSize}
                                                        </FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-multiple-default" className="form-label">{sizeRangeLabels?.size}</Label>
                                                        <Select
                                                            options={sizeRangeOption}
                                                            isMulti={true}
                                                            onChange={(selectedOptions: OptionType[]) => {
                                                                const selectedValues = selectedOptions.map((option) => option.value);
                                                                sizeRangeValidation.setFieldValue("sizes", selectedValues);
                                                            }}
                                                            name="sizes"
                                                            value={
                                                                sizeRangeValidation?.values?.sizes
                                                                    ? sizeRangeOption?.filter((option) =>
                                                                        sizeRangeValidation.values.sizes!.includes(option.value as never)
                                                                    )
                                                                    : []
                                                            }
                                                            styles={{
                                                                multiValueLabel: () => ({
                                                                    color: 'white !important',
                                                                    padding: "2px"
                                                                }),
                                                            }} //here i need to add the custom css because i can't able to show the Label in live that's why.
                                                        />
                                                        {sizeRangeValidation.touched.sizes && sizeRangeValidation.errors.sizes && (
                                                            <div className="error-message-drop">{sizeRangeValidation.errors.sizes}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                color="none"
                                                className="btn btn-ghost-success"
                                                onClick={toggleSizeRange}
                                            >
                                                {categoryLabels.cancel}
                                            </Button>
                                            {btnLoading ? (
                                                <Button disabled={btnLoading}>
                                                    <Spinner size="sm" className="me-2" />
                                                </Button>
                                            ) : (
                                                <Button type="submit" color="primary">
                                                    {isSizeRangeEdit ? categoryLabels.update : categoryLabels.submit}
                                                </Button>
                                            )}
                                        </ModalFooter>
                                    </Form>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
    );
};

export default ColorAndSize;
