import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import avatar3 from "../../assets/images/users/user-dummy-img.jpg";
import { getFinancialYear, getRole } from "utils/helpers/sessionStores";
import { RolesEnum } from "utils/helpers/enums";
import { listOfStaffRecentOrder, categoryWiseAnalysis } from 'api/dashboardApi';
import { useEffect, useState } from 'react';
import { currencyFormat, notFound, sectionLabels } from 'utils/helpers/constant';
import { useMainContext } from 'Context/Context';

interface RecentOrderData {
    amount?: any;
    category?: any;
    OrderId: string;
    percentage?: number;
    quantity?: number;
    ProfileImage: string;
    Customer: string | null;
    Product: string;
    Amount: number;
    Status: string;
}

const RecentOrders = () => {

    const { recentOrderData, setRecentOrderData } = useMainContext();
    const [staffRecentOrderData, setStaffRecentOrderData] = useState([]);
    const role = getRole();
    const financialYear = getFinancialYear();
    const statusColors: { [key: string]: string } = {
        Delivered: "primary",
        Returned: "danger",
        Success: "success",
        Pending: "warning",
        'Partial-Returned': "info",
    };

    useEffect(() => {
        if (role === RolesEnum.SELLER) {
            categoryWiseAnalysis({financialYear})
                .then((resp) => {
                    setRecentOrderData(resp.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            listOfStaffRecentOrder()
                .then((resp) => {
                    setStaffRecentOrderData(resp.data)
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [])

    return (
        <React.Fragment>
            <Col xl={role === RolesEnum.SELLER ? 6 : 12}>
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">{role === sectionLabels.seller ? 'Sub Categorywise Sales Analysis' : 'Recent Order'}</h4>
                    </CardHeader>

                    <CardBody>
                        <div className="table-responsive table-card table-responsive-scroll">
                            <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead className="text-muted table-light sticky-top">
                                    <tr>
                                        <th scope="col">
                                            {role === sectionLabels.seller ? 'Sr No.' : 'Order Id'}
                                        </th>
                                        <th scope="col">
                                            {role === sectionLabels.seller ? 'Sub-Category' : 'Customer Name'}
                                        </th>
                                        <th scope="col">
                                            {role === sectionLabels.seller ? 'Qty' : 'Status'}
                                        </th>
                                        <th scope="col">Amount</th>
                                        {role === sectionLabels.seller && (
                                            <th scope="col">Percentage %</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {((role === RolesEnum.SELLER ? recentOrderData : staffRecentOrderData) as RecentOrderData[])?.length > 0 ? (
                                        ((role === RolesEnum.SELLER ? recentOrderData : staffRecentOrderData) as RecentOrderData[])?.map((item: RecentOrderData, key: number) => (
                                            <>
                                                <tr key={key}>
                                                    <td>
                                                        {role === sectionLabels.seller && (
                                                            <div className="fw-medium link-primary">#{key + 1}</div>
                                                        )}
                                                        {role !== sectionLabels.seller && (
                                                            <div className="fw-medium link-primary">#{item.OrderId}</div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1"> {role === sectionLabels.seller ? item?.category ? item.category : notFound?.nullData : item.Customer ? item.Customer : notFound?.nullData}</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {role === sectionLabels.seller && (
                                                            <span>{item.quantity}</span>
                                                        )}
                                                        {role !== sectionLabels.seller && (
                                                            <span className={`badge bg-${statusColors[item.Status]}-subtle text-${statusColors[item?.Status]}`}>
                                                                {item?.Status ?? notFound?.nullData}
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <span>{currencyFormat.currency}{role === sectionLabels.seller ? item.amount : item.Amount}</span>
                                                    </td>
                                                    {role === sectionLabels.seller && (
                                                        <td>
                                                            <span className="text-success">{item?.percentage}%</span>
                                                        </td>
                                                    )}
                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5}>
                                                <div className="py-4 text-center">
                                                    <div>
                                                        <i className="ri-search-line display-5 text-success"></i>
                                                    </div>

                                                    <div className="mt-4">
                                                        <h5>{notFound.dataNotFound}</h5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>

                            </table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment >
    );
};

export default RecentOrders;