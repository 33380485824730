import TableContainer from 'Components/Common/TableContainer';
import Loader from 'Components/Common/loader';
import LoaderBlur from 'Components/Common/BlurLoader';
import { listOfProductPurchase } from 'api/productPurchase';
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Container, Row } from 'reactstrap';
import { currencyFormat, financialYear, notFound } from 'utils/helpers/constant';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { dateFormat } from 'utils/helpers/comman';
import { getFinancialYear } from 'utils/helpers/sessionStores';

const ProductPurchaseEntry = () => {

    const navigate = useNavigate();
    const [productPurchaseData, setProductPurchaseData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const [customPageSize, setCustomPageSize] = useState(5);
    const [searchValue, setSearchValue] = useState('');
    const [searchProductPurchaseData, setSearchProductPurchaseData] = useState([]);
    const financialYear = getFinancialYear();
    const [columnName, setColumnName] = useState("id");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [isFirstFetch, setIsFirstFetch] = useState(true);

    const handleFetchSorting = (page: number, id: string, order: string) => {
        setCurrentPage(page);
        setColumnName(id);
        setSortOrder(order);
    };

    const fetchProductPurchase = () => {
        setIsLoading(true);
        const payload = {
            sortOrder: isFirstFetch
                ? [{ field: "bill_date", order: sortOrder }, { field: columnName, order: sortOrder }]
                : [{ field: columnName, order: sortOrder }],
            pageNumber: currentPage,
            pageSize: customPageSize,
            financialYear: financialYear
        }
        listOfProductPurchase(payload).then((response) => {
            setTotalNumberOfRows(response?.data?.numberOfRows)
            setTotalRecords(response.data.totalRecordsCount)
            setTotalPages(response?.data?.totalPages)
            setProductPurchaseData(response?.data?.listOfProductPurchaseData)
            setIsFirstFetch(false);
        }).catch((err) => {
            return err
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const fetchSearchData = () => {
        setIsLoading(true);
        const payload = {
            sortOrder: [{ field: "bill_date", order: sortOrder }, { field: columnName, order: sortOrder }],
            pageNumber: currentPage,
            pageSize: customPageSize,
            financialYear: financialYear,
            searchBar: searchValue
        }
        listOfProductPurchase(payload).then((response) => {
            setTotalNumberOfRows(response?.data?.numberOfRows)
            setTotalRecords(response.data.totalRecordsCount)
            setTotalPages(response?.data?.totalPages)
            setSearchProductPurchaseData(response?.data?.listOfProductPurchaseData)
        }).catch((err) => {
            setSearchProductPurchaseData([]);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (searchValue) {
            fetchSearchData();
        } else {
            fetchProductPurchase();
        }
    }, [currentPage, customPageSize, searchValue, columnName, sortOrder]);

    const handleFetchData = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearchValueChange = (value: any) => {
        if (value !== searchValue) {
            setSearchValue(value);
            setCurrentPage(1);
        }
    };

    useEffect(() => {
        if (searchValue === '') {
            setCurrentPage(1);
        }
    }, [searchValue]);

    const ProductPurchaseEntryColumn = useMemo(
        () =>
            [{
                header: "Bill No.",
                accessorKey: 'bill_no',
                enableColumnFilter: false,
            },
            {
                header: "Bill Date",
                accessorKey: 'bill_date',
                enableColumnFilter: false,
                cell: (cell: { row: { original: { bill_date: string | null } } }) => {
                    const billDate = cell?.row?.original?.bill_date
                    return <> {dateFormat(billDate) ?? notFound?.nullData}</>;
                },
            },
            {
                header: "Supplier Name",
                accessorKey: 'supplier_name',
                enableColumnFilter: false,
            },
            {
                header: "Total Product",
                accessorKey: 'totalProduct',
                enableColumnFilter: false,
            },
            {
                header: "Total Quantity",
                accessorKey: 'total_quantity',
                enableColumnFilter: false,
            },
            {
                header: "Total Amount",
                accessorKey: 'total_amount',
                enableColumnFilter: false,
                cell: (cell: { row: { original: { total_amount: number } } }) => (
                    <span>{currencyFormat?.currency}{cell.row.original.total_amount}</span>
                ),
            },
            {
                header: "Status",
                accessorKey: 'status',
                enableColumnFilter: false,
                cell: (cell: any) => {
                    switch (cell.getValue()) {
                        case "Draft":
                            return <span className="badge text-uppercase bg-warning-subtle text-warning"> {cell.getValue()} </span>;
                        case "Publish":
                            return <span className="badge text-uppercase bg-success-subtle text-success"> {cell.getValue()} </span>;
                        default:
                            return <span className="badge text-uppercase bg-warning-subtle text-warning"> {cell.getValue()} </span>;
                    }
                }
            },
            {
                header: "Actions",
                enableSorting: false,
                cell: (cell: {
                    row: {
                        original: {
                            status: string; id: number
                        }
                    }
                }) => (
                    <div className="hstack gap-2">
                        <button
                            id={`editMode-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => {
                                handleClickOfEditSales(cell?.row?.original?.id)
                            }}
                        >
                            {cell.row.original.status === 'Draft' ? <i className="ri-pencil-fill align-bottom" /> : <i className="ri-eye-line align-bottom" />}
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content={cell.row.original.status === 'Draft' ? 'Edit' : 'View'}
                                anchorId={`editMode-${cell?.row?.original?.id}`}
                            />
                        </button>
                    </div>
                ),
            }
            ],
        []
    );

    const handleClickOfEditSales = (productPurchasedId: number) => {
        navigate(`/productPurchase/${productPurchasedId}`)
    }
    document.title = "Product Purchase"
    return (
        <div className='page-content'>
            <Container>
                <Row className="mb-3">
                    <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                        <h5 className="fw-semibold mb-0">Product Purchase Entry</h5>
                    </div>
                    <div className="col-auto order-2 order-sm-3 ms-auto">
                        <div className="hstack gap-2">
                            <button
                                className="btn btn-primary createTask"
                                type="button"
                                onClick={() => { navigate('/productPurchase') }}
                            >
                                + Add
                            </button>
                        </div>
                    </div>
                </Row>
                <Card>
                    <CardBody>
                        {isLoading && <LoaderBlur />}
                        {productPurchaseData && productPurchaseData?.length > 0 ? (
                            <TableContainer
                                columns={ProductPurchaseEntryColumn}
                                data={searchValue ? searchProductPurchaseData : productPurchaseData || []}
                                isGlobalFilter={true}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                totalRecords={totalRecords}
                                fetchData={handleFetchData}
                                onSearch={handleSearchValueChange}
                                customPageSize={customPageSize}
                                setCustomPageSize={setCustomPageSize}
                                divClass="table-responsive mb-1"
                                tableClass="mb-0 align-middle table-border"
                                theadClass="table-light text-muted table-wrap"
                                SearchPlaceholder="Search Product Purchase Entry"
                                manualPagination={true}
                                totalNumberOfRows={totalNumberOfRows}
                                manualFiltering={true}
                                fetchSortingData={handleFetchSorting}
                                manualSorting={true}
                            />
                        ) : (
                            <div className="py-4 text-center">
                                <div>
                                    <i className="ri-search-line display-5 text-success"></i>
                                </div>

                                <div className="mt-4">
                                    <h5>{notFound.loadingContent}</h5>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default ProductPurchaseEntry