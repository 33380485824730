import LoaderBlur from 'Components/Common/BlurLoader';
import DataCard from 'Components/Common/DataCard';
import TableContainer from 'Components/Common/TableContainer';
import { countOfStockDetails, listOfStockDetails } from 'api/stockDetailsApi';
import { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Container, Row } from 'reactstrap'
import { dateFormat } from 'utils/helpers/comman';
import { addSalesOrderReturn, categoryLabels, currencyFormat, hsnLabels, notFound, productPurchase, stockDetails, stockDetailsAccessKey, subCategoryLabels, supplierLabels } from 'utils/helpers/constant';

interface CountStockData {
    totalPurchaseAmount: number,
    totalMrpAmount: number,
    totalStockQuantity: number
}

const StockDetails = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [stockDetailsData, setStockDetailsData] = useState([]);
    const [searchStockDetailsData, setSearchStockDetailsData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [countOfStockDetailsData, setCountOfStockDetailsData] = useState<CountStockData>();
    const [customPageSize, setCustomPageSize] = useState(5);
    const [columnName, setColumnName] = useState("id");
    const [sortOrder, setSortOrder] = useState("DESC");

    const handleFetchSorting = (page: number, id: string, order: string) => {
        setCurrentPage(page);
        setColumnName(id);
        setSortOrder(order);
    };

    const handleSearchValueChange = (value: string) => {
        if (value !== searchValue) {
            setSearchValue(value);
            setCurrentPage(1);
        }
    };

    const fetchCountOFStockDetails = () => {
        setIsLoading(true);
        countOfStockDetails().then((response) => {
            setCountOfStockDetailsData(response?.data)
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const fetchStockDetails = () => {
        setIsLoading(true);
        const payload = {
            sortOrder:  { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize,
        };

        listOfStockDetails(payload)
            .then((response) => {
                setTotalRecords(response.data.totalRecordsCount)
                setTotalPages(response?.data?.totalPages)
                setStockDetailsData(response?.data?.listOfProductData);
                setTotalNumberOfRows(response?.data?.numberOfRows)
            })
            .catch((err) => err)
            .finally(() => {
                setIsLoading(false);
            });
    }

    const fetchSearchData = () => {
        setIsLoading(true);
        const payload = {
            sortOrder:  { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize,
            searchBar: searchValue
        };

        listOfStockDetails(payload)
            .then((response) => {
                setTotalRecords(response.data.totalRecordsCount)
                setTotalPages(response?.data?.totalPages)
                setSearchStockDetailsData(response?.data?.listOfProductData)
                setTotalNumberOfRows(response?.data?.numberOfRows)
            })
            .catch((err) => {
                setSearchStockDetailsData([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleFetchData = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        fetchCountOFStockDetails();
    }, []);

    useEffect(() => {
        if (searchValue) {
            fetchSearchData();
        } else {
            fetchStockDetails()
        }
    }, [currentPage, searchValue, customPageSize, columnName, sortOrder])

    useEffect(() => {
        if (searchValue === '') {
            setCurrentPage(1);
        }
    }, [searchValue]);

    const StockDetailsColumn = useMemo(
        () => [
            {
                header: `${addSalesOrderReturn?.sku}`,
                accessorKey: `${stockDetailsAccessKey?.skuKey}`,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { sku: string | null } } }) => {
                    const sku = cell?.row?.original?.sku
                    return <> {sku ?? notFound?.nullData}</>;
                },
            },
            {
                header: `${addSalesOrderReturn?.Quantity}`,
                accessorKey: `${addSalesOrderReturn?.quantity}`,
                enableColumnFilter: false,
            },
            {
                header: `${productPurchase?.productCode}`,
                accessorKey: `${stockDetailsAccessKey?.productCodeKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${productPurchase?.productName}`,
                accessorKey: `${stockDetailsAccessKey?.productNameKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${addSalesOrderReturn?.Color}`,
                accessorKey: `${stockDetailsAccessKey?.colorNameKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${addSalesOrderReturn?.Size}`,
                accessorKey: `${addSalesOrderReturn?.size}`,
                enableColumnFilter: false,
            },
            {
                header: `${addSalesOrderReturn?.SaleRate}`,
                accessorKey: `${stockDetailsAccessKey?.saleRateKey}`,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { saleRate: string | null } } }) => {
                    const saleRate = cell?.row?.original?.saleRate
                    return <> {saleRate ? `${currencyFormat?.currency}${saleRate}` : notFound?.nullData}</>;
                },
            },
            {
                header: `${categoryLabels?.categoryName}`,
                accessorKey: `${stockDetailsAccessKey?.categoryNameKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${subCategoryLabels?.subCategoryNameOfKey}`,
                accessorKey: `${stockDetailsAccessKey?.subcategoryNameKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${productPurchase?.mrp}`,
                accessorKey: `${addSalesOrderReturn?.mrp}`,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { mrp: string | null } } }) => {
                    const mrp = cell?.row?.original?.mrp
                    return <> {mrp ? `${currencyFormat?.currency}${mrp}` : notFound?.nullData}</>;
                },
            },
            {
                header: `${stockDetails?.sizeRange}`,
                accessorKey: `${stockDetailsAccessKey?.sizeRangeKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${productPurchase?.productDescription}`,
                accessorKey: `${stockDetailsAccessKey?.productDescKey}`,
                enableColumnFilter: false,
                cell: (cell: any) => {
                    const description = cell?.row?.original?.productDesc;
                    const truncatedDescription = description?.length > 50
                        ? `${description.substring(0, 50)}...`
                        : description;

                    return (
                        <div className="tooltip-container" title={description}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: truncatedDescription ? truncatedDescription : notFound?.nullData,
                                }}
                            />
                            <span className="tooltip-text">{description}</span>
                        </div>
                    );
                },
                minWidth: 100,
            },
            {
                header: `${supplierLabels?.supplierName}`,
                accessorKey: `${stockDetailsAccessKey?.supplierNameKey}`,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { supplierName: string | null } } }) => {
                    const supplierName = cell?.row?.original?.supplierName
                    return <> {supplierName ?? notFound?.nullData}</>;
                },
            },
            {
                header: `${productPurchase?.purchaseRate}`,
                accessorKey: `${stockDetailsAccessKey?.purchaseRateKey}`,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { purchaseRate: string | null } } }) => {
                    const purchaseRate = cell?.row?.original?.purchaseRate
                    return <> {purchaseRate ? `${currencyFormat?.currency}${purchaseRate}` : notFound?.nullData}</>;
                },
            },
            {
                header: `${hsnLabels?.hsnCode}`,
                accessorKey: `${stockDetailsAccessKey?.hsnCodeKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${stockDetails?.billNumber}`,
                accessorKey: `${stockDetailsAccessKey?.billNoKey}`,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { billNo: string | null } } }) => {
                    const billNo = cell?.row?.original?.billNo
                    return <> {billNo ?? notFound?.nullData}</>;
                },
            },
            {
                header: `${stockDetails?.billDate}`,
                accessorKey: `${stockDetailsAccessKey?.billDateKey}`,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { billDate: string | null } } }) => {
                    const billDate = cell?.row?.original?.billDate
                    return <> {dateFormat(billDate) ?? notFound?.nullData}</>;
                },
            },
        ],
        []
    );

    const cardData = [
        {
            title: 'Stock Quantity',
            count: countOfStockDetailsData?.totalStockQuantity,
            iconClass: 'ri-funds-fill',
            bgColorClass: 'bg-success-subtle',
            iconTextClass: 'text-success',
            decimals: 0
        },
        {
            title: 'MRP Amount',
            count: countOfStockDetailsData?.totalMrpAmount,
            iconClass: 'ri-money-dollar-box-line',
            bgColorClass: 'bg-primary-subtle',
            iconTextClass: 'text-primary',
            decimals: 2
        },
        {
            title: 'Purchase Amount',
            count: countOfStockDetailsData?.totalPurchaseAmount,
            iconClass: 'ri-currency-line',
            bgColorClass: 'bg-danger-subtle',
            iconTextClass: 'text-warning',
            decimals: 2
        },
    ];

    document.title = stockDetailsAccessKey.title;

    return (
        <div className='page-content'>
            {isLoading && <LoaderBlur />}

            <Container>
                <Row className="mb-3">
                    <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                        <h5 className="fw-semibold mb-0">{stockDetailsAccessKey?.title}</h5>
                    </div>
                </Row>

                <Row>
                    {cardData.map((data, index) => (
                        <DataCard
                            key={index}
                            title={data.title}
                            count={data.count}
                            iconClass={data.iconClass}
                            bgColorClass={data.bgColorClass}
                            iconTextClass={data?.iconTextClass}
                            decimals={data?.decimals}
                        />
                    ))}
                </Row>

                <Card>
                    <CardBody>
                        {stockDetailsData && stockDetailsData?.length > 0 ? (
                            <TableContainer
                                columns={StockDetailsColumn}
                                data={searchValue ? searchStockDetailsData : stockDetailsData || []}
                                isGlobalFilter={true}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                totalRecords={totalRecords}
                                fetchData={handleFetchData}
                                onSearch={handleSearchValueChange}
                                fetchSortingData={handleFetchSorting}
                                customPageSize={customPageSize}
                                setCustomPageSize={setCustomPageSize}
                                divClass="table-responsive custom-table mb-1"
                                tableClass="mb-0 align-middle table-border"
                                theadClass="table-light text-muted table-wrap"
                                SearchPlaceholder="Search Via SKU"
                                manualPagination={true}
                                manualFiltering={true}
                                totalNumberOfRows={totalNumberOfRows}
                                manualSorting={true}
                            />
                        ) : (
                            <div className="py-4 text-center">
                                <div>
                                    <i className="ri-search-line display-5 text-success"></i>
                                </div>
                                <div className="mt-4">
                                    <h5>{notFound.loadingContent}</h5>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default StockDetails
