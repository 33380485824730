import { listOfSalesReturn, deleteSalesReturn } from 'api/salesOrderApi'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Container, Row } from 'reactstrap'
import { ResponseStatusEnum } from 'utils/helpers/enums'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { toast } from 'react-toastify'
import DeleteModal from 'Components/Common/DeleteModal'
import { addSalesOrderReturn, notFound, salesReturnList } from 'utils/helpers/constant'
import LoaderBlur from 'Components/Common/BlurLoader';
import TableContainer from 'Components/Common/TableContainer'
import { orderReturnFormatDate } from 'utils/helpers/comman'
import { getFinancialYear } from 'utils/helpers/sessionStores'

interface Sales {
    doc_no: number,
    doc_date: string,
    total_quantity: number,
    total_products: number,
    tax: number | null,
    total_amount: number,
    status: string
}

const SalesOrderReturn = () => {
    const [listOfSales, setListOfSales] = useState<Sales[] | []>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [categoryDeleteModal, setCategoryDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState<number>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const [customPageSize, setCustomPageSize] = useState(5);
    const [searchValue, setSearchValue] = useState('');
    const [searchSalesReturnData, setSearchSalesReturnData] = useState([]);
    const financialYear = getFinancialYear();
    const [columnName, setColumnName] = useState("id");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [isFirstFetch, setIsFirstFetch] = useState(true);

    const handleFetchSorting = (page: number, id: string, order: string) => {
        setCurrentPage(page);
        setColumnName(id);
        setSortOrder(order);
    };

    const listOfSalesApi = () => {
        setIsLoading(true)
        let data = {
            sortOrder: isFirstFetch
                ? [{ field: "doc_date", order: sortOrder }, { field: columnName, order: sortOrder }]
                : [{ field: columnName, order: sortOrder }],
            pageNumber: currentPage,
            pageSize: customPageSize,
            financialYear: financialYear
        }
        listOfSalesReturn(data).then((response) => {
            setTotalRecords(response.totalRecordsCount)
            setTotalPages(response?.data?.totalPages)
            setTotalNumberOfRows(response?.data?.numberOfRows)
            setIsFirstFetch(false)
            if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                const formattedData = response?.data?.listOfSalesReturnData?.map((item: any) => ({
                    ...item,
                    doc_date: new Date(item.doc_date).toLocaleDateString('en-GB')
                }));
                setListOfSales(formattedData);
            } else setListOfSales([]);
        }).catch((err) => setListOfSales([])).finally(() => setIsLoading(false));
    }

    const fetchSearchData = () => {
        setIsLoading(true)
        let data = {
            sortOrder: [{ field: "doc_date", order: sortOrder }, { field: columnName, order: sortOrder }],
            pageNumber: currentPage,
            pageSize: customPageSize,
            financialYear: financialYear,
            searchBar: searchValue
        }
        listOfSalesReturn(data).then((response) => {
            setTotalRecords(response.totalRecordsCount)
            setTotalPages(response?.data?.totalPages)
            setTotalNumberOfRows(response?.data?.numberOfRows)
            if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                const formattedData = response?.data?.listOfSalesReturnData?.map((item: any) => ({
                    ...item,
                    doc_date: new Date(item.doc_date).toLocaleDateString('en-GB')
                }));
                setSearchSalesReturnData(formattedData);
            } else setSearchSalesReturnData([]);
        }).catch((err) => setSearchSalesReturnData([])).finally(() => setIsLoading(false));
    }

    const handleFetchData = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearchValueChange = (value: string) => {
        if (value !== searchValue) {
            setSearchValue(value);
            setCurrentPage(1);
        }
    };

    useEffect(() => {
        if (searchValue) {
            fetchSearchData();
        } else {
            listOfSalesApi()
        }
    }, [currentPage, customPageSize, searchValue, columnName, sortOrder])

    useEffect(() => {
        if (searchValue === '') {
            setCurrentPage(1);
        }
    }, [searchValue]);

    const CategoryColumns = useMemo(
        () => [
            {
                header: salesReturnList.docNO,
                accessorKey: salesReturnList.doc_no,
                enableColumnFilter: false,
            },
            {
                header: salesReturnList.docDate,
                accessorKey: salesReturnList.doc_date,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { doc_date: any } } }) => {
                    const formattedDate = orderReturnFormatDate(cell?.row?.original?.doc_date)
                    return <>{formattedDate ?? notFound?.nullData}</>;
                },
            },
            {
                header: salesReturnList.totalProduct,
                accessorKey: salesReturnList.total_product,
                enableColumnFilter: false,
            },
            {
                header: salesReturnList.TotalQuantity,
                accessorKey: salesReturnList.total_quantity,
                enableColumnFilter: false,
            },
            {
                header: salesReturnList.totalAmount,
                accessorKey: salesReturnList.total_amount,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { total_amount: number } } }) => (
                    <span>A${cell.row.original.total_amount}</span>
                ),
            },
            {
                header: salesReturnList.actions,
                enableSorting: false,
                cell: (cell: {
                    row: {
                        original: {
                            status: string
                            id: number
                        }
                    }
                }) => (
                    <div className="hstack gap-2">
                        <Link to={`/salesOrderReturn/editSalesOrderReturn/${cell.row.original.id}`} >
                            <button
                                id={`editMode-${cell.row.original.id}`}
                                className="btn btn-sm btn-soft-info edit-list"
                            >
                                {cell.row.original.status === addSalesOrderReturn.draft ? <i className="ri-pencil-fill align-bottom" />
                                    : <i className="ri-eye-line align-bottom" />
                                }
                                <ReactTooltip
                                    place="bottom"
                                    variant="info"
                                    content={cell.row.original.status === salesReturnList.draft ? salesReturnList.edit : salesReturnList.view}
                                    anchorId={`editMode-${cell.row.original.id}`}
                                />
                            </button>
                        </Link>
                        {cell.row.original.status === salesReturnList.draft && (

                            <button
                                id={`delete-${cell?.row?.original?.id}`}
                                className="btn btn-sm btn-soft-danger remove-list"
                                onClick={() => { onClickDelete(cell?.row?.original?.id) }}
                            >
                                <i className="ri-delete-bin-5-fill align-bottom" />
                                <ReactTooltip
                                    place="bottom"
                                    variant="error"
                                    content="Delete"
                                    anchorId={`delete-${cell?.row?.original?.id}`}
                                />
                            </button>
                        )}

                    </div>
                ),
            },
        ], []);

    const onClickDelete = (id: number) => {
        setSelectedId(id)
        setCategoryDeleteModal(true);
    };

    const handleDeleteCategory = () => {
        setIsLoading(true);
        setCategoryDeleteModal(false);
        if (selectedId === undefined) {
            return;
        }
        deleteSalesReturn(selectedId).then((response) => {
            if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                listOfSalesApi();
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(err.somethingWrong);
        }).finally(() => {
            setIsLoading(false);
            setCategoryDeleteModal(false);
        });
    };

    document.title = "Sales Return"

    return (
        <div className='page-content'>
            <DeleteModal
                show={categoryDeleteModal}
                onDeleteClick={handleDeleteCategory}
                onCloseClick={() => setCategoryDeleteModal(false)}
            />
            <Container>
                <Row className="mb-4">
                    <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                        <h5 className="fw-semibold mb-0">{salesReturnList.salesReturnEntry}</h5>
                    </div>
                    <div className="col-auto order-2 order-sm-3 ms-auto">
                        <div className="hstack gap-2">
                            <Link
                                to={"/salesOrderReturn/addSalesOrderReturn"}
                                className="btn btn-primary"
                            >
                                {salesReturnList.addSales}
                            </Link>
                        </div>
                    </div>
                </Row>
                <Card>
                    {isLoading && <LoaderBlur />}
                    <CardBody>
                        {listOfSales && listOfSales?.length > 0 ? (
                            <TableContainer
                                columns={CategoryColumns}
                                data={searchValue ? searchSalesReturnData : listOfSales || []}
                                isGlobalFilter={true}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                totalRecords={totalRecords}
                                fetchData={handleFetchData}
                                onSearch={handleSearchValueChange}
                                customPageSize={customPageSize}
                                fetchSortingData={handleFetchSorting}
                                setCustomPageSize={setCustomPageSize}
                                divClass="table-responsive mb-1"
                                tableClass="mb-0 align-middle table-border"
                                theadClass="table-light text-muted table-wrap"
                                SearchPlaceholder="Search via Doc No."
                                manualPagination={true}
                                manualFiltering={true}
                                totalNumberOfRows={totalNumberOfRows}
                                manualSorting={true}
                            />
                        ) : (
                            <div className="py-4 text-center">
                                <div>
                                    <i className="ri-search-line display-5 text-success"></i>
                                </div>
                                <div className="mt-4">
                                    <h5>{notFound.loadingContent}</h5>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default SalesOrderReturn