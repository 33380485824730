import React, { useState } from 'react';
import { Card, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { SalesComparisonChart, StoreVisitsCharts } from './DashboardEcommerceCharts';
import { graphOfPayment, lastTenOrder } from "api/dashboardApi";
import { useEffect } from "react";
import { getRole } from 'utils/helpers/sessionStores';
import { RolesEnum } from 'utils/helpers/enums';
import { useMainContext } from 'Context/Context';

const StoreVisits = () => {
    const { orderData, setOrderData } = useMainContext();
    const role = getRole();
    useEffect(() => {
        if (role === RolesEnum.SELLER) {
            lastTenOrder()
                .then((resp) => {
                    setOrderData(resp?.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [])

    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0">Last 10 Days Sales Comparison</h4>
                    </CardHeader>
                    <div className="card-body">
                        <SalesComparisonChart dataColors='["--vz-primary"]' orderData={orderData} />
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default StoreVisits;