import { COUNT_OF_STOCK_DETAILS, LIST_OF_STOCK_DETAILS } from "./apiRoutes";
import { catalogServices } from "./apiServices";

export const listOfStockDetails = async (data: object) => {
  const response = await catalogServices.post(LIST_OF_STOCK_DETAILS, data);
  return response?.data;
};

export const countOfStockDetails = async () =>{
    const response = await catalogServices.get(COUNT_OF_STOCK_DETAILS);
    return response?.data;
};