import TableContainer from 'Components/Common/TableContainer'
import { useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Container, Row } from 'reactstrap';
import { listOfSalesOrderCustomer } from 'api/salesOrderApi';
import { notFound } from 'utils/helpers/constant';
import LoaderBlur from 'Components/Common/BlurLoader';

const CustomerList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [customerListData, setCustomerListData] = useState([]);
    const [searchCustomerListData, setSearchCustomerListData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [customPageSize, setCustomPageSize] = useState(5);
    const [columnName, setColumnName] = useState("id");
    const [sortOrder, setSortOrder] = useState("DESC");

    const handleFetchSorting = (page: number, id: string, order: string) => {
        setCurrentPage(page);
        setColumnName(id);
        setSortOrder(order);
    };

    const fetchCustomer = () => {
        setIsLoading(true);
        listOfSalesOrderCustomer({
            sortOrder: {
                [columnName]: sortOrder === "DESC" ? -1 : 1
            },
            pageNumber: currentPage,
            pageSize: customPageSize
        })
            .then((response) => {
                setCustomerListData(response?.data?.listOfCustomerData);
                setTotalRecords(response?.data?.totalRecordsCount)
                setTotalPages(response?.data?.totalPages)
                setTotalNumberOfRows(response?.data?.numberOfRows)
            })
            .catch((err) => err)
            .finally(() => {
                setIsLoading(false);
            });
    }

    const fetchSearchData = () => {
        setIsLoading(true);
        listOfSalesOrderCustomer({
            sortOrder: {
                [columnName]: sortOrder === "DESC" ? -1 : 1
            },
            pageNumber: currentPage,
            pageSize: customPageSize,
            searchBar: searchValue
        })
            .then((response) => {
                setSearchCustomerListData(response?.data?.listOfCustomerData);
                setTotalRecords(response?.data?.totalRecordsCount)
                setTotalPages(response?.data?.totalPages)
                setTotalNumberOfRows(response?.data?.numberOfRows)
            })
            .catch((err) => err)
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleFetchData = (page: number) => {
        setCurrentPage(page); // Update the current page
    };

    const handleSearchValueChange = (value: string) => {
        if (value !== searchValue) {
            setSearchValue(value);
            setCurrentPage(1);
        }
    };

    useEffect(() => {
        if (searchValue) {
            fetchSearchData();
        } else {
            fetchCustomer();
        }
    }, [currentPage, searchValue, customPageSize, columnName, sortOrder])

    useEffect(() => {
        if (searchValue === '') {
            setCurrentPage(1);
        }
    }, [searchValue]);

    const CategoryColumns = useMemo(
        () =>
            [{
                header: 'Name',
                accessorKey: `name`,
                enableColumnFilter: false,
            },
            {
                header: 'Email',
                accessorKey: `email`,
                enableColumnFilter: false,
            },
            {
                header: 'Contact No',
                accessorKey: `contact_no`,
                enableColumnFilter: false,
                cell: (cell: { row: { original: { contact_no: string | null } } }) => {
                    const contact_no = cell?.row?.original?.contact_no
                    return <> {contact_no ?? notFound?.nullData}</>;
                },
            },
            ],
        []
    );

    document.title = "Customer List";

    return (
        <div className='page-content'>
            <Container>
                <Row className="mb-3">
                    <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                        <h5 className="fw-semibold mb-0">Customer List</h5>
                    </div>
                </Row>
                <Card>
                    {isLoading && <LoaderBlur />}
                    <CardBody>
                        {customerListData && customerListData?.length > 0 ? (
                            <TableContainer
                                columns={CategoryColumns}
                                data={searchValue ? searchCustomerListData : customerListData || []}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                totalRecords={totalRecords}
                                totalNumberOfRows={totalNumberOfRows}
                                fetchData={handleFetchData}
                                onSearch={handleSearchValueChange}
                                isGlobalFilter={true}
                                customPageSize={customPageSize}
                                setCustomPageSize={setCustomPageSize}
                                divClass="table-responsive mb-1"
                                tableClass="mb-0 align-middle table-border table-wrap"
                                theadClass="table-light text-muted"
                                SearchPlaceholder="Search Customer..."
                                manualPagination={true}
                                manualFiltering={true}
                                fetchSortingData={handleFetchSorting}
                                manualSorting={true}
                            />
                        ) : (
                            <div className="py-4 text-center">
                                <div>
                                    <i className="ri-search-line display-5 text-success"></i>
                                </div>

                                <div className="mt-4">
                                    <h5>{notFound.loadingContent}</h5>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default CustomerList
