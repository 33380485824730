import {
  ADD_PRODUCT,
  CREATE_BILL_NO,
  DELETE_ADDED_PRODUCT,
  DELETE_ADDED_PRODUCT_VARIANTS,
  EDIT_PRODUCT,
  LIST_OF_ADDED_PRODUCT,
  LIST_OF_PRODUCT_CODE,
  LIST_OF_PRODUCT_PURCHASE,
  PRODUCT_DETAILS,
  SUPPLIER_PRODUCT_PURCHASE,
  VIEW_ADDED_PRODUCT,
  VIEW_PRODUCT_PURCHASE,
  GENERATE_PRODUCT_BARCODE,
  SIZE_RANGE,
  LIST_SIZE,
  LIST_SIZE_RANGE,
} from "./apiRoutes";
import {
  adminUsersServices,
  searchServices,
  catalogServices,
  catalogColorAndSizeServices,
} from "./apiServices";

export const supplierProductPurchase = async (data: object) => {
  const response = await adminUsersServices.post(
    SUPPLIER_PRODUCT_PURCHASE,
    data
  );
  return response?.data;
};

export const listOfProductPurchase = async (data: object) => {
  const response = await adminUsersServices.post(
    LIST_OF_PRODUCT_PURCHASE,
    data
  );
  return response?.data;
};

export const addProductPurchase = async (data: object) => {
  const response = await adminUsersServices.post(ADD_PRODUCT, data);
  return response?.data;
};

export const productDetails = async (data: object) => {
  const response = await adminUsersServices.post(PRODUCT_DETAILS, data);
  return response?.data;
};

export const listOfAddedProduct = async (data: object) => {
  const response = await adminUsersServices.post(LIST_OF_ADDED_PRODUCT, data);
  return response?.data;
};

export const addedProductDelete = async (data: object) => {
  const response = await adminUsersServices.put(DELETE_ADDED_PRODUCT, data);
  return response?.data;
};

export const listOfProductCode = async (data: object) => {
  const response = await searchServices.post(LIST_OF_PRODUCT_CODE, data);
  return response?.data;
};

export const createBillNumber = async (data: object) => {
  const response = await adminUsersServices.get(CREATE_BILL_NO, data);
  return response?.data;
};

export const viewProductPurchaseEntry = async (id: number | undefined) => {
  if (id !== undefined) {
    const response = await adminUsersServices.get(
      `${VIEW_PRODUCT_PURCHASE}/${id}`
    );
    return response?.data;
  }
};

export const editProductPurchaseEntry = async (id: number, data: object) => {
  if (id !== undefined) {
    const response = await adminUsersServices.put(
      `${VIEW_PRODUCT_PURCHASE}/${id}`,
      data
    );
    return response?.data;
  }
};

export const viewAddedProduct = async (id: number) => {
  if (id !== undefined) {
    const response = await adminUsersServices.get(
      `${VIEW_ADDED_PRODUCT}/${id}`
    );
    return response?.data;
  }
};

export const editAddedProduct = async (id: number, data: object) => {
  const response = await adminUsersServices.put(`${EDIT_PRODUCT}/${id}`, data);
  return response?.data;
};

export const deleteAddedProduct = async (data: object) => {
  const response = await adminUsersServices.put(
    `${DELETE_ADDED_PRODUCT_VARIANTS}`,
    data
  );
  return response?.data;
};

export const addProductWithPurchaseProduct = async (data: object) => {
  const response = await adminUsersServices.post(ADD_PRODUCT, data);
  return response?.data;
};

export const generateProductBarcode = async (data: object) => {
  const response = await catalogServices.post(GENERATE_PRODUCT_BARCODE, data);
  return response?.data;
};

export const listOfSizeRange = async (data: object) => {
  const response = await catalogColorAndSizeServices.post(
    LIST_SIZE_RANGE,
    data
  );
  return response?.data;
};

export const sizeRangeData = async (id: number | string) => {
  const response = await catalogColorAndSizeServices.get(`${SIZE_RANGE}/${id}`);
  return response?.data;
};
