import { PDFViewer } from '@react-pdf/renderer';
import ShippingLabelPDF from 'pages/Orders/ShippingLabel';
import { useState } from 'react';
import { Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { notFound, addressLabels } from 'utils/helpers/constant';
import { Tooltip as ReactTooltip } from "react-tooltip";

interface AddressProps {
    title: string,
    customerInfo: {
        customer_name: string | undefined,
        customer_contact_no: string | undefined
    },
    addressLine1: string | undefined,
    addressLine2: string | undefined,
    city: string | undefined,
    state: { id: number, province_name: string } | undefined,
    zipCode: string | undefined
}

const AddressCard = ({ title, customerInfo, addressLine1, addressLine2, city, state, zipCode }: AddressProps) => {
    const [billModal, setBillModal] = useState(false);

    const toggleBillModal = () => {
        setBillModal(!billModal);
    };

    const customerData = {
        title,
        customerInfo,
        addressLine1,
        addressLine2,
        state,
        zipCode,
        city
    }

    return (
        <><Card>
            <CardHeader>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5 className="card-title mb-0">
                        <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}
                        {title}
                    </h5>
                    {title === "Shipping Address" && (
                        <Button id='print-shipping' color="primary" onClick={toggleBillModal}><i className="ri-printer-fill"></i>
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content="Print"
                                anchorId={`print-shipping`}
                            /></Button>
                    )}
                </div>
            </CardHeader>
            <CardBody>
                <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                    <li className="fw-medium fs-14">{customerInfo?.customer_name ?? notFound?.notAvailable}</li>
                    <li>{customerInfo?.customer_contact_no ?? notFound?.notAvailable}</li>
                    <li>{addressLine1 ?? notFound?.notAvailable},{addressLine2 ?? notFound?.notAvailable} </li>
                    <li>{city}{city !== null && ","}{state?.province_name}, {zipCode}</li>
                </ul>
            </CardBody>
        </Card>

            <Modal
                isOpen={billModal}
                toggle={toggleBillModal}
                modalClassName="zoomIn"
                centered
            >
                <ModalHeader toggle={toggleBillModal} className="p-3 bg-light p-3">
                    {addressLabels?.viewAddress}
                </ModalHeader>
                <ModalBody className="text-center">
                    <PDFViewer width="222px" height="385px">
                        <ShippingLabelPDF customerData={customerData} />
                    </PDFViewer>
                </ModalBody>
            </Modal></>
    );
};

export default AddressCard;
