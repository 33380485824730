import {
  ADD_SALES_CUSTOMER,
  ADD_SALES_RETURN,
  DELETE_SCAN_PRODUCT,
  GENERATE_DOC_NO,
  LIST_OF_SALES_ORDER_CUSTOMER,
  LIST_OF_SALES_RETURN,
  LIST_OF_SCAN_PRODUCT,
  SCAN_BARCODE_RETURN,
  SALES_RETURN,
  DELETE_SALES_RETURN,
  SALES_ORDER_DISCOUNT,
  DELETE_SALES_RETURN_ITEM,
  SCAN_ITEM_APPLY_DISCOUNT,
} from "./apiRoutes";
import { nonOrderServices, orderServices } from "./apiServices";

export const listOfSalesReturn = async (data: object) => {
  const response = await orderServices.post(LIST_OF_SALES_RETURN, data);
  return response?.data;
};

export const listOfSalesOrderCustomer = async (data: object) => {
  const response = await orderServices.post(LIST_OF_SALES_ORDER_CUSTOMER, data);
  return response?.data;
};

export const addSalesOrderCustomer = async (data: object) => {
  const response = await orderServices.post(ADD_SALES_CUSTOMER, data);
  return response?.data;
};

export const generateDocNo = async () => {
  const response = await nonOrderServices.get(GENERATE_DOC_NO);
  return response?.data;
};

export const scanBarcode = async (data: object) => {
  const response = await orderServices.post(SCAN_BARCODE_RETURN, data);
  return response?.data;
};

export const deleteScanProduct = async (id: number) => {
  const response = await orderServices.delete(`${DELETE_SCAN_PRODUCT}/${id}`);
  return response?.data;
};

export const deleteSalesOrderReturnItem = async (id: number) => {
  const response = await orderServices.delete(
    `${DELETE_SALES_RETURN_ITEM}/${id}`
  );
  return response?.data;
};

export const deleteSalesReturn = async (id: number) => {
  const response = await orderServices.delete(`${DELETE_SALES_RETURN}/${id}`);
  return response?.data;
};

export const addSalesReturn = async (data: object) => {
  const response = await orderServices.post(ADD_SALES_RETURN, data);
  return response?.data;
};

export const editSalesReturn = async (id: number, data: object) => {
  const response = await orderServices.put(`${SALES_RETURN}/${id}`, data);
  return response?.data;
};

export const viewSalesReturn = async (editId: number) => {
  const response = await orderServices.get(`${ADD_SALES_RETURN}/${editId}`);
  return response?.data;
};

export const salesOrderDiscount = async (data: object) => {
  const response = await orderServices.post(SALES_ORDER_DISCOUNT, data);
  return response?.data;
};

export const scanItemApplyDiscount = async (data: object) => {
  const response = await orderServices.post(SCAN_ITEM_APPLY_DISCOUNT, data);
  return response?.data;
};
