import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link, useNavigate } from "react-router-dom";
import logoLight from "../../assets/images/logo/logo.jpg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { login } from "api/loginApi";
import { toast } from "react-toastify";
import { emailRegex, passwordRegex } from "utils/helpers/regexPatterns";
import { loginLabels, title, validationMessages } from "utils/helpers/constant";
import { ResponseStatusEnum } from "utils/helpers/enums";
import { JwtPayload, jwtDecode } from 'jwt-decode'
interface CustomJwtPayload extends JwtPayload {
  role: string;
}

const Login = () => {

  const history = useNavigate();

  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   start()
  //     .then()
  //     .catch(error => {
  //       return error;
  //     });
  // }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(validationMessages.format(loginLabels.email))
        .matches(emailRegex, validationMessages.format(loginLabels.email))
        .required(validationMessages.required(loginLabels.email)),
      password: Yup.string()
        .required(validationMessages.required(loginLabels.password)),
    }),
    onSubmit: (values) => {

      setIsLoading(true);
      login(values)
        .then((response) => {
          if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
            sessionStorage.setItem("authUser", response?.data?.token);
            sessionStorage.setItem("financialYear", response?.data?.financialYear)
            const decodedToken = jwtDecode<CustomJwtPayload>(response?.data?.token);
            const userRole = decodedToken.role;
            sessionStorage.setItem("role", userRole);
            history("/dashboard");
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          toast.error(title.somethingWrong);
        })
        .finally(() => setIsLoading(false));
    },

  });

  document.title = title.signIn;
  return (
    <ParticlesAuth>
      <div className="auth-page-content mt-lg-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="64" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{loginLabels.welcomeBack}</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          {loginLabels.email}
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={!!(validation.touched.email && validation.errors.email)}
                        />
                        {validation.touched.email &&
                          validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="password-input"
                        >
                          {loginLabels.password}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={passwordShow ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={!!(validation.touched.password && validation.errors.password)}
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted me-3"
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                          {validation.touched.password &&
                            validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-4">
                        <Button
                          color="success"
                          disabled={isLoading}
                          className="btn btn-success w-100"
                          type="submit"
                        >
                          {isLoading ? (
                            <Spinner size="sm" className="me-2" />
                          ) :
                            (title.signIn)
                          }
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  )
}

export default Login