import TableContainer from 'Components/Common/TableContainer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Container, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { staffList, deleteStaff, staffResetPassword } from 'api/staffApi';
import { ResponseStatusEnum } from 'utils/helpers/enums';
import { toast } from 'react-toastify';
import { categoryLabels, notFound, resetPasswordLabels, title, validationMessages } from 'utils/helpers/constant';
import DeleteModal from 'Components/Common/DeleteModal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { staffLabels } from 'utils/helpers/constant';
import { Link } from 'react-router-dom';
import LoaderBlur from 'Components/Common/BlurLoader';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { passwordRegex } from 'utils/helpers/regexPatterns';

const Staff = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [staffData, setStaffData] = useState([]);
    const [searchStaffData, setSearchStaffData] = useState([]);
    const [categoryDeleteModal, setCategoryDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState<number>();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [customPageSize, setCustomPageSize] = useState(5);
    const [passwordModal, setPasswordModal] = useState(false);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [confPasswordShow, setConfPasswordShow] = useState<boolean>(false);
    const [staffId, setSaffId] = useState<number>();
    const [columnName, setColumnName] = useState("id");
    const [sortOrder, setSortOrder] = useState("DESC");

    const handleFetchSorting = (page: number, id: string, order: string) => {
        setCurrentPage(page);
        setColumnName(id);
        setSortOrder(order);
    };

    const onClickPasswordModal = (id: number, values: any) => {
        setPasswordModal(false);
        const resetPasswordPayload = {
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword
        };
        staffResetPassword(id, resetPasswordPayload)
            .then((resp) => {
                if (resp?.statusCode && ResponseStatusEnum.SUCCESS.includes(resp?.statusCode)) {
                    toast.success(resp.message)
                } else {
                    toast.error(resp.message)
                }
            });
    };

    const fetchStaffData = () => {
        setIsLoading(true);
        staffList({
            sortOrder:  { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize
        }).then((response) => {
            setStaffData(response?.data?.listOfStaffData);
            setTotalRecords(response?.data?.totalRecordsCount)
            setTotalPages(response?.data?.totalPages)
            setTotalNumberOfRows(response?.data?.numberOfRows)
            if (searchValue) {
                fetchSearchData();
            }
        })
            .catch((err) => err)
            .finally(() => {
                setIsLoading(false);
            });
    }

    const fetchSearchData = () => {
        setIsLoading(true);
        staffList({
            sortOrder:  { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize,
            searchBar: searchValue
        }).then((response) => {
            setSearchStaffData(response?.data?.listOfStaffData);
            setTotalRecords(response?.data?.totalRecordsCount)
            setTotalPages(response?.data?.totalPages)
            setTotalNumberOfRows(response?.data?.numberOfRows)
        })
            .catch((err) => {
                setSearchStaffData([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleFetchData = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearchValueChange = (value: string) => {
        if (value !== searchValue) {
            setSearchValue(value);
            setCurrentPage(1);
        }
    };
    useEffect(() => {
        if (searchValue) {
            fetchSearchData();
        } else {
            fetchStaffData();
        }
    }, [currentPage, searchValue, customPageSize, columnName, sortOrder])

    useEffect(() => {
        if (searchValue === '') {
            setCurrentPage(1);
        }
    }, [searchValue]);

    const onClickDelete = (id: number) => {
        setSelectedId(id)
        setCategoryDeleteModal(true);
    };

    const handleDeleteCategory = () => {
        if (selectedId !== undefined) {
            setIsLoading(true);
            setCategoryDeleteModal(false);
            deleteStaff(selectedId)
                .then((response) => {
                    if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        toast.success(response.message);
                        fetchStaffData();
                    } else {
                        toast.error(response.message);
                    }
                }).catch((err) => {
                    toast.error(title.somethingWrong);
                }).finally(() => {
                    setIsLoading(false);
                    setCategoryDeleteModal(false);
                });
        } else {
            console.error("selectedId is undefined");
        }
    };


    const CategoryColumns = useMemo(
        () =>
            [{
                header: staffLabels.name,
                accessorKey: `${staffLabels.accessKeyName}`,
                enableColumnFilter: false,
            },
            {
                header: staffLabels.email,
                accessorKey: `${staffLabels.accessKeyEmail}`,
                enableColumnFilter: false,
            },
            {
                header: staffLabels.contact,
                accessorKey: `${staffLabels.accessKeyContact}`,
                enableColumnFilter: false,
            },
            {
                header: staffLabels.address,
                accessorKey: `${staffLabels.addressKeyAddress}`,
                enableColumnFilter: false,
            },
            {
                header: categoryLabels.actions,
                enableSorting: false,
                cell: (cell: { row: { original: { id: number } } }) => (
                    <div className="hstack gap-2">
                        <Link to={`/staff/editStaff/${cell?.row?.original?.id}`} >
                            <button
                                id={`editMode-${cell?.row?.original?.id}`}
                                className="btn btn-sm btn-soft-info edit-list"
                            >
                                <i className="ri-pencil-fill align-bottom" />
                                <ReactTooltip
                                    place="bottom"
                                    variant="info"
                                    content="Edit"
                                    anchorId={`editMode-${cell?.row?.original?.id}`}
                                />
                            </button>
                        </Link>
                        <button
                            id={`delete-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-danger remove-list"
                            onClick={() => { onClickDelete(cell?.row?.original?.id) }}
                        >
                            <i className="ri-delete-bin-5-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="error"
                                content="Delete"
                                anchorId={`delete-${cell?.row?.original?.id}`}
                            />
                        </button>
                        <button
                            id={`reset-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-success remove-list"
                            onClick={() => {
                                setSaffId(cell?.row?.original?.id);
                                setPasswordModal(true);
                            }}
                        >
                            <i className="ri-key-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="success"
                                content="Reset Password"
                                anchorId={`reset-${cell?.row?.original?.id}`}
                            />
                        </button>
                    </div>
                ),
            },
            ],
        []
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .required(validationMessages.required(resetPasswordLabels.password))
                .matches(
                    passwordRegex,
                    validationMessages.passwordComplexity(resetPasswordLabels.password)
                ),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), undefined], validationMessages.passwordsMatch(resetPasswordLabels.password))
                .required(validationMessages.required(resetPasswordLabels.confirmPassword)),
        }),
        onSubmit: (values) => {
            if (staffId) {
                onClickPasswordModal(staffId, values);
                validation.resetForm();
            }
        },
    });

    const toggle = useCallback(() => {
        if (passwordModal) {
            validation.resetForm();
        }
        setPasswordModal(!passwordModal);
    }, [passwordModal, validation]);

    document.title = staffLabels.title;

    return (
        <div className='page-content'>
            <DeleteModal
                show={categoryDeleteModal}
                onDeleteClick={handleDeleteCategory}
                onCloseClick={() => setCategoryDeleteModal(false)}
            />
            <Container>
                <Row className="mb-3">
                    <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                        <h5 className="fw-semibold mb-0">{staffLabels.title}</h5>
                    </div>
                    <div className="col-auto order-2 order-sm-3 ms-auto">
                        <div className="hstack gap-2">
                            <Link to={"/staff/addStaff"} className="btn btn-primary createTask" >+ {staffLabels.addStaff}</Link>
                        </div>
                    </div>
                </Row>
                <Card>
                    <CardBody>
                        {isLoading && <LoaderBlur />}
                        {staffData && staffData?.length > 0 ? (
                            <TableContainer
                                columns={CategoryColumns}
                                data={searchValue ? searchStaffData : staffData || []}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                totalRecords={totalRecords}
                                totalNumberOfRows={totalNumberOfRows}
                                fetchData={handleFetchData}
                                onSearch={handleSearchValueChange}
                                fetchSortingData={handleFetchSorting}
                                isGlobalFilter={true}
                                customPageSize={customPageSize}
                                setCustomPageSize={setCustomPageSize}
                                divClass="table-responsive mb-1"
                                tableClass="mb-0 align-middle table-border table-wrap"
                                theadClass="table-light text-muted text-wrap"
                                SearchPlaceholder="Search Staff"
                                manualPagination={true}
                                manualFiltering={true}
                                manualSorting={true}
                            />
                        ) : (
                            <div className="py-4 text-center">
                                <div>
                                    <i className="ri-search-line display-5 text-success"></i>
                                </div>

                                <div className="mt-4">
                                    <h5>{notFound.loadingContent}</h5>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>

                <Modal isOpen={passwordModal}
                    toggle={toggle}
                    modalClassName="zoomIn"
                    centered>
                    <ModalHeader toggle={toggle} className="p-3 bg-light p-3">
                            {resetPasswordLabels.resetPassword}
                    </ModalHeader>
                    <ModalBody>
                        <div className="p-2 ">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="mb-3">
                                    <Label
                                        className="form-label"
                                        htmlFor="password-input"
                                    >
                                        {resetPasswordLabels.newPassword}
                                    </Label>
                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                        <Input
                                            name="newPassword"
                                            value={validation.values.newPassword || ""}
                                            type={passwordShow ? "text" : "password"}
                                            className="form-control pe-5"
                                            placeholder="Enter New Password"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={!!(validation.touched.newPassword && validation.errors.newPassword)}
                                        />
                                        <button
                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted me-3"
                                            type="button"
                                            id="password-addon"
                                            onClick={() => setPasswordShow(!passwordShow)}
                                        >
                                            <i className="ri-eye-fill align-middle"></i>
                                        </button>
                                        {validation.touched.newPassword &&
                                            validation.errors.newPassword ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.newPassword}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Label
                                        className="form-label"
                                        htmlFor="password-input"
                                    >
                                        {resetPasswordLabels.confirmPassword}
                                    </Label>
                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                        <Input
                                            name="confirmPassword"
                                            value={validation.values.confirmPassword || ""}
                                            type={confPasswordShow ? "text" : "password"}
                                            className="form-control pe-5"
                                            placeholder="Enter Confirm Password"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={!!(validation.touched.confirmPassword && validation.errors.confirmPassword)}
                                        />
                                        <button
                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted me-3"
                                            type="button"
                                            id="password-addon"
                                            onClick={() => setConfPasswordShow(!confPasswordShow)}
                                        >
                                            <i className="ri-eye-fill align-middle  "></i>
                                        </button>
                                        {validation.touched.confirmPassword &&
                                            validation.errors.confirmPassword ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.confirmPassword}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <Button
                                        color="success"
                                        disabled={isLoading}
                                        className="btn btn-success w-100"
                                        type="submit"
                                    >
                                        {isLoading ? (
                                            <Spinner size="sm" className="me-2" />
                                        ) :
                                            (resetPasswordLabels.submit)
                                        }
                                    </Button>
                                </div>
                            </form>
                        </div>

                    </ModalBody>
                </Modal>
            </Container>
        </div>
    )
}

export default Staff
