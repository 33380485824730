
import Report from "./combinationOfReport";

const Customer = () => {
  document.title = "Customer Report"
  return (
    <Report reportType="customer"/>
  );
};

export default Customer;
