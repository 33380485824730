import TableContainer from 'Components/Common/TableContainer'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { PlaceHolderFormat, RequiredField } from 'utils/helpers/validationRequiredMessages';
import { addSubCategory, deleteSubCategory, editSubCategory, listOfSubCategory } from 'api/subCategory';
import dummyImg from '../../assets/images/users/user-dummy-img.jpg'
import Select from 'react-select';
import { IMAGE_URL } from 'api/apiServices';
import { toast } from 'react-toastify';
import { categoryLabels, notFound, subCategoryLabels, title } from 'utils/helpers/constant';
import DeleteModal from 'Components/Common/DeleteModal';
import { ResponseStatusEnum } from 'utils/helpers/enums';
import { fileUploads } from 'api/categoryApi';
import { Tooltip as ReactTooltip } from "react-tooltip";
import LoaderBlur from 'Components/Common/BlurLoader';
import { listOfCategoryOptions } from 'api/dropDownApi';
import imageCompression from 'browser-image-compression';

type OptionType = {
    label: string;
    value: string;
};
interface CategoryOption {
    find(arg0: (option: OptionType) => boolean): unknown;
    value: string;
    label: string;
}
interface EditSubCategoryData {
    id: number,
    categoryId: number,
    subCategory_name: string,
    images: string,
    description: string
}
interface CategoryOptions {
    category_description: string,
    category_image: string,
    category_name: string,
    id: number
}

const SubCategory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [listOfSubCategoryData, setListOfSubCategoryData] = useState([]);
    const [searchSubCategoryData, setSearchSubCategoryData] = useState([]);
    const [listOfCategoryDataOptions, setListOfCategoryDataOptions] = useState<CategoryOption[]>([]);
    const [selectedImage, setSelectedImage] = useState<string>();
    const [categoryDeleteModal, setCategoryDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState<number>();
    const [editSubCategoryData, setEditSubCategoryData] = useState<EditSubCategoryData>();
    const [subCategoryModal, setSubCategoryModal] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [customPageSize, setCustomPageSize] = useState(5);
    const [columnName, setColumnName] = useState("id");
    const [sortOrder, setSortOrder] = useState("DESC");

    const handleFetchSorting = (page: number, id: string, order: string) => {
        setCurrentPage(page);
        setColumnName(id);
        setSortOrder(order);
    };

    const toggle = useCallback(() => {
        if (subCategoryModal) {
            setSubCategoryModal(false);
            setIsEdit(false);
            setSelectedImage('');
            validation.resetForm();
            setEditSubCategoryData(undefined);
        } else {
            setSubCategoryModal(true);
        }
    }, [subCategoryModal]);

    const handleEditMode = (cell: EditSubCategoryData) => {
        setIsEdit(true);
        setEditSubCategoryData(cell)
        setSubCategoryModal(true);
    }

    const onClickDelete = (id: number) => {
        setSelectedId(id)
        setCategoryDeleteModal(true);
    };

    const handleDeleteCategory = () => {
        setIsLoading(true);
        deleteSubCategory({ subCategory_id: selectedId }).then((response) => {
            if (response.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                subCategoryFetchData();
                setCategoryDeleteModal(false);
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(title.somethingWrong);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handleEditSubCategory = (subCategoryId: number, value: object) => {
        editSubCategory(subCategoryId, value).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                subCategoryFetchData();
                setSubCategoryModal(false);
                setSelectedImage('');
                setIsEdit(false);
                setEditSubCategoryData(undefined);
                validation.resetForm();
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(title.somethingWrong);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleAddSubCategory = (value: object) => {
        addSubCategory(value).then((response) => {
            if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                toast.success(response.message);
                subCategoryFetchData();
                validation.resetForm();
                setSelectedImage('');
                setSubCategoryModal(false);
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            toast.error(title.somethingWrong);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const fetchData = () => {
        setIsLoading(true);
        listOfCategoryOptions()
            .then(response => {
                setListOfCategoryDataOptions(response?.data?.map((category: CategoryOptions) => ({
                    value: category?.id,
                    label: category?.category_name
                })));
            })
            .catch((error) => {
                return error
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const subCategoryFetchData = () => {
        setIsLoading(true);
        const payload = {
            sortOrder: { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize,
        };

        listOfSubCategory(payload).then((response) => {
            setTotalRecords(response?.data?.totalRecordsCount)
            setTotalPages(response?.data?.totalPages)
            setTotalNumberOfRows(response?.data?.numberOfRows)
            setListOfSubCategoryData(response?.data?.listOfSubCategoryData)
            if (searchValue) {
                fetchSearchData();
            }
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const fetchSearchData = () => {
        setIsLoading(true);
        const payload = {
            sortOrder: { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize,
            searchBar: searchValue
        };

        listOfSubCategory(payload).then((response) => {
            setTotalRecords(response?.data?.totalRecordsCount)
            setTotalPages(response?.data?.totalPages)
            setTotalNumberOfRows(response?.data?.numberOfRows)
            setSearchSubCategoryData(response?.data?.listOfSubCategoryData)
        }).catch((err) => {
            setSearchSubCategoryData([]);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleReadMore = (cell: any) => {
        setIsEdit(true);
        setEditSubCategoryData(cell)
        setSubCategoryModal(true);
    }

    const handleFetchData = (page: number) => {
        setCurrentPage(page); // Update the current page
    };

    const handleSearchValueChange = (value: string) => {
        if (value !== searchValue) {
            setSearchValue(value);
            setCurrentPage(1);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        if (searchValue) {
            fetchSearchData();
        } else {
            subCategoryFetchData();
        }
    }, [currentPage, searchValue, customPageSize, sortOrder, columnName])

    useEffect(() => {
        if (searchValue === '') {
            setCurrentPage(1);
        }
    }, [searchValue]);

    const SubCategoryColumns = useMemo(
        () => [
            {
                header: `${categoryLabels.categoryName}`,
                accessorKey: `${subCategoryLabels.categoryNameKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${subCategoryLabels.subCategoryNameOfKey}`,
                accessorKey: `${subCategoryLabels.subCategoryAccessKey}`,
                enableColumnFilter: false,
            },
            {
                header: categoryLabels.description,
                accessorKey: `${categoryLabels.descriptionAccessKey}`,
                enableColumnFilter: false,
                cell: (cell: any) => (
                    <div>
                        {cell?.row?.original?.description?.length > 50 ? (
                            <>
                                {cell?.row?.original?.description?.substring(0, 50)}
                                <span
                                    className="text-primary read-more"
                                    onClick={() => handleReadMore(cell?.row?.original)}
                                >
                                    Read More {">>"}
                                </span>
                            </>
                        ) : (
                            cell?.row?.original?.description
                        )}
                    </div>
                ),
            },
            {
                header: `${categoryLabels.image}`,
                accessorKey: `${categoryLabels.imageAccessKey}`,
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cell: { row: { original: { images: string } } }) => (
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                            <div className="imageDivWrap avatar-sm bg-light rounded p-1">
                                <img
                                    src={`${IMAGE_URL}/${cell.row.original.images}`}
                                    alt="sub-category image"
                                    className="img-fluid d-block imageWrap"
                                    onError={(e: any) => {
                                        e.target.onerror = null;
                                        e.target.src = dummyImg;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                header: `${categoryLabels.actions}`,
                enableSorting: false,
                cell: (cell: { row: { original: EditSubCategoryData } }) => (
                    <div className="hstack gap-2">
                        <button
                            id={`editMode-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => { handleEditMode(cell?.row?.original) }}
                        >
                            <i className="ri-pencil-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content="Edit"
                                anchorId={`editMode-${cell?.row?.original?.id}`}
                            />
                        </button>
                        <button
                            id={`delete-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-danger remove-list"
                            onClick={() => { onClickDelete(cell?.row?.original?.id) }}
                        >
                            <i className="ri-delete-bin-5-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="error"
                                content="Delete"
                                anchorId={`delete-${cell?.row?.original?.id}`}
                            />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            category_id: editSubCategoryData?.categoryId || '',
            name: editSubCategoryData?.subCategory_name || '',
            description: editSubCategoryData?.description || '',
            image: editSubCategoryData?.images || ''
        },
        validationSchema: Yup.object().shape({
            category_id: Yup.string().required(RequiredField(categoryLabels.category)),
            name: Yup.string().required(RequiredField(subCategoryLabels.subCategory)),
            description: Yup.string().required(RequiredField(categoryLabels.description)),
            image: Yup.string().required(RequiredField(`${subCategoryLabels.subCategory}`)),
        }),
        onSubmit: (value) => {
            setIsLoading(true);
            setSubCategoryModal(false);
            if (editSubCategoryData) {
                handleEditSubCategory(editSubCategoryData?.id, value);
            } else {
                handleAddSubCategory(value);
            }
            validation.resetForm();
        }
    });

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const fileSizeLimit = 1000000;
        if (file.size > fileSizeLimit) {
            toast.error('File size exceeds the limit (1MB)');
            return;
        }

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
        };

        try {
            const compressedFile = await imageCompression(file, options);

            // Preserve the original filename
            const newFile = new File([compressedFile], file.name, {
                type: compressedFile.type,
                lastModified: compressedFile.lastModified,
            });

            const formData = new FormData();
            formData.append('files', newFile);

            setIsImageUploading(true);

            const response = await fileUploads(formData);
            const files = response?.data?.[0];

            validation.setFieldValue('image', files);
            setSelectedImage(files);
        } catch (error) {
            toast.error('Image compression failed');
        } finally {
            setIsImageUploading(false);
        }
    };

    document.title = subCategoryLabels.subCategory;

    return (
        <>
            <div className='page-content'>
                <DeleteModal
                    show={categoryDeleteModal}
                    onDeleteClick={handleDeleteCategory}
                    onCloseClick={() => setCategoryDeleteModal(false)} />
                <Container>
                    <Row className="mb-3">
                        <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                            <h5 className="fw-semibold mb-0">{subCategoryLabels.subCategory}</h5>
                        </div>
                        <div className="col-auto order-2 order-sm-3 ms-auto">
                            <div className="hstack gap-2">
                                <button className='btn btn-primary' onClick={() => { setSubCategoryModal(true); }}>
                                    + {categoryLabels.addCategory}
                                </button>
                            </div>
                        </div>
                    </Row>
                    <Card>
                        <CardBody>
                            {isLoading && <LoaderBlur />}
                            {listOfSubCategoryData && listOfSubCategoryData?.length > 0 ? (
                                <TableContainer
                                    columns={SubCategoryColumns}
                                    data={searchValue ? searchSubCategoryData : listOfSubCategoryData || []}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalPages={totalPages}
                                    totalRecords={totalRecords}
                                    totalNumberOfRows={totalNumberOfRows}
                                    isGlobalFilter={true}
                                    fetchData={handleFetchData}
                                    fetchSortingData={handleFetchSorting}
                                    customPageSize={customPageSize}
                                    setCustomPageSize={setCustomPageSize}
                                    onSearch={handleSearchValueChange}
                                    divClass="table-responsive mb-1"
                                    tableClass="mb-0 align-middle table-border"
                                    theadClass="table-light text-muted table-wrap"
                                    SearchPlaceholder="Search Sub-Category"
                                    manualPagination={true}
                                    manualFiltering={true}
                                    manualSorting={true}
                                />
                            ) : (
                                <div className="py-4 text-center">
                                    <div>
                                        <i className="ri-search-line display-5 text-success"></i>
                                    </div>
                                    <div className="mt-4">
                                        <h5>{notFound.loadingContent}</h5>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal
                isOpen={subCategoryModal}
                toggle={toggle}
                modalClassName="zoomIn"
                centered
            >
                <Form onSubmit={validation.handleSubmit}>
                    <ModalHeader toggle={toggle} className="p-3 bg-light p-3">
                        {isEdit ? categoryLabels.update : categoryLabels.addCategory} {subCategoryLabels.subCategory}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <div className="text-center">
                                <div className="position-relative d-inline-block">
                                    <div className="position-absolute  bottom-0 end-0">
                                        <Label htmlFor="customer-image-input" className="mb-0">
                                            <div className="avatar-xs cursor-pointer">
                                                <div className="avatar-title bg-light border rounded-circle text-muted">
                                                    {(editSubCategoryData?.images || selectedImage) ? (
                                                        <i className="ri-pencil-fill"></i>
                                                    ) : (
                                                        <i className="ri-camera-fill"></i>
                                                    )}
                                                </div>
                                            </div>
                                        </Label>
                                        <Input className="form-control d-none" id="customer-image-input" type="file"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={handleImageChange}
                                            invalid={!!(validation.touched.image && validation.errors.image)} />
                                    </div>
                                    {isImageUploading ? (
                                        <Spinner className='me-2' size="sm" />
                                    ) : (
                                        <div className="avatar-lg p-1">
                                            <div className="avatar-title bg-light rounded-circle">
                                                <img
                                                    src={selectedImage ? `${IMAGE_URL}/${selectedImage}` : `${IMAGE_URL}/${editSubCategoryData?.images || dummyImg}`}
                                                    alt="categoryImage"
                                                    id="category-image"
                                                    className="avatar-md rounded-circle object-fit-cover"
                                                    onError={(e: any) => {
                                                        e.target.onerror = null;
                                                        e.target.src = dummyImg;
                                                    }} />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {validation.errors.image && validation.touched.image ? (
                                    <FormFeedback type="invalid" className='d-block'> {validation.errors.image} </FormFeedback>
                                ) : null}
                            </div>
                            <div>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label>{subCategoryLabels.select} {categoryLabels.category}</Label>
                                        <Select
                                            className={`${validation.touched.category_id && validation.errors.category_id && "is-invalid"}`}
                                            value={listOfCategoryDataOptions?.find(option => option.value === validation.values.category_id)}
                                            onChange={(selectedOption: OptionType) => validation.setFieldValue('category_id', selectedOption?.value || '')}
                                            options={listOfCategoryDataOptions} />
                                        {validation.touched.category_id && validation.errors.category_id && (
                                            <div className="error-message-drop">{validation.errors.category_id}</div>
                                        )}
                                    </div>
                                </Col>
                            </div>
                            <div className='p-2'>
                                <Col lg={12}>
                                    <Label>{subCategoryLabels.subCategory}</Label>
                                    <Input
                                        type="text"
                                        title="Name"
                                        name={categoryLabels.categoryAccessKey}
                                        maxLength={30}
                                        placeholder={PlaceHolderFormat(subCategoryLabels.subCategory)}
                                        value={validation.values.name}
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={!!(validation.touched.name &&
                                            validation.errors.name)}
                                    ></Input>
                                    {validation.touched.name &&
                                        validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.name}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </div>
                            <div className='p-2'>
                                <Col lg={12}>
                                    <Label>{categoryLabels.description}</Label>
                                    <Input
                                        type="textarea"
                                        title={categoryLabels.description}
                                        name={categoryLabels.descriptionAccessKey}
                                        placeholder={PlaceHolderFormat(categoryLabels.description)}
                                        value={validation.values.description}
                                        onBlur={validation.handleBlur}
                                        onChange={validation.handleChange}
                                        invalid={!!(validation.touched.description &&
                                            validation.errors.description)}
                                    ></Input>
                                    {validation.touched.description &&
                                        validation.errors.description ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.description}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                            </div>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="none"
                            className="btn btn-ghost-success"
                            onClick={toggle}
                        >
                            {categoryLabels.cancel}
                        </Button>
                        <Button type="submit" color="primary">
                            {isLoading ? (
                                <Spinner size="sm" className="me-2" />
                            ) :
                                isEdit ? categoryLabels.update : categoryLabels.submit
                            }
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export default SubCategory;
