import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { billLabels } from "utils/helpers/constant";

interface ViewOrdersData {
    savedAmount: number;
    cash_amount: number;
    card_amount: number;
    bill_no: number;
    gst_amount: number;
    taxable_amount: number;
    total_amount: number;
    total_quantity: number;
    order_number: number;
    order_date: string | number | Date;
    order_items: any;
}

interface BillPDFProps {
    viewOrdersData: ViewOrdersData | any;
}

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: 10,
        height: "3000px",
        width: "80px",
    },
    header: {
        marginBottom: 10,
    },
    title: {
        fontSize: 15,
        marginBottom: 5,
        fontWeight: "ultrabold",
        textAlign: "center",
    },
    address: {
        fontSize: 8,
        marginBottom: 5,
        textAlign: "center",
    },
    invoice: {
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: 12,
        marginBottom: 5,
        textAlign: "center",
    },
    tableHeader: {
        flexDirection: "row",
        borderBottomWidth: 1,
        alignItems: "center",
        marginBottom: 5,
        fontSize: 7,
    },
    tableRow: {
        flexDirection: "row",
        borderBottomWidth: 1,
        alignItems: "center",
        marginTop: 5,
        fontSize: 7,
    },
    tableCell: {
        width: "20%",
        textAlign: "center",
        fontSize: 7,
        wordWrap: "break-word",
    },
    tableColumn: {
        width: "20%",
        textAlign: "center",
        fontSize: 7,
    },
    totalRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 5,
        fontSize: 7,
    },
    noAndDate: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 8,
        marginBottom: 8,
        fontSize: 7,
        borderBottomWidth: 1,
        borderBottomColor: "black",
        paddingBottom: 5
    },
    footer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 5,
        fontSize: 7,
    },
    payBack: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 5,
        marginBottom: 8,
        fontSize: 7,
        borderBottomWidth: 1,
        borderBottomColor: "black",
        paddingBottom: 5,
    },
    forTryMe: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 5,
        fontSize: 7,
        fontWeight: "bold",
    },
    terms: {
        fontSize: 7,
        marginTop: 5,
    },
    saved: {
        textAlign: "center",
        backgroundColor: "black",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 5,
        fontSize: 7,
        color: "white",
    },
    gstTable: {
        flexDirection: 'column',
        marginTop: 10,
        marginBottom: 10,
        borderWidth: 1,
        borderColor: 'black',
        fontSize: 7,
    },
    gstTableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: 'black',
        fontSize: 7,
    },
    gstTableHeader: {
        width: '25%',
        textAlign: 'center',
        borderRightWidth: 1,
        borderColor: 'black',
        padding: 5,
        fontSize: 7,
    },
    gstTableCell: {
        width: '25%',
        textAlign: 'center',
        borderRightWidth: 1,
        borderColor: 'black',
        padding: 5,
        fontSize: 7,
        fontWeight: 700
    },
    totalEndRow: {
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: 5,
        fontSize: 7,
    }
});

const BillPDF: React.FC<BillPDFProps> = ({ viewOrdersData }) => {
    const paymentType = viewOrdersData.card_amount ? "Card" : "Cash";
    const paymentAmount = viewOrdersData.card_amount || viewOrdersData.cash_amount || 0;
    return (
        <Document>
            <Page style={{ maxWidth: "200px", height: "3000px", padding: "7px", marginTop: '10px' }}>
                <View style={styles.header}>
                    <Text style={styles.title}>{billLabels?.billTitle}</Text>
                    <Text style={styles.address}>
                        {billLabels?.address}
                    </Text>
                </View>
                <Text style={styles.invoice}>{billLabels?.taxInvoice}</Text>
                <View style={styles.noAndDate}>
                    <Text>{billLabels?.no} {viewOrdersData.bill_no}</Text>
                    <Text>{billLabels?.date} {new Date(viewOrdersData?.order_date).toLocaleDateString('en-GB')}</Text>
                </View>
                <View style={styles.tableHeader}>
                    <Text style={[styles.tableColumn, { width: '5px' }]}>Sr.</Text>
                    <Text style={[styles.tableColumn, { width: '70px' }]}>
                        <>{billLabels?.code}</>
                        {'\n'}
                        <>{billLabels?.desc}</>
                    </Text>
                    <Text style={[styles.tableColumn, { width: '20px' }]}>
                        <>{billLabels?.mrp}</>
                        {'\n'}
                        <>{billLabels?.hsn}</>
                    </Text>
                    <Text style={[styles.tableColumn, { width: '28px' }]}>
                        <>{billLabels?.qty}</>
                        {'\n'}
                        <>{billLabels?.disc}</>
                    </Text>
                    <Text style={[styles.tableColumn, { width: '32px' }]}>

                        <>{billLabels?.rate}</>
                        {'\n'}
                        <>{billLabels?.gst}</>
                    </Text>
                    <Text style={[styles.tableColumn, { width: '30px' }]}>{billLabels?.amount}</Text>
                </View>
                {viewOrdersData?.order_items?.map((item: any, index: number) => (
                    <View key={item.name} style={styles.tableRow}>
                        <Text style={[styles.tableColumn, { width: '5px' }]}>{index + 1}</Text>
                        <Text style={[styles.tableCell, { width: '70px' }]}>
                            <>{item.barcode}</>
                            {'\n'}
                            <>{item.product_name}</>
                        </Text>
                        <Text style={[styles.tableColumn, { width: '20px' }]}>
                            <>{item.price}</>
                            {'\n'}
                            <>{item.product_id.hsn_code_id.code}</>
                        </Text>
                        <Text style={[styles.tableColumn, { width: '28px' }]}>
                            <>{item.quantity}</>
                            {'\n'}
                            <>{(item?.discounted_amount === null ? viewOrdersData?.discountable_amount : item?.discounted_amount) ?? 0}</>
                        </Text>
                        <Text style={[styles.tableColumn, { width: '32px' }]}>
                            <>{viewOrdersData?.bill_no === null ?
                                item?.price :
                                item?.discounted_amount === null ? viewOrdersData?.net_payable : (item?.discounted_sale_rate === null ? item?.totalAmount : item?.discounted_sale_rate)
                            }
                            </>
                            {'\n'}
                            <>{billLabels?.tenPercent}</>
                        </Text>
                        <Text style={[styles.tableColumn, { width: '30px' }]}>
                            {viewOrdersData?.bill_no === null ?
                                item?.totalAmount :
                                item?.discounted_amount === null ? viewOrdersData?.net_payable : (item?.discounted_sale_rate === null ? item?.totalAmount : item?.discounted_sale_rate)
                            }
                        </Text>
                    </View>
                ))}
                {viewOrdersData?.net_payable === null &&
                    <>
                        <View style={styles.totalEndRow}>
                            <Text>{billLabels?.subTotal}: {viewOrdersData?.subTotal}</Text>
                        </View><View style={styles.totalEndRow}>
                            <Text>
                                {billLabels?.shippingCharge}: {viewOrdersData?.shipping_charge ?? 0}
                            </Text>
                        </View>
                    </>
                }
                <hr />
                <View style={styles.totalRow}>
                    <Text>{billLabels?.quantity} {viewOrdersData.total_quantity}</Text>
                    <Text>
                        {billLabels?.netAmount} {viewOrdersData?.net_payable === null ? viewOrdersData?.total_amount : viewOrdersData?.net_payable}
                    </Text>
                </View>
                <Text style={styles.footer}>{paymentType} = {paymentAmount}</Text>
                <View style={styles.saved}>
                    <Text style={{ backgroundColor: "black", color: "white", padding: 5 }}>
                        {billLabels?.youHaveSaved} $. {viewOrdersData.savedAmount}
                    </Text>
                </View>
                <View style={styles.gstTable}>
                    <View style={styles.gstTableRow}>
                        <Text style={styles.gstTableHeader}>{billLabels?.gstRate}</Text>
                        <Text style={[styles.gstTableCell, { borderLeftWidth: 0, width: '92px' }]}>10.00</Text>
                    </View>
                    <View style={styles.gstTableRow}>
                        <Text style={styles.gstTableHeader}>{billLabels?.taxable}</Text>
                        <Text style={[styles.gstTableCell, { borderLeftWidth: 0, width: '92px' }]}>{viewOrdersData?.taxable_amount}</Text>
                    </View>
                    <View style={styles.gstTableRow}>
                        <Text style={styles.gstTableHeader}>{billLabels?.cgst}</Text>
                        <Text style={[styles.gstTableCell, { borderLeftWidth: 0 }]}>10</Text>
                        <Text style={[styles.gstTableCell, { borderLeftWidth: 0 }]}>{viewOrdersData?.gst_amount}</Text>
                        <Text style={[styles.gstTableCell, { borderLeftWidth: 0, borderColor: 'white' }]}></Text>
                    </View>
                    <View style={styles.gstTableRow}>
                        <Text style={styles.gstTableHeader}>{billLabels?.sgst}</Text>
                        <Text style={[styles.gstTableCell, { borderLeftWidth: 0 }]}>0.00 %</Text>
                        <Text style={[styles.gstTableCell, { borderLeftWidth: 0 }]}>0.00</Text>
                        <Text style={[styles.gstTableCell, { borderLeftWidth: 0, borderColor: 'white' }]}></Text>
                    </View>
                </View>
                <View style={styles.forTryMe}>
                    <Text>{billLabels?.refundAmount}</Text>
                    <Text style={{ fontWeight: "bold" }}>0.00</Text>
                </View>
                <Text style={styles.footer}>{billLabels?.recAmt}  {viewOrdersData.net_payable === null ? viewOrdersData.total_amount : viewOrdersData?.net_payable}</Text>
                <Text style={styles.payBack}>{billLabels?.payBack} 0.00</Text>
                <View style={styles.forTryMe}>
                    <Text>{billLabels?.eoe}</Text>
                    <Text style={{ fontWeight: "bold" }}>{billLabels?.forTryMe}</Text>
                </View>
                <Text style={styles.terms}>{billLabels?.termsAndCondition}</Text>
                <Text style={styles.terms}>{billLabels?.noExchangeRefund}</Text>
                <Text style={styles.terms}>{billLabels?.thankyou}</Text>
            </Page>
        </Document>
    )
};

export default BillPDF;
