import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRole } from "utils/helpers/sessionStores";
import { RolesEnum } from "utils/helpers/enums";

const Navdata = () => {
  const history = useNavigate();
  const [role, setRole] = useState(getRole());
  //state data
  const [isDashboard, setIsDashboard] = useState<boolean>(false);
  const [isCategory, setIsCategory] = useState<boolean>(false);
  const [isSubCategory, setIsSubCategory] = useState<boolean>(false);
  const [isOrders, setIsOrders] = useState<boolean>(false);
  const [isProduct, setIsProduct] = useState<boolean>(false);
  const [isStockDetails, setIsStockDetails] = useState<boolean>(false);
  const [isHSN, setIsHSN] = useState<boolean>(false);
  const [isStaff, setIsStaff] = useState<boolean>(false);
  const [isSuppliers, setIsSuppliers] = useState<boolean>(false);
  const [isReport, setIsReport] = useState<boolean>(false);
  const [isSalesOrderReturn, setIsSalesOrderReturn] = useState<boolean>(false);
  const [isColorAndSize, setIsColorAndSize] = useState<boolean>(false);
  const [isCustomerList, setIsCustomerList] = useState<boolean>(false);
  const [isProductPurchase, setIsProductPurchase] = useState<boolean>(false);
  const [isSupport, setIsSupport] = useState<boolean>(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Category") {
      setIsCategory(false);
    }
    if (iscurrentState !== "Sub Category") {
      setIsSubCategory(false);
    }
    if (iscurrentState !== "Orders") {
      setIsOrders(false);
    }
    if (iscurrentState !== "Suppliers") {
      setIsSuppliers(false);
    }
    if (iscurrentState !== "HSN") {
      setIsHSN(false);
    }
    if (iscurrentState !== "Report") {
      setIsReport(false);
    }
    if (iscurrentState !== "Sales Order Return") {
      setIsSalesOrderReturn(false);
    }
    if (iscurrentState !== "Color And Size") {
      setIsColorAndSize(false);
    }
    if (isCustomerList) {
      setIsCustomerList(false);
    }
    if (iscurrentState !== "Stock Details") {
      setIsStockDetails(false);
    }
    if (iscurrentState !== "Support") {
      setIsSupport(false);
    }
  }, [history, iscurrentState, isDashboard, isCategory, isSubCategory, isHSN, isSalesOrderReturn, isColorAndSize, isCustomerList, isStockDetails, isSupport]);

  const menuItems: any = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "category",
      label: "Category",
      icon: "ri-layout-3-fill",
      link: "/category",
      stateVariables: isCategory,
      click: function (e: any) {
        e.preventDefault();
        setIsCategory(!isDashboard);
        setIscurrentState("Category");
        updateIconSidebar(e);
      },
    },
    {
      id: "subcategory",
      label: "Sub Category",
      icon: "ri-node-tree",
      link: "/subcategory",
      stateVariables: isSubCategory,
      click: function (e: any) {
        e.preventDefault();
        setIsSubCategory(!isSubCategory);
        setIscurrentState("Sub Category");
        updateIconSidebar(e);
      },
    },
    {
      id: "hsn",
      label: "HSN",
      icon: "ri-find-replace-line",
      link: "/hsn",
      stateVariables: isHSN,
      click: function (e: any) {
        e.preventDefault();
        setIsHSN(!isHSN);
        setIscurrentState("HSN");
        updateIconSidebar(e);
      },
    },
    {
      id: "orders",
      label: "Orders",
      icon: "ri-shopping-basket-2-line",
      link: "/orders",
      stateVariables: isOrders,
      click: function (e: any) {
        e.preventDefault();
        setIsOrders(!isOrders);
        setIscurrentState("Orders");
        updateIconSidebar(e);
      },
    },
    {
      id: "product",
      label: "Products",
      icon: "ri-shirt-fill",
      link: "/product",
      stateVariables: isProduct,
      click: function (e: any) {
        e.preventDefault();
        setIsProduct(!isProduct);
        setIscurrentState("Product");
        updateIconSidebar(e);
      },
    },
    {
      id: "stockDetails",
      label: "Stock Details",
      icon: "ri-funds-fill",
      link: "/stockDetails",
      stateVariables: isStockDetails,
      click: function (e: any) {
        e.preventDefault();
        setIsStockDetails(!isStockDetails);
        setIscurrentState("Stock Details");
        updateIconSidebar(e);
      },
    },
    {
      id: "supplier",
      label: "Supplier",
      icon: "ri-shake-hands-fill",
      link: "/supplier",
      stateVariables: isSuppliers,
      click: function (e: any) {
        e.preventDefault();
        setIsSuppliers(!isSuppliers);
        setIscurrentState("Supplier");
        updateIconSidebar(e);
      },
    },
    {
      id: "productPurchaseEntry",
      label: "Product Purchase",
      icon: "ri-hand-coin-fill",
      link: "/productPurchaseEntry",
      stateVariables: isProductPurchase,
      click: function (e: any) {
        e.preventDefault();
        setIsProductPurchase(!isProductPurchase);
        setIscurrentState('Product Purchase');
        updateIconSidebar(e);
      },
    },
    {
      id: "staff",
      label: "Staff",
      icon: "ri-team-line",
      link: "/staff",
      stateVariables: isStaff,
      click: function (e: any) {
        e.preventDefault();
        setIsStaff(!isStaff);
        setIscurrentState("Staff");
        updateIconSidebar(e);
      },
    },
    {
      id: "report",
      label: "Report",
      icon: "ri-pie-chart-line",
      stateVariables: isReport,
      click: function (e: any) {
        e.preventDefault();
        setIsReport(!isReport);
        setIscurrentState("Report");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "salesReport",
          label: "Sales",
          link: "/salesReport",
          parentId: "report",
        },
        {
          id: "customerReport",
          label: "Customer",
          link: "/customerReport",
          parentId: "report",
        },
        {
          id: "supplierReport",
          label: "Supplier",
          link: "/supplierReport",
          parentId: "report",
        },
        // I need for the future so please allow me
        // {
        //   id: "purchaseProductReport",
        //   label: "Purchase Product",
        //   link: "/purchaseProductReport",
        //   parentId: "report",
        // },
      ],
    },
    {
      id: "salesOrderReturn",
      label: "Sales Return Entry",
      icon: "ri-arrow-left-right-line",
      link: "/salesOrderReturn",
      stateVariables: isSalesOrderReturn,
      click: function (e: any) {
        e.preventDefault();
        setIsSalesOrderReturn(!isSalesOrderReturn);
        setIscurrentState('Sales Order Return');
        updateIconSidebar(e);
      },
    },
    {
      id: "colorAndSize",
      label: "Color And Size",
      icon: "ri-pencil-ruler-2-line",
      link: "/color-size",
      stateVariables: isColorAndSize,
      click: function (e: any) {
        e.preventDefault();
        setIsColorAndSize(!isColorAndSize);
        setIscurrentState("Color And Size");
        updateIconSidebar(e);
      },
    },
    {
      id: "customerList",
      label: "Customer List",
      icon: "ri-file-list-3-line",
      link: "/customerList",
      stateVariables: isColorAndSize,
      click: function (e: any) {
        e.preventDefault();
        setIsCustomerList(!isCustomerList);
        setIscurrentState("Customer List");
        updateIconSidebar(e);
      },
    },
    {
      id: "support",
      label: "Support",
      icon: "ri-customer-service-2-line",
      link: "/support",
      stateVariables: isSupport,
      click: function (e: any) {
        e.preventDefault();
        setIsSupport(!isSupport);
        setIscurrentState("Support");
        updateIconSidebar(e);
      },
    },
  ];
  const filteredMenuItems = role === RolesEnum.STAFF
    ? menuItems.filter((item: any) => item.id === "dashboard" || item.id === "orders" || item.id === "product")
    : menuItems;
  return <React.Fragment>{filteredMenuItems}</React.Fragment>;
};
export default Navdata;
