import {
  LIST_OF_CITY,
  LIST_OF_COLOR,
  LIST_OF_COUNTRY,
  LIST_OF_SIZE,
  LIST_OF_STATE,
} from "./apiRoutes";
import { addressServices, catalogServices } from "./apiServices";

export const listOfCountry = async (data: object) => {
  const response = await addressServices.post(LIST_OF_COUNTRY, data);
  return response?.data;
};

export const listOfState = async (data: object) => {
  const response = await addressServices.post(LIST_OF_STATE, data);
  return response?.data;
};

export const listOfCity = async (data: object) => {
  const response = await addressServices.post(LIST_OF_CITY, data);
  return response?.data;
};

export const listOfColor = async () => {
  const response = await catalogServices.get(LIST_OF_COLOR);
  return response?.data;
};

export const listOfSize = async () => {
  const response = await catalogServices.get(LIST_OF_SIZE);
  return response?.data;
};
