import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { passwordRegex } from "utils/helpers/regexPatterns";
import { loginLabels, resetPasswordLabels, title, validationMessages } from "utils/helpers/constant";
import { ResponseStatusEnum } from "utils/helpers/enums";
import { adminResetPasswordApi } from "api/loginApi";
import { Link, useNavigate } from "react-router-dom";

const ChangePassword = () => {

  const [currentPasswordShow, setCurrentPasswordShow] = useState<boolean>(false);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [confPasswordShow, setConfPasswordShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .required(validationMessages.required(resetPasswordLabels.currentPassword)),
      newPassword: Yup.string()
        .required(validationMessages.required(resetPasswordLabels.password))
        .matches(
          passwordRegex,
          validationMessages.passwordComplexity(resetPasswordLabels.password)
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), undefined], validationMessages.passwordsMatch(resetPasswordLabels.password))
        .required(validationMessages.required(resetPasswordLabels.confirmPassword)),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      let resetPasswordPayload = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword
      }
      adminResetPasswordApi(resetPasswordPayload)
        .then((response) => {
          if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
            toast.success(response.message);
            validation.resetForm();
            navigate('/dashboard');
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          toast.error(title.somethingWrong);
        })
        .finally(() => setIsLoading(false));
    },
  });

  document.title = resetPasswordLabels.changePassword;
  return (
    <div className='page-content'>
      <Container>
        <Row className="">
          <div className="col-auto order-2 order-sm-3 ms-auto">
            <div className="hstack gap-2">
              <Link to={"/dashboard"} className="btn btn-primary createTask" >Back</Link>
            </div>
          </div>
        </Row>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="mt-1">
              <CardBody className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">{resetPasswordLabels.changePassword}</h5>
                </div>
                <div className="p-2 mt-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="current-password-input"
                      >
                        {resetPasswordLabels.currentPassword}
                      </Label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                          name="currentPassword"
                          value={validation.values.currentPassword || ""}
                          type={currentPasswordShow ? "text" : "password"}
                          className="form-control pe-5"
                          placeholder="Enter Current Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={!!(validation.touched.currentPassword && validation.errors.currentPassword)}
                        />
                        <button
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted me-3"
                          type="button"
                          id="password-addon"
                          onClick={() => setCurrentPasswordShow(!currentPasswordShow)}
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </button>
                        {validation.touched.currentPassword &&
                          validation.errors.currentPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.currentPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="new-password-input"
                      >
                        {loginLabels.password}
                      </Label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                          name="newPassword"
                          value={validation.values.newPassword || ""}
                          type={passwordShow ? "text" : "password"}
                          className="form-control pe-5"
                          placeholder="Enter New Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={!!(validation.touched.newPassword && validation.errors.newPassword)}
                        />
                        <button
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted me-3"
                          type="button"
                          id="password-addon"
                          onClick={() => setPasswordShow(!passwordShow)}
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </button>
                        {validation.touched.newPassword &&
                          validation.errors.newPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.newPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="confirm-password-input"
                      >
                        {resetPasswordLabels.confirmPassword}
                      </Label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                          name="confirmPassword"
                          value={validation.values.confirmPassword || ""}
                          type={confPasswordShow ? "text" : "password"}
                          className="form-control pe-5"
                          placeholder="Enter Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={!!(validation.touched.confirmPassword && validation.errors.confirmPassword)}
                        />
                        <button
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted me-3"
                          type="button"
                          id="password-addon"
                          onClick={() => setConfPasswordShow(!confPasswordShow)}
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </button>
                        {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>

                    <div className="mt-4">
                      <Button
                        color="success"
                        disabled={isLoading}
                        className="btn btn-success w-100"
                        type="submit"
                      >
                        {isLoading ? (
                          <Spinner size="sm" className="me-2" />
                        ) :
                          (resetPasswordLabels.submit)
                        }
                      </Button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ChangePassword