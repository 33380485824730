import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
  ModalFooter,
} from "reactstrap";

import { Link, useNavigate, useParams } from "react-router-dom";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import EcommerceOrderProduct from "./OrderProduct";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg"
import { updateStatusOfOrder, viewOrders } from "api/ordersApi";
import Loader from "Components/Common/loader";
import AddressCard from "Components/Common/AddressCard";
import { billLabels, categoryLabels, notFound, title } from "utils/helpers/constant";
import { ResponseStatusEnum } from "utils/helpers/enums";
import { toast } from "react-toastify";
import { PDFViewer } from "@react-pdf/renderer";
import BillPDF from "./BillPdf";
import { RequiredField } from "utils/helpers/validationRequiredMessages";
interface ViewOrderData {
  id: number,
  order_number: string,
  shipping_charge: string,
  total_amount: number,
  discount_type: string,
  discount: null,
  net_payable: null,
  order_status: string,
  order_items: OrderItemsData[]
  order_customer_info: OrderCustomerInfo
  payment: PaymentInfo,
  gst_amount: number,
  subTotal: number,
  tracking_no: string,
}
interface PaymentInfo {
  id: number,
  amount: number,
  payment_method: string,
  payment_id: string
}
interface OrderItemsData {
  id: number,
  product_id: ProductData
  price: number,
  quantity: number,
  color: string,
  size: string,
  product_name: string,
  totalAmount: number
}
interface ProductData {
  id: number,
  product_images: ProductImagesData
}
interface ProductImagesData {
  id: number,
  image: string,
  status: boolean
}
interface OrderCustomerInfo {
  id: number,
  customer_contact_no: string,
  customer_name: string,
  customer_email: string,
  billing_address_line_1: string,
  billing_address_line_2: string,
  billing_address_title: string,
  billing_zip_code: string,
  billing_country_id: BillingCountryData,
  billing_province_id: BillingProvinceData,
  billing_city_id: BillingCityData,
  shipping_address_line_1: string,
  shipping_address_line_2: string,
  shipping_address_title: string,
  shipping_zip_code: string,
  shipping_country_id: ShippingCountryData,
  shipping_province_id: ShippingProvinceData,
  shipping_city_id: ShippingCityData,
  billing_city: string,
  shipping_city: string
}
interface BillingCountryData {
  id: number,
  country_name: string
}
interface BillingProvinceData {
  id: number,
  province_name: string
}
interface BillingCityData {
  id: number,
  city_name: string
}
interface ShippingCountryData {
  id: number,
  country_name: string
}
interface ShippingProvinceData {
  id: number,
  province_name: string
}
interface ShippingCityData {
  id: number,
  city_name: string
}

const OrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [viewOrdersData, setViewOrdersData] = useState<ViewOrderData>();
  const [isLoading, setIsLoading] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingNumberError, setTrackingNumberError] = useState('');

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState);
  };

  const handleStatusChange = (status: string, trackingNumber?: string) => {
    setIsLoading(true)
    setSelectedStatus(status);
    const payload: { orderStatus: string, tracking_no?: string } = { orderStatus: status };
    if (trackingNumber) {
      payload.tracking_no = trackingNumber;
    }
    updateStatusOfOrder(orderId, payload).then((response) => {
      if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
        toast.success(response.message);
        fetchViewOrdersData();
        setDropdownOpen(false);
        if (trackingNumber) {
          setDispatchToggle(false);
          setTrackingNumber('');
        }
      } else {
        toast.error(response.message);
      }
    }).catch((err) => {
      toast.error(title.somethingWrong);
    })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchViewOrdersData = () => {
    setIsLoading(true);
    viewOrders(orderId).then((response) => {
      setViewOrdersData(response?.data);
    }).catch((err) => {
      return err;
    }).finally(() => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    fetchViewOrdersData();
  }, [])

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

  const [billModal, setBillModal] = useState(false);

  const toggleBillModal = () => {
    setBillModal(!billModal);
  };

  const [dispatchToggle, setDispatchToggle] = useState(false);

  const toggleDispatch = () => {
    setDispatchToggle(!dispatchToggle);
  };

  const customerInfo = {
    customer_name: viewOrdersData?.order_customer_info?.customer_name,
    customer_contact_no: viewOrdersData?.order_customer_info?.customer_contact_no
  }

  const handleTrackingNumberChange = (e: any) => {
    setTrackingNumber(e.target.value);
    if (trackingNumberError) {
      setTrackingNumberError('');
    }
  }

  const handleTrackingNumberSubmit = (e: any) => {
    e.preventDefault();

    if (!trackingNumber) {
      setTrackingNumberError(RequiredField('Tracking number'));
      return;
    }

    setTrackingNumberError('');
    handleStatusChange('Dispatched', trackingNumber);
  };


  document.title = "Order Details";
  return (
    <div className="page-content">
      {isLoading ? <Loader /> :
        <Container fluid>
          <BreadCrumb title="Order Details" />

          <Row className="mb-4">
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                <button
                  className="btn btn-primary createTask"
                  type="button"
                  onClick={() => { navigate('/orders') }}
                >
                  Back
                </button>
              </div>
            </div>
          </Row>

          <Row>
            <Col xl={9}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">Order #{viewOrdersData?.id}</h5>
                    <div className="flex-shrink-0">
                      <Link
                        to="#"
                        onClick={toggleBillModal}
                        className="btn btn-success btn-sm"
                      >
                        <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                        Invoice
                      </Link>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive table-card">
                    <table className="table table-nowrap align-middle table-borderless mb-0">
                      <thead className="table-light text-muted">
                        <tr>
                          <th scope="col">Product Details</th>
                          <th scope="col">SKU</th>
                          <th scope="col">Item Price</th>
                          <th scope="col">Quantity</th>
                          <th scope="col" className="text-end">
                            Total Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewOrdersData?.order_items?.map((product: any, key: any) => (
                          <EcommerceOrderProduct product={product} key={key} />
                        ))}
                        <tr className="border-top border-top-dashed">
                          <td colSpan={2}></td>
                          <td colSpan={3} className="fw-medium p-0">
                            <table className="table table-borderless mb-0">
                              <tbody>
                                <tr>
                                  <td>Sub Total :</td>
                                  <td className="text-end">A${viewOrdersData?.subTotal ?? notFound?.notAvailable}</td>
                                </tr>
                                {viewOrdersData?.discount && <tr>
                                  <td>
                                    Discount :{" "}
                                  </td>
                                  <td className="text-end">A${viewOrdersData?.discount ?? notFound?.notAvailable}</td>
                                </tr>}
                                <tr>
                                  <td>Shipping Charge :</td>
                                  <td className="text-end">A${viewOrdersData?.shipping_charge ?? notFound?.notAvailable}</td>
                                </tr>
                                {/* <tr> //As of now i have comment this code if need in future so.
                                  <td>Estimated Tax :</td>
                                  <td className="text-end">A${viewOrdersData?.gst_amount ?? notFound?.notAvailable}</td>
                                </tr> */}
                                <tr className="border-top border-top-dashed">
                                  <th scope="row">Total (AUD) :</th>
                                  <th className="text-end">A${viewOrdersData?.total_amount ?? notFound?.notAvailable}</th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={3}>
              {isLoading ?
                <Spinner size="sm" />
                : <Card>
                  <CardHeader>
                    <div className="d-flex">
                      <h6 className="card-title flex-grow-1 mb-0">
                        <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
                        Order Status
                      </h6>
                      <div className="flex-shrink-0">
                        <Col sm={4}>
                          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle caret className="btn btn-sm btn-soft-info ">
                              <i className="ri-pencil-fill fs-16"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => handleStatusChange('Delivered')}>DELIVERED</DropdownItem>
                              <DropdownItem onClick={toggleDispatch}>DISPATCH</DropdownItem>
                              <DropdownItem onClick={() => handleStatusChange('Out-of-Stock')}>OUT OF STOCK</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>

                        </Col>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xxl={6}>
                      <div className="text-center">
                        <p className="fs-15 mb-0 badge text-uppercase bg-secondary-subtle text-primary">{viewOrdersData?.order_status}</p>
                      </div>

                      </Col>
                      <Col xxl={6}>
                        <div className="text-center pt-2 fs-12 mb-1 word-break">
                          <Label>
                            <span className="text-muted mb-0">Tracking No.</span> : {viewOrdersData?.tracking_no}
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>}

              <Card>
                <CardHeader>
                  <div className="d-flex">
                    <h5 className="card-title flex-grow-1 mb-0">
                      Customer Details
                    </h5>
                    <div className="flex-shrink-0">
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <ul className="list-unstyled mb-0 vstack gap-3">
                    <li>
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <img
                            src={dummyImg}
                            alt=""
                            className="avatar-sm rounded"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6 className="fs-12 mb-1 word-break">{viewOrdersData?.order_customer_info?.customer_name ?? notFound?.notAvailable}</h6>
                          <p className="text-muted mb-0">Customer</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                      {viewOrdersData?.order_customer_info?.customer_email ?? notFound?.notAvailable}
                    </li>
                    <li>
                      <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                      {viewOrdersData?.order_customer_info?.customer_contact_no ?? notFound?.notAvailable}
                    </li>
                  </ul>
                </CardBody>
              </Card>

              <AddressCard title="Billing Address" customerInfo={customerInfo} addressLine1={viewOrdersData?.order_customer_info?.billing_address_line_1} addressLine2={viewOrdersData?.order_customer_info?.billing_address_line_2} city={viewOrdersData?.order_customer_info?.billing_city} state={viewOrdersData?.order_customer_info?.billing_province_id} zipCode={viewOrdersData?.order_customer_info?.billing_zip_code} />
              <AddressCard title="Shipping Address" customerInfo={customerInfo} addressLine1={viewOrdersData?.order_customer_info?.shipping_address_line_1} addressLine2={viewOrdersData?.order_customer_info?.shipping_address_line_2} city={viewOrdersData?.order_customer_info?.shipping_city} state={viewOrdersData?.order_customer_info?.shipping_province_id} zipCode={viewOrdersData?.order_customer_info?.shipping_zip_code} />

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>{" "}
                    Payment Details
                  </h5>
                </CardHeader>
                <CardBody>
                  <div className="d-flex align-items-center mb-2">
                    <div className="flex-shrink-0">
                      <p className="text-muted mb-0">Transactions:</p>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-0">{viewOrdersData?.payment?.id ?? notFound?.notAvailable}</h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <div className="flex-shrink-0">
                      <p className="text-muted mb-0">Payment Method:</p>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-0">{viewOrdersData?.payment?.payment_method ?? notFound?.notAvailable}</h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-6">
                      <p className="text-muted mb-0">Card Holder Name:</p>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mb-0">{viewOrdersData?.order_customer_info?.customer_name ?? notFound?.notAvailable}</h6>
                    </div>
                  </div>
                  {/* need to discuss */}
                  {/* <div className="d-flex align-items-center mb-2">
                    <div className="flex-shrink-0">
                      <p className="text-muted mb-0">Card Number:</p>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-0">xxxx xxxx xxxx 2456</h6>
                    </div>
                  </div> */}
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <p className="text-muted mb-0">Total Amount:</p>
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <h6 className="mb-0">A${viewOrdersData?.payment?.amount ?? notFound?.notAvailable}</h6>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>}

      <Modal
        isOpen={billModal}
        toggle={toggleBillModal}
        modalClassName="zoomIn"
        centered
      >
        <ModalHeader toggle={toggleBillModal} className="p-3 bg-light p-3">
          {billLabels?.viewBill}
        </ModalHeader>
        <ModalBody>
          <PDFViewer width="100%" height="600px">
            <BillPDF viewOrdersData={viewOrdersData} />
          </PDFViewer>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={dispatchToggle}
        toggle={toggleDispatch}
        modalClassName="zoomIn"
        centered
      >
        <ModalHeader toggle={toggleDispatch} className="p-3 bg-light p-3">
          Tracking Order
        </ModalHeader>
        <Form onSubmit={handleTrackingNumberSubmit}>
          <ModalBody>

            <Label for="trackingNumber">Tracking No.</Label>
            <Input
              type="text"
              id="trackingNumber"
              value={trackingNumber}
              placeholder="Enter tracking no."
              onChange={handleTrackingNumberChange}
              invalid={!!trackingNumberError}
            />
            {trackingNumberError && <FormFeedback>{trackingNumberError}</FormFeedback>}
          </ModalBody>
          <ModalFooter>
            <Button
              color="none"
              className="btn btn-ghost-success"
              onClick={() => { setDispatchToggle(false); setTrackingNumber(''); }}
            >
              {categoryLabels.cancel}
            </Button>
            <Button type="submit" color="primary">{categoryLabels.submit}</Button>
          </ModalFooter>
        </Form>
      </Modal>

    </div >
  );
};

export default OrderDetails