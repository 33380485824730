import moment from "moment";
import { getToken } from "./sessionStores";
import { jwtDecode } from "jwt-decode";

export const dateFormatChange = (date: any) => {
  return moment(date).format("YYYY-MM-DD");
};

export const timeFormat = (time: any) => {
  return moment(time).format("HH:mm");
};

export const tokenDecode = () => {
  const token: any = getToken();
  const decodedToken = jwtDecode(token);
  return decodedToken;
};

export const dateFormat = (date: string | null) => {
  return moment(date).format("DD MMM Y");
};

export const orderReturnFormatDate = (date: string | null) => {
  return moment(date, "DD/MM/YYYY").format("DD MMM Y");
};
