export const setToken = (token: string) => {
  sessionStorage.setItem('token', token)
};

export const getToken = () => {
  const authToken = sessionStorage.getItem('authUser')
  return authToken;
};

export const getRole = () => {
  const role = sessionStorage.getItem('role');
  return role;
}

export const setOrderToken = (orderToken: string) => {
  const orderAccessToken = localStorage.setItem('orderToken', orderToken);
  return orderAccessToken;
}

export const getOrderToken = () => {
  const orderAccessToken = localStorage.getItem('orderToken')
  return orderAccessToken;
};

export const setSalesReturnOrderToken = (orderToken: string) => {
  const salesReturnOrderAccessToken = localStorage.setItem('salesReturnOrderToken', orderToken);
  return salesReturnOrderAccessToken;
}

export const getSalesReturnOrderToken = () => {
  const salesReturnOrderAccessToken = localStorage.getItem('salesReturnOrderToken')
  return salesReturnOrderAccessToken;
};

export const setFinancialYear = (financialYear: string) => {
  sessionStorage.setItem('financialYear', financialYear)
}

export const getFinancialYear = () => {
  const fYear = sessionStorage.getItem('financialYear')
  return fYear;
};