import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Container, CardHeader, Row, Input, Label, FormFeedback, Form, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import TableContainer from "Components/Common/TableContainer";
import { PlaceHolderFormat, RequiredField } from "utils/helpers/validationRequiredMessages";
import { addSalesOrderCustomer, createSaleOrder, deleteOrderItem, deleteScanItem, generateBillNumber, listOfSalesOrderCustomer, scanBarCode, updateSalesOrder, viewAndStoreOrders, viewOrders } from "api/ordersApi";
import { ResponseStatusEnum } from "utils/helpers/enums";
import { toast } from "react-toastify";
import { addSalesOrderReturn, categoryLabels, currencyFormat, customerLabels, loginLabels, notFound, productPurchaseEntry, staffLabels, title, validationMessages } from "utils/helpers/constant";
import { dateFormat, dateFormatChange, timeFormat } from "utils/helpers/comman";
import DeleteModal from "Components/Common/DeleteModal";
import { salesOrderDiscount, scanItemApplyDiscount } from "api/salesOrderApi";
import LoaderBlur from "Components/Common/BlurLoader";
import BillPDF from "./BillPdf";
import { PDFViewer } from "@react-pdf/renderer";
import { emailRegex } from "utils/helpers/regexPatterns";
import { listOfCustomerOptions } from "api/dropDownApi";
import { getOrderToken, setOrderToken } from "utils/helpers/sessionStores";
interface ScanProduct {
    id: number,
    barcode: string,
    product_name: string,
    sale_rate: number,
    color: string,
    size: string,
    quantity: number,
    discount_type: string,
    discount: number,
    product_id: {
        id: number
    },
    product_variant_id: {
        id: number
    }
}
interface ListOfScanProduct {
    id: number,
    total_amount: number,
    total_quantity: number,
    discount_type: string,
    discount: number,
    scan_product_items: ScanProduct[],
}

interface ListOfViewScanProduct {
    id: number,
    total_amount: number,
    total_quantity: number,
    discount_type: string,
    discount: number,
    scan_product_items: ScanProduct[],
}

interface GenerateBillNoProps {
    billNo: string,
    billDate: string,
    billTime: string,
}
interface CustomerData {
    contact_no?: string,
    id: number,
    label: string,
    name: string,
    value: string
}
interface ViewOrdersData {
    bill_no: number,
    bill_time: string,
    discount: number,
    discount_type: string,
    mrp_amount: number,
    order_date: string,
    order_save_status: string,
    order_items: [],
    order_customer_info: CustomerDetails
    round_off: number,
    shipping_charge: number,
    total_amount: number,
    payment_option: string,
    net_payable: number,
    total_quantity: number,
    cash_amount: number,
    card_amount: number,
    discountableAmount: number
}
interface CustomerDetails {
    id: number,
    customer_contact_no: number,
    customer_name: string,
    customer_email: string | null,
}

interface Payload {
    order_id?: string;
    scan_product_id?: number | undefined | null;
    bill_no: string;
    discount_type: string;
    discount: number;
}
interface InputValues {
    [key: number]: string;
}

const CreateSalesOrder = () => {
    document.title = "Sale Order";

    const navigate = useNavigate();
    const currentDate = new Date();
    const { orderId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [customerModal, setCustomerModal] = useState(false);
    const [generateBillNumberData, setGenerateBillNumberData] = useState<GenerateBillNoProps>();
    const [listOfScanProductData, setListOfScanProductData] = useState<ListOfScanProduct | null | any>();
    const [scanProductListId, setScanProductListId] = useState<number | null>();
    const [customerList, setCustomerList] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerData>();
    const [viewOrdersData, setViewOrdersData] = useState<ViewOrdersData | any>();
    const [statusOfOrder, setStatusOfOrder] = useState(productPurchaseEntry?.draftStatus);
    const [scanItemModal, setScanItemModal] = useState(false);
    const [selectedId, setSelectedId] = useState<undefined | number>();
    const [billDate, setBillDate] = useState(currentDate);
    const [time, setTime] = useState(currentDate);
    const [discountData, setDiscountData] = useState<any>();
    const [discountableAmount, setDiscountableAmount] = useState<number>(0);
    const [discountMessage, setDiscountMessage] = useState('');
    const [isDiscountLoading, setIsDiscountLoading] = useState(false);
    const [isBarCodeLoading, setIsBarCodeLoading] = useState(false);
    const [discountInputValue, setDiscountInputValue] = useState('');
    const [discountType, setDiscountType] = useState('');
    const [billModal, setBillModal] = useState(false);
    const [viewAndStoreOrdersData, setViewAndStoreOrdersData] = useState<ListOfViewScanProduct>();
    const orderToken: string | null = getOrderToken();

    const toggleBillModal = () => {
        setBillModal(!billModal);
    };

    const scanBarcodeApi = (barCode: string) => {
        let trimmedBarcode = barCode?.startsWith('+') ? barCode?.slice(1) : barCode;
        let payload: any = {}
        if (orderId) {
            payload = {
                bar_code: trimmedBarcode,
                bill_no: viewOrdersData ? viewOrdersData?.bill_no : generateBillNumberData?.billNo,
                order_id: orderId
            }
        } else {
            payload = {
                bar_code: trimmedBarcode,
                bill_no: viewOrdersData ? viewOrdersData?.bill_no : generateBillNumberData?.billNo,
                order_token: orderToken
            }
            if (orderToken) {
                payload.order_token = orderToken
            }
        }
        setIsBarCodeLoading(true);
        scanBarCode(payload)
            .then((response) => {
                if (ResponseStatusEnum.ERROR.includes(response.statusCode)) {
                    toast.error(response.message)
                }
                if (ResponseStatusEnum.SUCCESS.includes(response.statusCode)) {
                    viewOrdersApi();
                    setOrderToken(response?.data?.token)
                    if (orderId) {
                        fetchViewProfile()
                    }
                } else {
                    setScanProductListId(null);
                }
            })
            .catch((err) => {
                setScanProductListId(null)
            })
            .finally(() => {
                setIsBarCodeLoading(false);
            });
    }

    const viewOrdersApi = () => {
        setIsLoading(true);
        if (orderToken) {
            const payload = {
                order_token: orderToken
            }
            viewAndStoreOrders(payload).then((response) => {
                setViewAndStoreOrdersData(response?.data);
                setTotalAmountOfOrder(response?.data?.total_amount)
                setTotalNetPayableAmount(response?.data?.totalNetPayableAmount)
                setDiscountableAmount(response?.data?.totalDiscountedAmount)
                setScanProductListId(response?.data?.id);
            }).catch((err) => {
                return err;
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }

    const fetchListOfSalesOrderCustomer = () => {
        const payload = {
            selectionCriteria: [
                "id",
                "name",
                "contact_no"
            ],
        }
        listOfCustomerOptions(payload).then((response) => {
            if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                const data = response?.data?.map((item: any) => ({ value: item.name, label: item.name, ...item }));
                setCustomerList(data);
            } else setCustomerList([]);
        }).catch((err) => setCustomerList([]));
    }

    const fetchGenerateBillNumber = () => {
        setIsLoading(true);
        generateBillNumber().then((response) => {
            setGenerateBillNumberData(response?.data);
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const fetchViewProfile = () => {
        setIsLoading(true);
        viewOrders(orderId).then((response) => {
            setViewOrdersData(response?.data);
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        fetchViewProfile();
    }, [orderId])

    useEffect(() => {
        fetchListOfSalesOrderCustomer()
    }, []);

    useEffect(() => {
        if (!orderId) {
            fetchGenerateBillNumber()
        }
    }, [])

    useEffect(() => {
        viewOrdersApi();
    }, [orderToken])

    const toggle = useCallback(() => {
        if (customerModal) {
            setCustomerModal(false);
            customerValidation?.resetForm();
        } else {
            setCustomerModal(true);
        }
    }, [customerModal]);

    const onClickDelete = (id: number) => {
        setSelectedId(id);
        setScanItemModal(true);
    };
    const [updateMode, setUpdateMode] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
    const [discountOnSaleRate, setDiscountOnSaleRate] = useState<any>();
    const [discountedTotalAmount, setDiscountedTotalAmount] = useState();
    const [discountAmount, setDiscountAmount] = useState<string | any>();
    const [isDiscountApplyLoading, setIsDiscountApplyLoading] = useState(false);
    const [inputValue, setInputValue] = useState<InputValues>({});
    const [saleRateInputValue, setSaleRateInputValue] = useState<InputValues>({});
    const [editModeMap, setEditModeMap] = useState<{ [key: number]: boolean }>({});
    const [isClearable, setIsClearable] = useState(true);
    const [originalDiscount, setOriginalDiscount] = useState<any>();
    const [totalNetPayableAmount, setTotalNetPayableAmount] = useState<any>(null);
    const [totalAmountOfOrder, setTotalAmountOfOrder] = useState();

    const handleDeleteScanItems = () => {
        setScanItemModal(false);
        setIsDiscountApplyLoading(true);
        const isOnlyOneItem = viewAndStoreOrdersData?.scan_product_items?.length === 1
        if (viewOrdersData === undefined) {
            deleteOrderItem(selectedId).then((response) => {
                if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    viewOrdersApi();
                    if (isOnlyOneItem) {
                        localStorage.clear();
                    }
                    setDiscountInputValue('');
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            })
                .catch((err) => {
                    toast.error(title.somethingWrong);
                    return err;
                })
                .finally(() => {
                    setIsDiscountApplyLoading(false);
                });
        } else {
            deleteScanItem(selectedId)
                .then((response) => {
                    if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        setListOfScanProductData(response?.data);
                        fetchViewProfile()
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                })
                .catch((err) => {
                    toast.error(title.somethingWrong);
                    return err;
                })
                .finally(() => {
                    setIsDiscountApplyLoading(false);
                });
        }
    };

    const discountTypeOptions = [
        { value: 'Percentage', label: 'Percentage' }
    ];

    const [discounts, setDiscounts] = useState<{ [key: number]: string }>({});
    const [saleRates, setSaleRates] = useState<{ [key: number]: string }>({});
    const [focusedInputId, setFocusedInputId] = useState<any>(null);

    // When initializing discounts, ensure each row has its own discount

    useEffect(() => {
        if (Array.isArray(viewOrdersData)) {
            const initialDiscounts: { [key: number]: string } = {};
            const initialSaleRate: { [key: number]: string } = {};
            // Initialize discounts for each row
            viewOrdersData?.forEach((row: any) => {
                initialDiscounts[row?.id] = row?.discount || '';
                initialSaleRate[row?.id] = row?.sale_rate || '';
            });
            setDiscounts(initialDiscounts);
            setSaleRates(initialSaleRate);
        }
    }, [viewOrdersData]);


    // Update discount for a specific row
    const handleDiscountChange = (id: number, value: string) => {
        setInputValue(prevState => ({
            ...prevState,
            [id]: value,
        }));
        setFocusedInputId(id);
    };

    const handleSaleRateChange = (id: number, value: string) => {
        setSaleRateInputValue(prevState => ({
            ...prevState,
            [id]: value,
        }))
        setFocusedInputId(id);
    }

    const handleEditClick = (id: number, discount?: number, sale_rate?: any) => {
        setEditModeMap(prevState => ({
            ...prevState,
            [id]: true,
        }));
        setSelectedRowId(id);
        setInputValue(prevState => ({
            ...prevState,
            [id]: discount !== undefined ? discount?.toString() : '',
        }));
        setSaleRateInputValue((prev: any) => ({ ...prev, [id]: sale_rate }))
        setFocusedInputId(id);
    };

    const handleCheckClick = (id: number, barcode: string) => {
        const discountValue: any = inputValue[id];
        const saleRateValue: any = saleRateInputValue[id];

        let scanItemPayload: any = {}
        scanItemPayload = {
            scan_item_id: id,
            discount: parseInt(discountValue),
        };
        if (barcode === '0' || barcode === '1') {
            scanItemPayload.sale_rate = parseInt(saleRateValue);
        }
        setFocusedInputId(null);
        setIsDiscountApplyLoading(true);
        scanItemApplyDiscount(scanItemPayload).then((response) => {
            if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                viewOrdersApi();
                setDiscountOnSaleRate((prevState: any) => ({
                    ...prevState,
                    [id]: response?.data?.discounted_sale_rate,
                }));
                setEditModeMap(prevState => ({
                    ...prevState,
                    [id]: false,
                }));
                setDiscounts(prevState => ({
                    ...prevState,
                    [id]: discountValue,
                }));
                setSelectedRowId(null);
                setInputValue(prevState => ({
                    ...prevState,
                    [id]: '',
                }));
                setSaleRateInputValue(prevState => ({
                    ...prevState,
                    [id]: '',
                }))
                toast.success(response?.message)
            } else {
                toast.error(response.message);
            }
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsDiscountApplyLoading(false);
        });
    };

    const handleCloseClick = (id: number) => {
        setEditModeMap(prevState => ({
            ...prevState,
            [id]: false,
        }));
        setSelectedRowId(null);
        setInputValue(prevState => ({
            ...prevState,
            [id]: '',
        }));
        setSaleRateInputValue(prevState => ({
            ...prevState,
            [id]: '',
        }))
    };

    const SKUColumns = useMemo(
        () => [
            {
                header: "Product",
                accessorKey: "product_name",
                enableColumnFilter: false,
                cell: (cell: { row: { original: { product_name: string, product_code: string } } }) => (
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1">
                                {" "}
                                {cell?.row?.original?.product_name}
                            </h5>
                            <p className="text-muted mb-0">
                                <span className="fw-medium">
                                    {" "}
                                    {cell?.row?.original?.product_code}
                                </span>
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                header: "SKU",
                accessorKey: "barcode",
                enableColumnFilter: false,
            },
            {
                header: "Color",
                accessorKey: "color",
                enableColumnFilter: false,
            },
            {
                header: "Size",
                accessorKey: "size",
                enableColumnFilter: false,
            },
            {
                header: "Quantity",
                accessorKey: "quantity",
                enableColumnFilter: false,
            },
            {
                header: "SaleRate",
                accessorKey: "sale_rate",
                enableColumnFilter: false,
                cell: ({ cell, row }: any) => {
                    const { id, sale_rate, barcode, price }: any = row.original;
                    const isEditMode = editModeMap[id];
                    const isEditable = barcode === "0" || barcode === "1";
                    const autoFocus = isEditMode && focus.saleRateFocus;
                    if (isEditable && isEditMode) {
                        return (
                            <Input
                                type="number"
                                value={saleRateInputValue[id] || ''}
                                onChange={(event) => {
                                    handleSaleRateChange(id, event.target.value);
                                    setFocus({ saleRateFocus: true, discountFocus: false });
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        handleCheckClick(id, barcode);
                                    }
                                }}
                                autoFocus={autoFocus}
                            />
                        );
                    } else {
                        return <span>{currencyFormat?.currency}{viewOrdersData ? price : saleRateInputValue[id] || sale_rate}</span>;
                    }
                },
            },
            {
                header: "Discount",
                accessorKey: 'discount',
                enableColumnFilter: false,
                cell: ({ cell, row }: any) => {
                    const { id, discount, barcode } = row.original;
                    const isEditMode = editModeMap[id];
                    const autoFocus = isEditMode && focus.discountFocus;


                    if (isEditMode) {
                        return (
                            <div>
                                <Input
                                    type="number"
                                    value={inputValue[id] || ''}
                                    placeholder="Discount"
                                    onChange={(event) => {
                                        handleDiscountChange(id, event.target.value);
                                        setFocus({ saleRateFocus: false, discountFocus: true });
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            handleCheckClick(id, barcode);
                                        }
                                    }}
                                    autoFocus={autoFocus}
                                />
                            </div>
                        );
                    } else {
                        return <span>{discounts[id] === undefined ? (discount === 0 ? notFound.notAvailable : `${discount}%`) : `${discounts[id]}%`}</span>;
                    }
                }
            },
            {
                header: "Discounted Sale Rate",
                accessorKey: 'discounted_sale_rate',
                enableColumnFilter: false,
                cell: ({ row }: any) => {
                    const { id } = row.original;
                    const originalDiscountedSaleRate = row.original.discounted_sale_rate;
                    const editedDiscountedSaleRate = discountOnSaleRate?.[id];

                    const displayValue = `${currencyFormat.currency}${editedDiscountedSaleRate != null ? editedDiscountedSaleRate : originalDiscountedSaleRate != null ? originalDiscountedSaleRate : notFound?.notAvailable}`;
                    return <span>{displayValue}</span>;
                }
            },
            {
                header: "Actions",
                cell: (cell: { row: { original: { id: number, discount: number, sale_rate: any, barcode: string } } }) => {
                    const { id, discount, sale_rate, barcode } = cell.row?.original;
                    const isEditMode = editModeMap[id];
                    const isFirstRowZeroOrOne = viewAndStoreOrdersData?.scan_product_items?.[0]?.barcode === "0" ||
                        viewAndStoreOrdersData?.scan_product_items?.[0]?.barcode === "1";
                    const isDisabled = viewAndStoreOrdersData?.scan_product_items?.length === 1 && !isFirstRowZeroOrOne;

                    return (
                        <div className="hstack gap-2">
                            {isEditMode ? (
                                <>
                                    <button className="btn btn-sm btn-soft-success" type="button" onClick={() => handleCheckClick(id, barcode)}>
                                        <i className="ri-check-fill"></i>
                                    </button>
                                    <button className="btn btn-sm btn-soft-danger" type="button" onClick={() => handleCloseClick(id)}>
                                        <i className="ri-close-fill"></i>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        className="btn btn-sm btn-soft-info edit-list"
                                        type="button"
                                        disabled={viewOrdersData?.order_save_status === "Publish" || isDisabled}
                                        onClick={() => handleEditClick(id, discount, sale_rate)}
                                    >
                                        <i className="ri-pencil-fill align-bottom" />
                                    </button>
                                    <button
                                        className="btn btn-sm btn-soft-danger remove-list"
                                        disabled={viewOrdersData?.order_save_status === "Publish"}
                                        type="button"
                                        onClick={() => onClickDelete(id)}
                                    >
                                        <i className="ri-delete-bin-5-fill align-bottom" />
                                    </button>
                                </>
                            )}
                        </div>
                    );
                },
            }
        ],
        [editModeMap, inputValue, saleRateInputValue, focusedInputId, viewOrdersData, discounts, saleRates, discountOnSaleRate, viewAndStoreOrdersData]
    );

    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            billNo: (viewOrdersData ? viewOrdersData?.bill_no : generateBillNumberData?.billNo) ?? '',
            billDate: (viewOrdersData ? dateFormat(viewOrdersData?.order_date) : (billDate ?? '')),
            time: (viewOrdersData ? viewOrdersData?.bill_time : (time ?? '')),
            quantity: (viewOrdersData && viewOrdersData?.total_quantity) ?? (viewAndStoreOrdersData?.total_quantity || ""),
            scanSKU: '',
            discountType: (viewOrdersData && viewOrdersData?.discount_type) ?? 'Percentage',
            discount: (viewOrdersData && viewOrdersData?.discount) ?? (viewAndStoreOrdersData?.discount || ''),
            // roundOff: (viewOrdersData ? viewOrdersData?.round_off : ''),  In this file i have comment the all code of roundOff please allow that.
            cash_amount: (viewOrdersData && viewOrdersData?.cash_amount) ?? "",
            card_amount: (viewOrdersData && viewOrdersData?.card_amount) ?? totalNetPayableAmount,
            customer: (viewOrdersData && viewOrdersData?.order_customer_info?.customer_name) ?? selectedCustomer?.label ?? '',
            mobile_No: (viewOrdersData && viewOrdersData?.order_customer_info?.customer_contact_no) ?? selectedCustomer?.contact_no ?? "",
            amount: (viewOrdersData && viewOrdersData.total_amount) ?? totalAmountOfOrder,
            net_payable: (viewOrdersData && viewOrdersData?.net_payable) ?? (totalNetPayableAmount || 0),
            discountableAmount: ((viewOrdersData && viewOrdersData?.discountable_amount) || discountableAmount)
        },
        validationSchema: Yup.object({
            billNo: Yup.string().required(RequiredField("Bill no")),
            billDate: Yup.string().required(RequiredField("Bill date")),
            time: Yup.string().required(RequiredField("Bill time")),
            quantity: Yup.string().required(RequiredField("Quantity")),
            cash_amount: Yup.number().test({
                test: function (value) {
                    return (typeof value === 'number' && value !== undefined && value !== null) || (typeof this.parent.card_amount === 'number' && this.parent.card_amount !== undefined && this.parent.card_amount !== null);
                },
                message: RequiredField("Either Cash amount or Card amount"),
            }),
            card_amount: Yup.number().test({
                test: function (value) {
                    return (typeof value === 'number' && value !== undefined && value !== null) || (typeof this.parent.cash_amount === 'number' && this.parent.cash_amount !== undefined && this.parent.cash_amount !== null);
                },
                message: RequiredField("Either Cash amount or Card amount"),
            }),
            // discount: Yup.number().test({
            //     test: function (value) {
            //         if (this.parent.discountType === 'Percentage') {
            //             return typeof value === 'number' && value >= 0 && value <= 100;
            //         }
            //         return true;
            //     },
            //     message: (props) => {
            //         if (props.path === 'discount') {
            //             return `${RequiredField("Discount")}`
            //         }
            //         return '';
            //     }
            // }) here if any future need so i have not remove
        }),
        onSubmit: (values) => {
            const filteredValues = Object.fromEntries(
                Object.entries(values)?.filter(([key, value]) => value !== "")
            );
            setIsLoading(true);
            if (viewOrdersData) {
                updateSalesOrder(orderId, { ...filteredValues, order_save_status: statusOfOrder }).then((response) => {
                    if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        toast.success(response.message);
                        navigate(`/orders`)
                        setGenerateBillNumberData(undefined)
                        validation.resetForm();
                    } else {
                        toast.error(response.message);
                    }
                })
                    .catch((err) => {
                        toast.error(title.somethingWrong);
                        return err;
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(true);
                const payload = {
                    bill_no: values?.billNo,
                    bill_date: dateFormatChange(values?.billDate),
                    bill_time: timeFormat(values?.time),
                    discount_type: values?.discountType,
                    discount: parseFloat(discountInputValue),
                    card_amount: parseFloat(filteredValues?.card_amount),
                    cash_amount: parseFloat(filteredValues?.cash_amount),
                    customer_name: values?.customer,
                    contact_no: values?.mobile_No.toString(),
                    net_payable: +totalNetPayableAmount,
                    discountable_amount: +discountableAmount,
                    order_token: orderToken
                }
                createSaleOrder({ ...payload, order_save_status: addSalesOrderReturn?.publish }).then((response) => {
                    if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        toast.success(response.message);
                        navigate(`/orders`)
                        validation.resetForm();
                        localStorage.clear()
                    } else {
                        toast.error(response.message);
                    }
                })
                    .catch((err) => {
                        toast.error(title.somethingWrong);
                        return err;
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    });

    const customerValidation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            customer_name: "",
            email: "",
            contact_no: ""
        },

        validationSchema: Yup.object({
            customer_name: Yup.string().required(RequiredField(`${customerLabels?.title} ${categoryLabels?.categoryAccessKey}`)),
            email: Yup.string()
                .email(validationMessages.format(loginLabels.email))
                .matches(emailRegex, validationMessages.format(loginLabels.email))
                .required(validationMessages.required(loginLabels.email)),
            contact_no: Yup.string().required(RequiredField(customerLabels?.contactNumber))
        }),

        onSubmit: (values) => {
            setIsLoading(true);
            const payload = {
                customer_name: values.customer_name,
                email: values.email,
                contact_no: values.contact_no
            }
            addSalesOrderCustomer(payload).then((response) => {
                if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    toggle();
                    toast.success(response.message);
                    customerValidation.resetForm();
                    fetchListOfSalesOrderCustomer();
                    setCustomerModal(false);
                } else {
                    toast.error(response.message);
                }
            }).catch((err) => {
                toast.error(title.somethingWrong);
                return err;
            }).finally(() => {
                setIsLoading(false);
            });
        }
    });

    const fetchScanDiscountApi = () => {
        setIsDiscountLoading(true);
        let payload: Payload | any = {};
        if (orderId) {
            payload = {
                order_id: orderId,
                bill_no: viewOrdersData ? viewOrdersData?.bill_no : generateBillNumberData?.billNo,
                discount_type: validation?.values?.discountType || discountType,
                discount: parseInt(validation?.values?.discount) || parseInt(discountInputValue)
            }
        } else {
            payload = {
                scan_product_id: scanProductListId,
                bill_no: viewOrdersData ? viewOrdersData?.bill_no : generateBillNumberData?.billNo,
                discount_type: validation?.values?.discountType || discountType,
                discount: parseInt(validation?.values?.discount) || parseInt(discountInputValue)
            }
        }

        if (payload.discount <= 0 || payload.discount > 100) {
            if (payload.discount <= 0) {
                toast.error(categoryLabels?.minimumDiscountOfOrder);
                setIsDiscountLoading(false);
            } else {
                toast.error(categoryLabels?.maximumDiscount);
                setIsDiscountLoading(false);
            }
            return;
        }

        salesOrderDiscount(payload).then((response) => {
            if (response.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                viewOrdersApi();
            }
            else if (response?.statusCode && ResponseStatusEnum.ERROR.includes(response?.statusCode)) {
                toast.error(response?.message)
            }
        }).catch((err) => {
            setDiscountMessage(err)
        }).finally(() => {
            setIsDiscountLoading(false);
        })
    }

    useEffect(() => {
        const handleKeyPress = (event: any) => {
            if (event.key === 'F7') {
                event.preventDefault();
                validation.setValues((prevValues: any) => {
                    const { cash_amount, card_amount, ...rest } = prevValues;
                    return {
                        cash_amount: prevValues.card_amount,
                        card_amount: prevValues.cash_amount,
                        ...rest
                    };
                });
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [validation]);

    const handleCustomerChange = (option: any) => {
        setSelectedCustomer(option);
        validation.setFieldValue('customer', option ? option.value : '');
        setIsClearable((state) => !state);
    };

    const [focus, setFocus] = useState({ saleRateFocus: false, discountFocus: false });

    return (
        <Row>
            <Col lg={12}>
                {isLoading && <LoaderBlur />}
                <DeleteModal
                    show={scanItemModal}
                    onDeleteClick={handleDeleteScanItems}
                    onCloseClick={() => setScanItemModal(false)}
                />
                <div className="page-content">
                    <Container fluid>
                        <Row className="mb-3">
                            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                                <h5 className="fw-semibold mb-0">{viewOrdersData ? "View" : "Create"} {"Sales Order"}</h5>
                            </div>
                            <div className="col-auto order-2 order-sm-3 ms-auto">
                                {viewOrdersData?.order_save_status === "Publish" && (
                                    <button color="success" className="btn btn-success createTask" onClick={toggleBillModal}>
                                        <i className="ri-download-line label-icon align-middle me-2"></i>
                                        Generate Bill
                                    </button>
                                )}
                            </div>

                            <Modal
                                isOpen={billModal}
                                toggle={toggleBillModal}
                                modalClassName="zoomIn"
                                centered
                            >
                                <ModalHeader toggle={toggleBillModal} className="p-3 bg-light p-3">
                                    View Bill
                                </ModalHeader>
                                <ModalBody>
                                    <PDFViewer width="100%" height="600px">
                                        <BillPDF viewOrdersData={viewOrdersData} />
                                    </PDFViewer>
                                </ModalBody>
                            </Modal>

                            <div className="col-auto order-2 order-sm-3 ms-auto">
                                <button
                                    className="btn btn-primary createTask"
                                    type="button"
                                    onClick={() => { navigate('/orders') }}
                                >
                                    Back
                                </button>
                            </div>
                        </Row>
                        <Row>
                            <Col lg={8}>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="product-title-input">
                                                            Bill No
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control disabled-input-product-purchase"
                                                            id="product-title-input"
                                                            placeholder="Enter bill no."
                                                            name="billNo"
                                                            readOnly
                                                            value={validation.values.billNo || generateBillNumberData?.billNo}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={!!(validation.errors.billNo && validation.touched.billNo)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label>Bill Date</Label>
                                                        <Flatpickr
                                                            className='form-control disabled-input-product-purchase'
                                                            placeholder='Select Bill Date'
                                                            value={validation.values.billDate}
                                                            disabled
                                                            onChange={date => validation.setFieldValue('billDate', date[0])}
                                                            options={{ dateFormat: 'd M Y' }}
                                                        />
                                                        {validation.touched.billDate && validation.errors.billDate ? (
                                                            <FormFeedback type="invalid">{validation.errors.billDate}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label>Bill Time</Label>
                                                        <Flatpickr
                                                            className='form-control disabled-input-product-purchase'
                                                            placeholder='Select Time'
                                                            value={validation.values.time}
                                                            onChange={time => validation.setFieldValue('time', time[0])}
                                                            disabled
                                                            options={{ dateFormat: 'H:i', enableTime: true, time_24hr: true, noCalendar: true }} />
                                                        {validation.touched.time && validation.errors.time ? (
                                                            <FormFeedback type="invalid">{validation.errors.time}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label>Quantity</Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control disabled-input-product-purchase"
                                                            id="product-title-input"
                                                            placeholder="Enter quantity"
                                                            name="quantity"
                                                            value={validation.values.quantity || ""}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            disabled
                                                            invalid={!!(validation.errors.quantity && validation.touched.quantity)} />
                                                        {validation.touched.quantity && validation.errors.quantity ? (
                                                            <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <Label htmlFor="scanSKU" className="form-label">SKU</Label>
                                                    <div className="input-group mb-3">
                                                        <span
                                                            className="input-group-text"
                                                            id="product-Discount-addon"
                                                        >
                                                            <i className="ri-barcode-box-line"></i>
                                                        </span>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="product-Discount-input"
                                                            placeholder="Enter SKU"
                                                            name="scanSKU"
                                                            aria-label="scanSKU"
                                                            disabled={viewOrdersData?.order_save_status === "Publish"}
                                                            aria-describedby="bill-Discount-addon"
                                                            value={validation.values.scanSKU || ""}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            onKeyDown={(e) => {
                                                                if ((e.key === 'Enter' || e.key === 'Tab')) {
                                                                    e.preventDefault();
                                                                    if (validation.values.scanSKU === "") {
                                                                        toast.error(categoryLabels?.validSKU);
                                                                    } else {
                                                                        scanBarcodeApi(validation.values.scanSKU);
                                                                    }
                                                                }
                                                            }}
                                                            invalid={!!(validation.errors.scanSKU && validation.touched.scanSKU)} />
                                                        {validation.errors.scanSKU && validation.touched.scanSKU ? (
                                                            <FormFeedback type="invalid">{validation.errors.scanSKU}</FormFeedback>
                                                        ) : null}
                                                        {isBarCodeLoading && (
                                                            <div className="d-flex justify-content-center align-item-center p-2">
                                                                <Spinner size="sm" className="me-2" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            {isDiscountApplyLoading && <LoaderBlur />}
                                            {(viewOrdersData?.order_items || (viewAndStoreOrdersData?.scan_product_items?.length ?? 0) > 0) ? (
                                                <TableContainer
                                                    columns={SKUColumns}
                                                    data={viewOrdersData ? viewOrdersData?.order_items : viewAndStoreOrdersData?.scan_product_items}
                                                    isGlobalFilter={false}
                                                    customPageSize={5}
                                                    divClass="table-responsive mb-1"
                                                    tableClass="mb-0 align-middle table-border"
                                                    theadClass="table-light text-muted"
                                                    SearchPlaceholder="Search Category"
                                                    manualPagination={false}
                                                    manualSorting={false}
                                                />
                                            ) : (
                                                <div className="py-4 text-center">
                                                    <div>
                                                        <i className="ri-search-line display-5 text-success"></i>
                                                    </div>
                                                    <div className="mt-4">
                                                        <h5>{notFound?.loadingContent}</h5>
                                                    </div>
                                                </div>
                                            )}
                                        </CardBody>
                                    </Card>
                                    <div className="text-end mb-3">
                                        {/* <button type="submit" className="btn btn-primary w-sm m-1" disabled={viewOrdersData?.order_save_status === "Publish"}>
                                            Save as Draft (As Of commented)
                                        </button> */}
                                        <button type="submit" className="btn btn-primary w-sm" disabled={viewOrdersData?.order_save_status === "Publish"}
                                        // onClick={() => { setStatusOfOrder("Publish") }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            </Col>
                            <Col lg={4}>
                                <Card>
                                    <CardBody>
                                        <Col>
                                            <div className="mb-2">
                                                <Label htmlFor="discountType" className="form-label">Discount Type</Label>
                                                <Select
                                                    id="discountType"
                                                    name="discountType"
                                                    options={discountTypeOptions}
                                                    value={discountTypeOptions.find(option => option.value === validation.values.discountType)}
                                                    isDisabled
                                                    onChange={(option: any) => {
                                                        setDiscountType(option?.value);
                                                        if (option.value === '') {
                                                            setDiscountInputValue('')
                                                            validation.setFieldValue('net_payable', viewAndStoreOrdersData?.total_amount)
                                                        } else {
                                                            validation.setFieldValue('discountType', option.value);
                                                            validation.setFieldValue('net_payable', viewAndStoreOrdersData?.total_amount ?? (option?.value === "Percentage" && discountData))
                                                        }
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    placeholder="Select Discount Type"
                                                />
                                                {validation.touched.discountType && validation.errors.discountType ? (
                                                    <div className="text-danger">{validation.errors.discountType}</div>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Row>
                                            <Col>
                                                <Label htmlFor="discount" className="form-label">Discount In {validation.values.discountType === "Amount" ? "Amount" : "%"}</Label>
                                                <div className="input-group mb-2">
                                                    {validation.values.discountType === "Percentage" && <span className="input-group-text" id="product-Discount-addon"> % </span>}
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id="product-Discount-input"
                                                        placeholder={PlaceHolderFormat("discount")}
                                                        name="discount"
                                                        aria-label="discount"
                                                        aria-describedby="bill-Discount-addon"
                                                        value={validation.values.discount || discountInputValue || ''}
                                                        onBlur={validation.handleBlur}
                                                        readOnly={validation?.values?.scanSKU === "0" || validation?.values?.scanSKU === "1"}
                                                        disabled={viewOrdersData?.order_save_status === "Publish" || viewAndStoreOrdersData?.scan_product_items?.length !== 1 || viewAndStoreOrdersData?.scan_product_items?.[0]?.barcode === "0" || viewAndStoreOrdersData?.scan_product_items?.[0]?.barcode === "1"}
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value);
                                                            if (newValue >= 0 || e.target.value === "") {
                                                                setDiscountInputValue(e.target.value);
                                                                validation.handleChange(e);
                                                            }
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if ((e.key === 'Enter' || e.key === 'Tab')) {
                                                                e.preventDefault();
                                                                if (validation.values.discount === "") {
                                                                    toast.error(categoryLabels?.addDiscount);
                                                                } else {
                                                                    fetchScanDiscountApi();
                                                                }
                                                            }
                                                        }}
                                                        invalid={!!(validation.errors.discount && validation.touched.discount)} />
                                                    {validation.errors.discount && validation.touched.discount ? (
                                                        <FormFeedback type="invalid">{validation.errors.discount}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {/* <Col lg={validation.values.discountType ? 6 : 12}> 
                                                <div className="mb-3">
                                                    <Label>Round Off</Label>
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id="product-title-input"
                                                        placeholder={PlaceHolderFormat("round off")}
                                                        name="roundOff"
                                                        disabled={viewOrdersData?.order_save_status === "Publish"}
                                                        value={validation.values.roundOff || ""}
                                                        onBlur={validation.handleBlur}
                                                        min="0"
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value);
                                                            if (newValue >= 0 || e.target.value === "") {
                                                                validation.handleChange(e);
                                                            }
                                                        }}
                                                        invalid={!!(validation.errors.roundOff && validation.touched.roundOff)} />
                                                    {validation.touched.roundOff && validation.errors.roundOff ? (
                                                        <div className="text-danger">{validation.errors.roundOff}</div>
                                                    ) : null}
                                                </div>
                                            </Col> */}
                                        </Row>
                                        {isDiscountLoading ?
                                            <div className="d-flex align-items-center justify-content-center spinner">
                                                <Spinner className="me-6" />
                                            </div>
                                            :
                                            <Row>
                                                <Col xxl={6}>
                                                    <div className="mb-3">
                                                        <Label>Net Amount</Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="product-title-input"
                                                            placeholder="Enter net amount"
                                                            name="amount"
                                                            disabled
                                                            value={validation.values.amount || ""}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={!!(validation.errors.amount && validation.touched.amount)} />
                                                        {validation.touched.amount && validation.errors.amount ? (
                                                            <div className="text-danger">{validation.errors.amount}</div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col xxl={6}>
                                                    <div className="mb-3">
                                                        <Label>Discounted Amount</Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="product-title-input"
                                                            placeholder="Enter discounted amount"
                                                            name="discountableAmount"
                                                            disabled
                                                            value={validation.values.discountableAmount || ""}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            readOnly
                                                            invalid={!!(validation.errors.discountableAmount && validation.touched.discountableAmount)} />
                                                        {validation.touched.discountableAmount && validation.errors.discountableAmount ? (
                                                            <div className="text-danger">{validation.errors.discountableAmount}</div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                {viewOrdersData?.order_save_status === "Publish" && <><Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label>Taxable Amount</Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="product-title-input"
                                                            placeholder="Enter taxable amount"
                                                            name="taxable_amount"
                                                            value={viewOrdersData?.taxable_amount}
                                                            disabled
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={!!(validation.errors.taxable_amount && validation.touched.taxable_amount)} />
                                                        {validation.touched.taxable_amount && validation.errors.taxable_amount ? (
                                                            <div className="text-danger">{validation.errors.taxable_amount}</div>
                                                        ) : null}
                                                    </div>
                                                </Col><Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label>GST/Tax</Label>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                id="product-title-input"
                                                                placeholder="Enter tax"
                                                                name="tax"
                                                                disabled
                                                                value={viewOrdersData?.gst_amount}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={!!(validation.errors.tax && validation.touched.tax)} />
                                                            {validation.touched.tax && validation.errors.tax ? (
                                                                <div className="text-danger">{validation.errors.tax}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col></>}
                                                <Col lg={12}>
                                                    <div className="mb-3">
                                                        <Label>Net Payable</Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="product-title-input"
                                                            placeholder="Enter net payable"
                                                            name="net_payable"
                                                            value={validation.values.net_payable}
                                                            disabled
                                                            onBlur={validation.handleBlur}

                                                            invalid={!!(validation.errors.net_payable && validation.touched.net_payable)} />
                                                        {validation.touched.net_payable && validation.errors.net_payable ? (
                                                            <div className="text-danger">{validation.errors.net_payable}</div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>}
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardHeader>
                                        <h5 className="card-title mb-0">Customer Code</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col lg={6}>
                                                Select Customer
                                            </Col>
                                            <Col lg={6}>
                                                {viewOrdersData?.order_save_status !== 'Publish' && (
                                                    <button className="btn float-end text-decoration-underline" onClick={() => { setCustomerModal(true); }}>
                                                        Add New
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>
                                        <Select
                                            id="customer"
                                            name="customer"
                                            options={customerList}
                                            value={customerList?.find((option: any) => option.value === validation.values.customer)}
                                            onChange={handleCustomerChange}
                                            isClearable
                                            isDisabled={orderId !== undefined}
                                            onBlur={validation.handleBlur}
                                            placeholder="Select Customer" />
                                        {validation.touched.customer && validation.errors.customer ? (
                                            <div className="text-danger">{validation.errors.customer}</div>
                                        ) : null}
                                        <div className="mt-3">
                                            <Label>Mobile Number</Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="product-title-input"
                                                placeholder="Enter mobile no."
                                                name="mobile_No"
                                                value={validation.values.mobile_No || ""}
                                                onBlur={validation.handleBlur}
                                                disabled
                                                onChange={validation.handleChange}
                                                invalid={!!(validation.errors.mobile_No && validation.touched.mobile_No)} />
                                            {validation.touched.mobile_No && validation.errors.mobile_No ? (
                                                <div className="text-danger">{validation.errors.mobile_No}</div>
                                            ) : null}
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardHeader>
                                        <h5 className="card-title mb-0">Payment Mode</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col lg={3} className="mt-2">
                                                <Label>Cash: </Label>
                                            </Col>
                                            <Col lg={9}>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter cash amount"
                                                    name="cash_amount"
                                                    value={validation.values.cash_amount !== undefined ? validation.values.cash_amount : ""}
                                                    onBlur={validation.handleBlur}
                                                    min="0"
                                                    onChange={(e) => {
                                                        const value = e.target.value === "" ? "" : parseFloat(e.target.value);
                                                        if (value === "" || value >= 0) {
                                                            validation.setFieldValue('cash_amount', value);
                                                        }
                                                    }}
                                                    invalid={!!(validation.errors.cash_amount && validation.touched.cash_amount)}
                                                    disabled={viewOrdersData?.order_save_status === "Publish"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col lg={3} className="mt-2">
                                                <Label>Card: </Label>
                                            </Col>
                                            <Col lg={9}>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter card amount"
                                                    name="card_amount"
                                                    value={validation.values.card_amount !== undefined ? validation.values.card_amount : ""}
                                                    onBlur={validation.handleBlur}
                                                    min="0"
                                                    onChange={(e) => {
                                                        const value = e.target.value === "" ? "" : parseFloat(e.target.value);
                                                        if (value === "" || value >= 0) {
                                                            validation.setFieldValue('card_amount', value);
                                                        }
                                                    }}
                                                    invalid={!!(validation.errors.card_amount && validation.touched.card_amount)}
                                                    disabled={viewOrdersData?.order_save_status === "Publish"}
                                                />
                                                {validation.touched.card_amount && validation.errors.card_amount ? (
                                                    <div className="text-danger">{validation.errors.card_amount}</div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                {/* <Card>
                                    <CardHeader>
                                        <h5 className="card-title mb-0">Customer Code</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col lg={6}>
                                                Select Customer
                                            </Col>
                                            <Col lg={6}>
                                                <button className="btn float-end text-decoration-underline" onClick={() => { setCustomerModal(true); }}>
                                                    Add New
                                                </button>
                                            </Col>
                                        </Row>
                                        <Select
                                            id="customer"
                                            name="customer"
                                            options={customerList}
                                            value={customerList.find((option: any) => option.value === validation.values.customer)}
                                            onChange={(option: any) => {
                                                validation.setFieldValue('customer', option.value);
                                                setSelectedCustomer(option);
                                            }}
                                            onBlur={validation.handleBlur}
                                            placeholder="Select Customer" />
                                        {validation.touched.customer && validation.errors.customer ? (
                                            <div className="text-danger">{validation.errors.customer}</div>
                                        ) : null}
                                        <div className="mt-3">
                                            <Label>Mobile Number</Label>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="product-title-input"
                                                placeholder="Enter mobile no."
                                                name="mobile_No"
                                                value={validation.values.mobile_No || ""}
                                                onBlur={validation.handleBlur}
                                                onChange={validation.handleChange}
                                                invalid={!!(validation.errors.mobile_No && validation.touched.mobile_No)} />
                                            {validation.touched.mobile_No && validation.errors.mobile_No ? (
                                                <div className="text-danger">{validation.errors.mobile_No}</div>
                                            ) : null}
                                        </div>
                                    </CardBody>
                                </Card> */}
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Modal
                    id="createTask"
                    isOpen={customerModal}
                    toggle={toggle}
                    modalClassName="zoomIn"
                    centered
                >
                    <Form onSubmit={customerValidation.handleSubmit}>
                        <ModalHeader toggle={toggle} className="p-3 bg-light p-3">
                            Add Customer Details
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <div className='p-2'>
                                    <Col lg={12}>
                                        <Label>Customer Name</Label>
                                        <Input
                                            type="text"
                                            title="customer_name"
                                            name="customer_name"
                                            placeholder={PlaceHolderFormat('name')}
                                            value={customerValidation.values.customer_name}
                                            onBlur={customerValidation.handleBlur}
                                            onChange={customerValidation.handleChange}
                                            invalid={!!(customerValidation.touched.customer_name &&
                                                customerValidation.errors.customer_name)}
                                        ></Input>
                                        {customerValidation.touched.customer_name &&
                                            customerValidation.errors.customer_name ? (
                                            <FormFeedback type="invalid">
                                                {customerValidation.errors.customer_name}
                                            </FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className='p-2'>
                                    <Col lg={12}>
                                        <Label>Customer Email</Label>
                                        <Input
                                            type="text"
                                            title="Email"
                                            name="email"
                                            placeholder={PlaceHolderFormat('email')}
                                            value={customerValidation.values.email}
                                            onBlur={customerValidation.handleBlur}
                                            onChange={customerValidation.handleChange}
                                            invalid={!!(customerValidation.touched.email &&
                                                customerValidation.errors.email)}
                                        ></Input>
                                        {customerValidation.touched.email &&
                                            customerValidation.errors.email ? (
                                            <FormFeedback type="invalid">
                                                {customerValidation.errors.email}
                                            </FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                                <div className='p-2'>
                                    <Col lg={12}>
                                        <Label>Customer Mobile No</Label>
                                        <Input
                                            type="text"
                                            title="contact_no"
                                            name="contact_no"
                                            placeholder={PlaceHolderFormat('mobile no')}
                                            value={customerValidation.values.contact_no}
                                            onBlur={customerValidation.handleBlur}
                                            maxLength={10}
                                            onChange={e => {
                                                const value = e.target.value;
                                                if (/^\d*$/.test(value)) {
                                                    customerValidation.handleChange(e);
                                                }
                                            }}
                                            invalid={!!(customerValidation.touched.contact_no &&
                                                customerValidation.errors.contact_no)}
                                        ></Input>
                                        {customerValidation.touched.contact_no &&
                                            customerValidation.errors.contact_no ? (
                                            <FormFeedback type="invalid">
                                                {customerValidation.errors.contact_no}
                                            </FormFeedback>
                                        ) : null}
                                    </Col>
                                </div>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="none"
                                className="btn btn-ghost-success"
                                onClick={toggle}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" disabled={orderId !== undefined}>
                                {isLoading ? (
                                    <Spinner size="sm" className="me-2" />
                                ) :
                                    "Submit"
                                }
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Col>
        </Row>
    );
};

export default CreateSalesOrder;
