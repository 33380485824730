import {
  COUNTS_OF_PRODUCT_STATUS,
  DELETE_PRODUCT_IMAGE,
  DELETE_TAGS,
  LIST_OF_PRODUCT,
  LIST_OF_TAGS,
  PRODUCT,
  VIEW_PRODUCT,
} from "./apiRoutes";
import { catalogServices } from "./apiServices";

export const listOfProduct = async (data: object) => {
  const response = await catalogServices.post(LIST_OF_PRODUCT, data);
  return response?.data;
};

export const addProduct = async (data: object) => {
  const response = await catalogServices.post(PRODUCT, data);
  return response?.data;
};

export const deleteProduct = async (data: object) => {
  const response = await catalogServices.put(PRODUCT, data);
  return response?.data;
};

export const listOfTags = async (data: object) => {
  const response = await catalogServices.post(LIST_OF_TAGS, data);
  return response?.data;
};

export const viewProduct = async (productId: number | string | undefined) => {
  if(productId !== undefined){
    const response = await catalogServices.get(`${VIEW_PRODUCT}/${productId}`);
    return response?.data;
  }
};

export const editProduct = async (productId: number, data: object) => {
  const response = await catalogServices.put(`${PRODUCT}/${productId}`, data);
  return response?.data;
};

export const deleteTags = async (id: number) => {
  const response = await catalogServices.delete(`${DELETE_TAGS}/${id}`);
  return response?.data;
};

export const deleteProductImage = async (id: number) => {
  const response = await catalogServices.delete(
    `${DELETE_PRODUCT_IMAGE}/${id}`
  );
  return response?.data;
};

export const countsOfProductStatus = async () => {
  const response = await catalogServices.get(COUNTS_OF_PRODUCT_STATUS);
  return response?.data;
};
