// In this file i have comment all code of round off please allow me
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, CardHeader, Row, Input, Label, FormFeedback, Form, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import TableContainer from "Components/Common/TableContainer";
import { PlaceHolderFormat } from "utils/helpers/validationRequiredMessages";
import { addSalesOrderCustomer, addSalesReturn, editSalesReturn, deleteScanProduct, deleteSalesOrderReturnItem, generateDocNo, scanBarcode, viewSalesReturn, salesOrderDiscount, listOfSalesOrderCustomer } from "api/salesOrderApi";
import DeleteModal from "Components/Common/DeleteModal";
import { ResponseStatusEnum } from "utils/helpers/enums";
import { toast } from "react-toastify";
import { addSalesOrderReturn, categoryLabels, currencyFormat, notFound, title } from "utils/helpers/constant";
import LoaderBlur from "Components/Common/BlurLoader";
import { viewAndStoreOrders } from "api/ordersApi";
import { getSalesReturnOrderToken, setSalesReturnOrderToken } from "utils/helpers/sessionStores";
import moment from "moment";
import { dateFormat } from "utils/helpers/comman";

interface CustomerData {
    contact_no?: string,
    id: number,
    label: string,
    name: string,
    value: string
}

interface ScanProduct {
    id: number,
    barcode: string,
    product_name: string,
    sale_rate: number,
    color: string,
    size: string,
    quantity: number,
    discount_type: string,
    discount: number,
    product_id: {
        id: number
    },
    product_variant_id: {
        id: number
    }
}
interface ListOfScanProduct {
    id: number,
    totalNetPayableAmount: number,
    total_quantity: number,
    discount_type: string,
    discount: number,
    scan_product_items: ScanProduct[],
    order_customer_info: {
        customer_name: string,
        customer_contact_no: number
    }
}

interface SalesOrder {
    payment_option: string;
    shipping_charge: number;
    doc_no: string;
    doc_date: any | string;
    discount: any;
    discount_type: string;
    cash_amount: any;
    card_amount: any;
    net_amount: number;
    net_payable: number;
    save_status: any;
    sales_return_items: {
        quantity: number
    }[],
    order_customer_info: {
        customer_name: string,
        customer_contact_no: number
    }
}
interface ScanList {
    scan_product_items: any[];
}

interface Payload {
    bar_code: any;
    doc_no: number | null;
    bill_no?: any;
    order_token: string | undefined | null
}
interface EditBarcodePayload {
    bar_code: any;
    doc_no: any;
    sales_return_id: any;
    bill_no?: any;
}

const AddSalesOrderReturn = (props: any) => {
    document.title = "Sale Order Return";
    const [customerList, setCustomerList] = useState<CustomerData[] | []>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerData | null>(null);
    const [docNumber, setDocNumber] = useState<number | null>(null);
    const [dataOfScanProduct, setDataOfScanProduct] = useState<ListOfScanProduct | null>(null);
    const [scanProductDeleteModal, setScanProductDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [scanProductListId, setScanProductListId] = useState<number | null>(null);
    const { editId } = useParams<{ editId: string }>();
    const history = useNavigate();
    const currentDate = new Date();
    const [addCustomerModel, setAddCustomerModel] = useState(false);
    const [viewSalesOrder, setViewSalesOrder] = useState<SalesOrder>();
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [activeInput, setActiveInput] = useState(null);
    const [addScanList, setAddScanList] = useState<ScanList | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDelLoading, setDelIsLoading] = useState(false);
    const [editTimeData, setEditTimeData] = useState('');
    const [showBillInput, setShowBillInput] = useState(false);

    const handleInputChange = (e: any, field: any) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            validation.handleChange(e);
        }
        setActiveInput(value ? field : null);
    };

    const extractTimeFromDate = (dateString: any) => {
        const parsedDate = moment(dateString);
        const time = parsedDate.format("HH:mm:ss");
        return time;
    };

    const toggle = useCallback(() => {
        if (addCustomerModel) {
            setAddCustomerModel(false);
        } else {
            setAddCustomerModel(true);
        }
    }, [addCustomerModel]);

    const [addSalesReturnStatus, setAddSalesReturnStatus] = useState<string>();
    const [docDate, setDocDate] = useState(currentDate);
    const [time, setTime] = useState(currentDate);
    const [quantity, setQuantity] = useState();
    const [discountType, setDiscountType] = useState<string>();
    const [netPayable, setNetPayable] = useState(0);
    const [discountValue, setDiscountValue] = useState<number | null>(null);
    const [cash, setCash] = useState<number>();
    const [card, setCard] = useState<number>();
    // const [roundOff, setRoundOff] = useState<string>();
    const [addScanDelete, setAddScanDelete] = useState<string>();
    const [paymentModeOption, setPaymentModeOption] = useState<number>();
    const [shippingCharge, setShippingCharge] = useState();
    const [totalNetAmount, setTotalNetAmount] = useState<string>();
    const orderToken: string | undefined | null = getSalesReturnOrderToken();

    const listOfCustomerApi = () => {
        const payload = {
            selectionCriteria: [
                "id",
                "name",
                "contact_no"
            ],
        }
        listOfSalesOrderCustomer(payload).then((response) => {
            if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                const data = response?.data?.listOfCustomerData?.map((item: any) => ({ value: item.name, label: item.name, ...item }));
                setCustomerList(data);
            } else setCustomerList([]);
        }).catch((err) => setCustomerList([]));
    }

    const generateDocNoApi = () => {
        generateDocNo().then((response) => {
            if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                setDocNumber(response?.data);
            } else {
                setDocNumber(null);
                toast.error(response.message);
            }
        }).catch((err) => setDocNumber(null))
    }

    const scanBarcodeApi = () => {
        let trimmedBarcode = validation.values.scanSKU?.startsWith('+') ? validation.values.scanSKU?.slice(1) : validation.values.scanSKU;
        const payload: Payload = {
            bar_code: trimmedBarcode,
            doc_no: docNumber,
            order_token: orderToken
        };
        if (validation.values.bill_no) {
            payload.bill_no = validation.values.bill_no;
        }
        if (orderToken) {
            payload.order_token = orderToken
        }
        const editBarcodePayload: EditBarcodePayload = {
            bar_code: trimmedBarcode,
            doc_no: viewSalesOrder?.doc_no,
            sales_return_id: editId,
        }
        if (validation.values.bill_no) {
            editBarcodePayload.bill_no = validation.values.bill_no
        }
        setSpinnerLoading(true);
        scanBarcode(editId ? editBarcodePayload : payload)
            .then((response) => {
                if (response.message === "Please enter a bill no or order number.") {
                    setShowBillInput(true);
                }
                setAddScanList(response.data);
                if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    setShowBillInput(false)
                    viewOrdersApi();
                    setSalesReturnOrderToken(response?.data?.token);
                    fetchViewProduct();
                } else {
                    setScanProductListId(null);
                    toast.error(response?.message)
                }
            })
            .catch((err) => {
                setScanProductListId(null)
                toast.error(err);
            })
            .finally(() => {
                setSpinnerLoading(false);
            })
    }

    const viewOrdersApi = () => {
        if (orderToken) {
            const payload = {
                order_token: orderToken
            }
            setIsLoading(true);
            viewAndStoreOrders(payload).then((response) => {
                setDataOfScanProduct(response?.data);
                setDiscountType(response?.data.discount_type);
                setTotalNetAmount(response.data.totalNetPayableAmount)
                setScanProductListId(response?.data?.id);
                setPaymentModeOption(response.data.payment_option)
                setShippingCharge(response?.shipping_charge)
                setQuantity(response?.data?.total_quantity)
                validation.setFieldValue('bill_no', '');

            }).catch((err) => {
                return err;
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }

    const fetchViewProduct = () => {
        if (editId) {
            setIsLoading(true);
            const id = parseInt(editId, 10);
            viewSalesReturn(id)
                .then((resp) => {
                    setViewSalesOrder(resp.data)
                })
                .catch((err) => {
                    return err
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    useEffect(() => {
        fetchViewProduct();
    }, []);

    useEffect(() => {
        listOfCustomerApi();
    }, [])

    useEffect(() => {
        if (!editId) {
            generateDocNoApi();
        }
    }, [])

    useEffect(() => {
        viewOrdersApi();
    }, [orderToken])

    const handleDeleteCategory = () => {
        setScanProductDeleteModal(false);
        if (editId) {
            setDelIsLoading(true)
            deleteSalesOrderReturnItem(selectedId!)
                .then((response) => {
                    if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        toast.success(response.message);
                        viewOrdersApi();
                        fetchViewProduct();
                    } else {
                        toast.error(response.message);
                    }
                })
                .catch((err) => {
                    toast.error(title.somethingWrong);
                    return err;
                })
                .finally(() => {
                    setScanProductDeleteModal(false);
                    setDelIsLoading(false);
                });
        } else {
            setDelIsLoading(true)
            const isOnlyOneItem = dataOfScanProduct?.scan_product_items?.length === 1
            deleteScanProduct(selectedId!)
                .then((response) => {
                    if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        toast.success(response.message);
                        if (isOnlyOneItem) {
                            localStorage.clear();
                            setTotalNetAmount('');
                            setQuantity(undefined)
                        }
                        viewOrdersApi();
                    } else {
                        toast.error(response.message);
                    }
                    setAddScanDelete(response.data)
                })
                .catch((err) => {
                    toast.error(title.somethingWrong);
                    return err;
                })
                .finally(() => {
                    setScanProductDeleteModal(false);
                    setDelIsLoading(false);
                });
        }
    };

    const onClickDelete = (id: any) => {
        setSelectedId(id);
        setScanProductDeleteModal(true);
    };

    useEffect(() => {
        if (viewSalesOrder && viewSalesOrder.doc_date) {
            const time = extractTimeFromDate(viewSalesOrder.doc_date);
            setEditTimeData(time);
        }
    }, [viewSalesOrder]);

    const discountTypeOptions = [
        { value: addSalesOrderReturn.Amount, label: addSalesOrderReturn.Amount },
        { value: addSalesOrderReturn.Percentage, label: addSalesOrderReturn.Percentage }
    ];

    const CategoryColumns = [
        {
            header: addSalesOrderReturn.Product,
            accessorKey: addSalesOrderReturn.product_name,
            enableColumnFilter: false,
            cell: (cell: { row: { original: { product_name: string, product_code: string } } }) => (
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <h5 className="fs-14 mb-1">
                            {" "}
                            {cell?.row?.original?.product_name}
                        </h5>
                        <p className="text-muted mb-0">
                            <span className="fw-medium">
                                {" "}
                                {cell?.row?.original?.product_code}
                            </span>
                        </p>
                    </div>
                </div>
            ),
        },
        {
            header: addSalesOrderReturn.sku,
            accessorKey: addSalesOrderReturn.barcode,
            enableColumnFilter: false,
        },
        {
            header: addSalesOrderReturn.Color,
            accessorKey: addSalesOrderReturn.color,
            enableColumnFilter: false,
        },
        {
            header: addSalesOrderReturn.Size,
            accessorKey: addSalesOrderReturn.size,
            enableColumnFilter: false,
        },
        {
            header: addSalesOrderReturn.Quantity,
            accessorKey: addSalesOrderReturn.quantity,
            enableColumnFilter: false,
        },
        {
            header: addSalesOrderReturn.SaleRate,
            accessorKey: editId ? addSalesOrderReturn.price : addSalesOrderReturn.sale_rate,
            enableColumnFilter: false,
            cell: (cell: { row: { original: { sale_rate: number, price: number } } }) => (
                <span>A${editId ? cell.row.original.price : cell.row.original.sale_rate}</span>
            ),
        },
        {
            header: addSalesOrderReturn.Discounttype,
            accessorKey: addSalesOrderReturn.discount_type,
            enableColumnFilter: false,
        },
        {
            header: addSalesOrderReturn.Discount,
            accessorKey: addSalesOrderReturn.discount,
            enableColumnFilter: false,
            cell: (cell: { row: { original: { discount: number } } }) => {
                const discount = cell.row.original.discount;
                return discount !== undefined ? (
                    <span>{discount}%</span>
                ) : null;
            },
        },
        {
            header: addSalesOrderReturn.discountAmount,
            accessorKey: addSalesOrderReturn.discountAmountAccessor,
            enableColumnFilter: false,
            cell: (cell: any) => {
                const { row } = cell;
                return (
                    <span>{currencyFormat?.currency}{(row.original.discounted_sale_rate === null ? notFound.notAvailable : row.original.discounted_sale_rate)}</span>
                );
            }
        },
        {
            header: addSalesOrderReturn.actions,
            cell: (cell: any) => (
                <div className="hstack gap-2">
                    {editId && viewSalesOrder?.save_status === "Save" ? <></> :
                        <button type="button" className="btn btn-sm btn-soft-danger remove-list" onClick={() => {
                            onClickDelete(cell?.row?.original?.id);
                        }} disabled={viewSalesOrder?.save_status === 'Publish'}>
                            <i
                                className="ri-delete-bin-5-fill align-bottom"
                            />
                        </button>
                    }
                </div>
            ),
        },
    ]

    const validation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            docNo: editId ? viewSalesOrder?.doc_no : docNumber ?? 0,
            docDate: editId ? dateFormat(viewSalesOrder?.doc_date) : docDate || '',
            time: editId ? editTimeData : time || "",
            quantity: editId ? viewSalesOrder?.sales_return_items?.[0]?.quantity : quantity ?? 0,
            scanSKU: '',
            discountType: editId ? viewSalesOrder?.discount_type : discountType || '',
            customer: '',
            discountValue: editId ? viewSalesOrder?.discount : discountValue || '',
            // roundOff: roundOff || "",
            netPayable: editId ? viewSalesOrder?.net_payable : netPayable || "",
            amount: editId ? viewSalesOrder?.net_amount : totalNetAmount || "",
            cash: editId ? viewSalesOrder?.cash_amount || '' : cash || '',
            card: editId ? viewSalesOrder?.card_amount || '' : card || '',
            mobile_No: selectedCustomer?.contact_no ?? "",
            mrp: editId ? viewSalesOrder?.net_payable : netPayable || "",
            bill_no: ''
        },

        validationSchema: Yup.object({
            cash: Yup.string().test('required', 'Either cash or card field must be filled.', function (value) {
                const card = this.parent.card || '';
                return !!value || !!card;
            }),
            card: Yup.string().test('required', 'Either cash or card field must be filled.', function (value) {
                const cash = this.parent.cash || '';
                return !!value || !!cash;
            }),
        }),
        onSubmit: (values) => {
            const filteredValues = Object.fromEntries(
                Object.entries(values)?.filter(([key, value]) => value !== "")
            );

            const payload = {
                doc_no: values.docNo,
                save_status: addSalesReturnStatus,
                cash_amount: +filteredValues?.cash,
                card_amount: filteredValues.card,
                net_amount: totalNetAmount,
                order_token: orderToken
            };
            const editPayload = {
                discount_type: values.discountType,
                discount: parseInt(values.discountValue),
                // round_off: parseInt(values.roundOff),
                cash_amount: parseInt(values.cash),
                card_amount: values.card,
                save_status: addSalesReturnStatus,
                customer_name: selectedCustomer?.name,
                customer_contact_no: selectedCustomer?.contact_no,
            };
            if (editId) {
                const id = parseInt(editId, 10);
                editSalesReturn(id, editPayload)
                    .then().then((response) => {
                        if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                            history("/salesOrderReturn");
                            toast.success(response.message);
                            validation.resetForm();
                        } else {
                            toast.error(response.message);
                        }
                    }).catch((err) => {
                        toast.error(title.somethingWrong);
                        return err;
                    });
            } else {
                setIsLoading(true);
                addSalesReturn(payload).then((response) => {
                    if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        history("/salesOrderReturn");
                        localStorage.clear();
                        toast.success(response.message);
                        validation.resetForm();
                    } else {
                        toast.error(response.message);
                    }
                }).catch((err) => {
                    toast.error(title.somethingWrong);
                    return err;
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        }
    });

    const customerValidation: any = useFormik({
        enableReinitialize: true,

        initialValues: {
            customerName: "",
            customerEmail: "",
            customerMobileNo: ""
        },

        validationSchema: Yup.object({
            customerName: Yup.string().required("Please Enter a customer name"),
            customerEmail: Yup.string().required("Please Enter customer email"),
            customerMobileNo: Yup.string().required("Please Enter a customer mobile no")
        }),

        onSubmit: (values) => {
            const payload = {
                customer_name: values.customerName,
                email: values.customerEmail,
                contact_no: values.customerMobileNo
            }
            addSalesOrderCustomer(payload).then((response) => {
                if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    toggle();
                    toast.success(response.message);
                    validation.resetForm();
                    listOfCustomerApi()
                } else {
                    toast.error(response.message);
                }
            }).catch((err) => {
                toast.error(title.somethingWrong);
                return err;
            });
        }
    });
    // useEffect(() => {
    //     let netPayableAmount;
    //     if (discountType === "Percentage" && amount && discountValue) {
    //         netPayableAmount = amount - (amount * parseFloat(discountValue.toString())) / 100;
    //     } else if (amount && discountValue) {
    //         netPayableAmount = amount - parseFloat(discountValue.toString());
    //     }
    //     const roundedNetPayable = Math.round(netPayableAmount ?? 0);
    //     const roundOff = roundedNetPayable - (netPayableAmount ?? 0);
    //     setNetPayable(roundedNetPayable);
    //     setRoundOff(`${roundOff}`);
    // }, [amount, discountType, discountValue])

    const scanDiscountApi = () => {
        let data = {
            scan_product_id: scanProductListId,
            doc_no: docNumber,
            discount_type: validation.values.discountType,
            discount: parseInt(validation.values.discountValue)
        }
        salesOrderDiscount(data)
            .then((resp) => {
                setNetPayable(resp.data)
            })
            .catch((err) => {
                toast.error(title.somethingWrong);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Row>
            <Col lg={12}>
                {isLoading && <LoaderBlur />}
                <div className="page-content">
                    <Container fluid>
                        <DeleteModal
                            show={scanProductDeleteModal}
                            onDeleteClick={handleDeleteCategory}
                            onCloseClick={() => setScanProductDeleteModal(false)}
                        />
                        <Row className="mb-3">
                            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                                <h5 className="fw-semibold mb-0">{editId ? "Sales Return" : "Sales Return"}</h5>
                            </div>
                            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0 text-end">
                                <Link to={"/salesOrderReturn"} className="btn btn-primary mx-2">Back</Link>
                            </div>
                        </Row>
                        <Row>
                            <Form
                                onSubmit={(e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
                                    e.preventDefault();
                                    const clickedButton = (e.nativeEvent.submitter as HTMLInputElement)?.name;
                                    setAddSalesReturnStatus(clickedButton);
                                    validation.handleSubmit();
                                    return false;
                                }}>
                                <Row>
                                    <Col lg={8}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <Label className="form-label" htmlFor="product-title-input">
                                                                {addSalesOrderReturn.DocNo}
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="product-title-input"
                                                                placeholder={PlaceHolderFormat(addSalesOrderReturn.docNo)}
                                                                name="docNo"
                                                                value={validation.values.docNo || ""}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={!!(validation.errors.docNo && validation.touched.docNo)}
                                                                readOnly
                                                                disabled
                                                            />
                                                            {validation.errors.docNo && validation.touched.docNo ? (
                                                                <FormFeedback type="invalid">{validation.errors.docNo}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <Label className="text-capitalize">{addSalesOrderReturn.docDate}</Label>
                                                            <Flatpickr
                                                                className='form-control disabled-input'
                                                                placeholder={PlaceHolderFormat(addSalesOrderReturn.docDate)}
                                                                value={validation.values.docDate}
                                                                onChange={date => validation.setFieldValue('docDate', date[0])}
                                                                options={{ dateFormat: 'd M Y' }}
                                                                disabled={true}
                                                            />
                                                            {validation.touched.docDate && validation.errors.docDate ? (
                                                                <div className="text-danger">{validation.errors.docDate}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <Label>{addSalesOrderReturn.docTime}</Label>
                                                            <Flatpickr
                                                                className='form-control disabled-input'
                                                                placeholder='Select Time'
                                                                value={validation.values.time}
                                                                onChange={time => validation.setFieldValue('time', time[0])}
                                                                options={{ dateFormat: 'H:i', enableTime: true, time_24hr: true, noCalendar: true }}
                                                                disabled
                                                            />
                                                            {validation.touched.time && validation.errors.time ? (
                                                                <div className="text-danger">{validation.errors.time}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <Label>{addSalesOrderReturn.totalQuantity}</Label>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                id="product-title-input"
                                                                placeholder={PlaceHolderFormat(addSalesOrderReturn.quantity)}
                                                                name="quantity"
                                                                value={validation.values.quantity || 0}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={!!(validation.errors.quantity && validation.touched.quantity)}
                                                                readOnly
                                                                disabled
                                                            />
                                                            {validation.touched.quantity && validation.errors.quantity ? (
                                                                <div className="text-danger">{validation.errors.quantity}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Label htmlFor="scanSKU" className="form-label">{addSalesOrderReturn.sku}</Label>
                                                        {spinnerLoading ?
                                                            <div className="d-flex justify-content-center align-item-center">
                                                                <Spinner size="sm" className="me-2" />
                                                            </div>
                                                            :
                                                            <div className="input-group mb-3">
                                                                <span
                                                                    className="input-group-text"
                                                                    id="bill-Discount-addon"
                                                                >
                                                                    <i className="ri-barcode-box-line"></i>
                                                                </span>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="product-Discount-input"
                                                                    placeholder={PlaceHolderFormat(addSalesOrderReturn.sku)}
                                                                    name="scanSKU"
                                                                    aria-label="scanSKU"
                                                                    aria-describedby="bill-Discount-addon"
                                                                    value={validation.values.scanSKU || ""}
                                                                    onChange={validation.handleChange}
                                                                    readOnly={viewSalesOrder?.save_status === addSalesOrderReturn.save}
                                                                    disabled={viewSalesOrder?.save_status === 'Publish' ? true : false}
                                                                    onKeyDown={(e) => {
                                                                        if ((e.key === 'Enter' || e.key === 'Tab')) {
                                                                            e.preventDefault();
                                                                            if (validation.values.scanSKU === "") {
                                                                                toast.error(categoryLabels?.validSKU);
                                                                            } else {
                                                                                scanBarcodeApi();
                                                                            }
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        validation.handleBlur(e);
                                                                        // scanBarcodeApi();
                                                                    }}
                                                                    invalid={!!(validation.errors.scanSKU && validation.touched.scanSKU)}
                                                                />
                                                                {validation.errors.scanSKU && validation.touched.scanSKU ? (
                                                                    <FormFeedback type="invalid">{validation.errors.scanSKU}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        }
                                                    </Col>
                                                    <Col lg={4}>
                                                        {showBillInput && (
                                                            <div className="mb-3">
                                                                <Label className="text-capitalize">{addSalesOrderReturn.billNumber}</Label>
                                                                <Input
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="bill-no"
                                                                    placeholder={PlaceHolderFormat(addSalesOrderReturn.billNumber)}
                                                                    onKeyDown={(e) => {
                                                                        if ((e.key === 'Enter' || e.key === 'Tab')) {
                                                                            e.preventDefault();
                                                                            if (validation.values.discount === "") {
                                                                                toast.error(categoryLabels?.validSKU);
                                                                            } else {
                                                                                scanBarcodeApi();
                                                                            }
                                                                        }
                                                                    }}
                                                                    name="bill_no"
                                                                    value={validation.values.bill_no}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                />
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            {isDelLoading && <LoaderBlur />}
                                            <CardBody>
                                                {editId ? (
                                                    viewSalesOrder && viewSalesOrder?.sales_return_items && viewSalesOrder?.sales_return_items.length > 0 ? (

                                                        <TableContainer
                                                            columns={CategoryColumns}
                                                            data={viewSalesOrder.sales_return_items}
                                                            isGlobalFilter={false}
                                                            customPageSize={5}
                                                            divClass="table-responsive mb-1"
                                                            tableClass="mb-0 align-middle table-border"
                                                            theadClass="table-light text-muted"
                                                            SearchPlaceholder="Search Categories..."
                                                            manualPagination={false}
                                                            manualSorting={false}
                                                        />

                                                    ) : (
                                                        <div className="py-4 text-center">
                                                            <div>
                                                                <i className="ri-search-line display-5 text-success"></i>
                                                            </div>
                                                            <div className="mt-4">
                                                                <h5>{notFound?.loadingContent}</h5>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    dataOfScanProduct && dataOfScanProduct?.scan_product_items && dataOfScanProduct?.scan_product_items?.length > 0 ? (
                                                        <TableContainer
                                                            columns={CategoryColumns}
                                                            data={dataOfScanProduct?.scan_product_items}
                                                            isGlobalFilter={false}
                                                            customPageSize={5}
                                                            divClass="table-responsive mb-1"
                                                            tableClass="mb-0 align-middle table-border"
                                                            theadClass="table-light text-muted"
                                                            SearchPlaceholder="Search Categories..."
                                                            manualPagination={false}
                                                            manualSorting={false}
                                                        />
                                                    ) : (
                                                        <div className="py-4 text-center">
                                                            <div>
                                                                <i className="ri-search-line display-5 text-success"></i>
                                                            </div>
                                                            <div className="mt-4">
                                                                <h5>{notFound?.loadingContent}</h5>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={4}>
                                        <Card>
                                            <CardBody>
                                                <Col >
                                                    <div className="mb-2">
                                                        <Label htmlFor={addSalesOrderReturn.discountType} className="form-label">{addSalesOrderReturn.Discounttype}</Label>
                                                        <Select
                                                            id={addSalesOrderReturn.discountType}
                                                            name={addSalesOrderReturn.discountType}
                                                            options={discountTypeOptions}
                                                            value={discountTypeOptions.find(option => option.value === validation.values.discountType)}
                                                            onChange={(option: any) => validation.setFieldValue(addSalesOrderReturn.discountType, option.value)}
                                                            onBlur={validation.handleBlur}
                                                            placeholder={PlaceHolderFormat(addSalesOrderReturn.Discounttype)}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Row>
                                                    {validation.values.discountType && (
                                                        <Col>
                                                            <Label htmlFor="discountValue" className="form-label">{addSalesOrderReturn.discountIn} {validation.values.discountType === addSalesOrderReturn.Amount ? addSalesOrderReturn.Amount : "%"}</Label>
                                                            <div className="input-group mb-2">
                                                                {validation.values.discountType === "Percentage" && <span className="input-group-text" id="product-Discount-addon"> % </span>}
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="product-Discount-input"
                                                                    placeholder={PlaceHolderFormat(addSalesOrderReturn.Discount)}
                                                                    name="discountValue"
                                                                    aria-label="discountValue"
                                                                    aria-describedby="bill-Discount-addon"
                                                                    value={validation.values.discountValue || ""}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={validation.handleChange}
                                                                    invalid={!!(validation.errors.discountValue && validation.touched.discountValue)}
                                                                    disabled
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === addSalesOrderReturn.enter) {
                                                                            e.preventDefault();
                                                                            scanDiscountApi();
                                                                        }
                                                                    }}
                                                                />
                                                                {validation.errors.discountValue && validation.touched.discountValue ? (
                                                                    <FormFeedback type="invalid">{validation.errors.discountValue}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    )}
                                                    {/* Round Off Comments as of now */}
                                                    {/* <Col lg={validation.values.discountType ? 6 : 12}>
                                                        <div className="mb-3">
                                                            <Label className="text-capitalize">{addSalesOrderReturn.roundOff}</Label>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                id="product-title-input"
                                                                placeholder={PlaceHolderFormat(addSalesOrderReturn.roundOff)}
                                                                name="roundOff"
                                                                value={validation.values.roundOff || ""}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={!!(validation.errors.roundOff && validation.touched.roundOff)}
                                                                readOnly
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col> */}
                                                </Row>
                                                <Row>
                                                    {/*-----Commetned for future use-----*/}
                                                    {/* <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label className="text-capitalize">{addSalesOrderReturn.netPayable}</Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="product-title-input"
                                                            placeholder={PlaceHolderFormat(addSalesOrderReturn.netPayable)}
                                                            name="netPayable"
                                                            value={validation.values.netPayable || ""}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation.handleChange}
                                                            invalid={!!(validation.errors.netPayable && validation.touched.netPayable)}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </div>
                                                </Col> */}
                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Label className="text-capitalize">{addSalesOrderReturn.netAmount}</Label>
                                                            <Input
                                                                type="number"
                                                                className="form-control"
                                                                id="product-title-input"
                                                                placeholder={PlaceHolderFormat(addSalesOrderReturn.netAmount)}
                                                                name="amount"
                                                                value={validation.values.amount || ""}
                                                                onBlur={validation.handleBlur}
                                                                onChange={validation.handleChange}
                                                                invalid={!!(validation.errors.amount && validation.touched.amount)}
                                                                readOnly
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label>
                                                        Payment mode : {editId ? (viewSalesOrder ? viewSalesOrder.payment_option : '') : paymentModeOption}
                                                    </Label>

                                                    <Label>
                                                        Shipping rate: {editId ? (viewSalesOrder ? viewSalesOrder.shipping_charge : '') : shippingCharge}
                                                    </Label>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                        <Card>
                                            <CardHeader>
                                                <h5 className="card-title mb-0">{addSalesOrderReturn.customerCode}</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Select
                                                    id="customer"
                                                    name="customer"
                                                    options={customerList}
                                                    value={customerList.find(option => option.value === dataOfScanProduct?.order_customer_info?.customer_name || viewSalesOrder?.order_customer_info?.customer_name)}
                                                    onChange={(option: CustomerData) => {
                                                        validation.setFieldValue('customer', option.value);
                                                        setSelectedCustomer(option);
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    placeholder={addSalesOrderReturn.selectCustomer}
                                                    isDisabled={true}
                                                />
                                                {validation.touched.customer && validation.errors.customer ? (
                                                    <div className="text-danger">{validation.errors.customer}</div>
                                                ) : null}
                                                <div className="mt-3">
                                                    <Label>{addSalesOrderReturn.mobileNumber}</Label>
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id="product-title-input"
                                                        placeholder={PlaceHolderFormat(addSalesOrderReturn.mobileNo)}
                                                        name="mobile_No"
                                                        value={dataOfScanProduct?.order_customer_info?.customer_contact_no || viewSalesOrder?.order_customer_info?.customer_contact_no || ""}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={!!(validation.errors.mobile_No && validation.touched.mobile_No)}
                                                        disabled={true}
                                                        readOnly
                                                    />
                                                    {validation.touched.mobile_No && validation.errors.mobile_No ? (
                                                        <div className="text-danger">{validation.errors.mobile_No}</div>
                                                    ) : null}
                                                </div>
                                            </CardBody>
                                        </Card>

                                        <Card>
                                            <CardHeader>
                                                <h5 className="card-title mb-0">{addSalesOrderReturn.paymentMode}</h5>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col lg={3} className="mt-2">
                                                        <Label>{addSalesOrderReturn.cash}</Label>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="product-title-input"
                                                            placeholder={PlaceHolderFormat(addSalesOrderReturn.cashAmount)}
                                                            name="cash"
                                                            value={validation.values.cash || ""}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation?.handleChange}
                                                            invalid={!!(validation.errors.cash && validation.touched.cash)}
                                                            readOnly={viewSalesOrder?.save_status === "Save"}
                                                            disabled={viewSalesOrder?.save_status === 'Publish' ? true : false}
                                                        />
                                                        {validation.touched.cash && validation.errors.cash ? (
                                                            <div className="text-danger">{validation.errors.cash}</div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2">
                                                    <Col lg={3} className="mt-2">
                                                        <Label>{addSalesOrderReturn.card}</Label>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="product-title-input"
                                                            placeholder={PlaceHolderFormat(addSalesOrderReturn.cardAmount)}
                                                            name="card"
                                                            value={validation.values.card || ""}
                                                            onBlur={validation.handleBlur}
                                                            onChange={validation?.handleChange}
                                                            invalid={!!(validation.errors.card && validation.touched.card)}
                                                            readOnly={viewSalesOrder?.save_status === "Save"}
                                                            disabled={viewSalesOrder?.save_status === 'Publish' ? true : false}
                                                        />
                                                        {validation.touched.card && validation.errors.card ? (
                                                            <div className="text-danger">{validation.errors.card}</div>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                {viewSalesOrder?.save_status !== addSalesOrderReturn.publish && (
                                    <div className="text-end mb-3">
                                        <button type="submit" name="Publish" className="btn btn-success w-sm">
                                            {addSalesOrderReturn.save}
                                        </button>
                                    </div>
                                )}
                            </Form>
                        </Row>

                        <Modal
                            isOpen={addCustomerModel}
                            toggle={toggle}
                            modalClassName="zoomIn"
                            centered
                        >
                            <Form onSubmit={customerValidation.handleSubmit}>
                                <ModalHeader toggle={toggle} className="p-3 bg-light p-3">
                                    {addSalesOrderReturn.addCustomerDetail}
                                </ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <div className='p-2'>
                                            <Col lg={12}>
                                                <Label>{addSalesOrderReturn.CustomerName}</Label>
                                                <Input
                                                    type="text"
                                                    title="Name"
                                                    name="customerName"
                                                    placeholder={PlaceHolderFormat('name')}
                                                    value={customerValidation.values.customerName}
                                                    onBlur={customerValidation.handleBlur}
                                                    onChange={customerValidation.handleChange}
                                                    invalid={!!(customerValidation.touched.customerName &&
                                                        customerValidation.errors.customerName)}
                                                ></Input>
                                                {customerValidation.touched.customerName &&
                                                    customerValidation.errors.customerName ? (
                                                    <FormFeedback type="invalid">
                                                        {customerValidation.errors.customerName}
                                                    </FormFeedback>
                                                ) : null}
                                            </Col>
                                        </div>
                                        <div className='p-2'>
                                            <Col lg={12}>
                                                <Label>{addSalesOrderReturn.CustomerEmail}</Label>
                                                <Input
                                                    type="text"
                                                    title="Name"
                                                    name="customerEmail"
                                                    placeholder={PlaceHolderFormat('email')}
                                                    value={customerValidation.values.customerEmail}
                                                    onBlur={customerValidation.handleBlur}
                                                    onChange={customerValidation.handleChange}
                                                    invalid={!!(customerValidation.touched.customerEmail &&
                                                        customerValidation.errors.customerEmail)}
                                                ></Input>
                                                {customerValidation.touched.customerEmail &&
                                                    customerValidation.errors.customerEmail ? (
                                                    <FormFeedback type="invalid">
                                                        {customerValidation.errors.customerEmail}
                                                    </FormFeedback>
                                                ) : null}
                                            </Col>
                                        </div>
                                        <div className='p-2'>
                                            <Col lg={12}>
                                                <Label>{addSalesOrderReturn.CustomerMobile}</Label>
                                                <Input
                                                    type="text"
                                                    title="customerMobileNo"
                                                    name="customerMobileNo"
                                                    maxLength={10}
                                                    placeholder={PlaceHolderFormat('mobile no')}
                                                    value={customerValidation.values.customerMobileNo}
                                                    onBlur={customerValidation.handleBlur}
                                                    onChange={e => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                            customerValidation.handleChange(e);
                                                        }
                                                    }}
                                                    invalid={!!(customerValidation.touched.customerMobileNo &&
                                                        customerValidation.errors.customerMobileNo)}
                                                ></Input>
                                                {customerValidation.touched.customerMobileNo &&
                                                    customerValidation.errors.customerMobileNo ? (
                                                    <FormFeedback type="invalid">
                                                        {customerValidation.errors.customerMobileNo}
                                                    </FormFeedback>
                                                ) : null}
                                            </Col>
                                        </div>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="none"
                                        className="btn btn-ghost-success"
                                        onClick={toggle}
                                    >
                                        {addSalesOrderReturn.cancel}
                                    </Button>
                                    <Button type="submit" color="primary">
                                        {addSalesOrderReturn.submit}
                                    </Button>
                                </ModalFooter>
                            </Form>
                        </Modal>
                    </Container>
                </div >
            </Col>
        </Row>
    );
};

export default AddSalesOrderReturn;
