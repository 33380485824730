import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Col, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";

import {
    Column,
    Table as ReactTable,
    ColumnFiltersState,
    FilterFn,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';

import {
    ProductsGlobalFilter,
    CustomersGlobalFilter,
    OrderGlobalFilter,
    ContactsGlobalFilter,
    CompaniesGlobalFilter,
    LeadsGlobalFilter,
    CryptoOrdersGlobalFilter,
    InvoiceListGlobalSearch,
    TicketsListGlobalFilter,
    NFTRankingGlobalFilter,
    TaskListGlobalFilter,
} from "../../Components/Common/GlobalSearchFilter";
import NoResultsMessage from "./DataNotFoundMessage";
import Select from "react-select";
import { pageSizeOptions } from "utils/helpers/enums";

// Column Filter
const Filter = ({
    column
}: {
    column: Column<any, unknown>;
    table: ReactTable<any>;
}) => {
    const columnFilterValue = column.getFilterValue();

    return (
        <>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={value => column.setFilterValue(value)}
                placeholder="Search..."
                className="w-36 border shadow rounded"
                list={column.id + 'list'}
            />
            <div className="h-1" />
        </>
    );
};
const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
        e.preventDefault();
    }
};

// Global Filter
const DebouncedInput = ({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);

    return (
        <input {...props} value={value} id="search-bar-0" className="form-control search" onChange={e => setValue(e.target.value)} onKeyDown={handleKeyDown}/>
    );
};

interface TableContainerProps {
    columns?: any;
    data?: any;
    isGlobalFilter?: any;
    isProductsFilter?: any;
    isCustomerFilter?: any;
    isOrderFilter?: any;
    isContactsFilter?: any;
    isCompaniesFilter?: any;
    isLeadsFilter?: any;
    isCryptoOrdersFilter?: any;
    isInvoiceListFilter?: any;
    isTicketsListFilter?: any;
    isNFTRankingFilter?: any;
    isTaskListFilter?: any;
    handleTaskClick?: any;
    customPageSize?: any;
    tableClass?: any;
    theadClass?: any;
    trClass?: any;
    thClass?: any;
    divClass?: any;
    SearchPlaceholder?: any;
    handleLeadClick?: any;
    handleCompanyClick?: any;
    handleContactClick?: any;
    handleTicketClick?: any;
    currentPage?: any;
    setCurrentPage?: any;
    totalPages?: any;
    totalRecords?: any;
    totalNumberOfRows?: any;
    fetchData?: any;
    onSearch?: any;
    manualPagination: boolean;
    manualFiltering?: boolean;
    setCustomPageSize?: any;
    fetchSortingData?: any;
    manualSorting: boolean
}

const TableContainer = ({
    columns,
    data,
    isGlobalFilter,
    isProductsFilter,
    isCustomerFilter,
    isOrderFilter,
    isContactsFilter,
    isCompaniesFilter,
    isLeadsFilter,
    isCryptoOrdersFilter,
    isInvoiceListFilter,
    isTicketsListFilter,
    isNFTRankingFilter,
    isTaskListFilter,
    customPageSize,
    tableClass,
    theadClass,
    trClass,
    thClass,
    divClass,
    SearchPlaceholder,
    currentPage,
    setCurrentPage,
    totalPages,
    totalRecords,
    totalNumberOfRows,
    fetchData,
    onSearch,
    manualPagination, // If you wanna do the manualPagination then you need to pass true from your main component.
    manualFiltering,
    setCustomPageSize,
    fetchSortingData,
    manualSorting,

}: TableContainerProps) => {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [onValueSearch, setOnValueSearch] = useState('');
    const [sorting, setSorting] = useState<any>([]);
    const [order, setOrder] = useState<any>({});
    const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
        const itemRank = rankItem(row.getValue(columnId), value);
        addMeta({
            itemRank
        });
        return itemRank.passed;
    };

    const table = useReactTable({
        columns,
        data,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
            sorting,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const {
        getHeaderGroups,
        getRowModel,
        getCanPreviousPage,
        getCanNextPage,
        getPageOptions,
        setPageIndex,
        nextPage,
        previousPage,
        setPageSize,
        getState
    } = table;

    useEffect(() => {
        Number(customPageSize) && setPageSize(Number(customPageSize));
    }, [customPageSize, setPageSize]);

    const handleNextPage = () => {
        fetchData(currentPage + 1);
    };

    const handlePreviousPage = () => {
        fetchData(currentPage - 1); // Call fetchData with previous page number
    };

    const handlePageClick = (pageNumber: number) => {
        setCurrentPage(pageNumber); // Update currentPage state with the clicked page number
        fetchData(pageNumber); // Call fetchData with the clicked page number
    };

    const handleValueChange = (newValue: any) => {
        setOnValueSearch(newValue);
        // Check if onSearch is a function before calling it
        if (typeof onSearch === 'function') {
            onSearch(newValue);
        }
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
    };

    const handleFirstPageAuto = () => {
        setPageIndex(0);
    };

    const handleLastPageAuto = () => {
        setPageIndex(getPageOptions()?.length - 1);
    };

    const getTotalRecordsCount = () => { // In this i i need to add this logic bcz i get total count but my component need the array and in that values. 
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages?.push(i);
        }
        return pages;
    };

    const handleSorting = (column: any) => {
        let columnName = column.accessorKey;
        const isSorted = sorting?.find((sort: any) => sort.accessorKey === columnName);
        const newSorting = isSorted
            ? { accessorKey: columnName, desc: !isSorted.desc }
            : { accessorKey: columnName, desc: false };
        setSorting([newSorting]);
        const order = newSorting.desc ? "DESC" : "ASC";
        setOrder(() => ({
            [columnName]: order,
        }));
        fetchSortingData(currentPage, columnName, order);
    };

    // Ellipsis With Pagination code is start

    const renderPageNumbers = () => {
        const MAX_PAGES_DISPLAYED = customPageSize;
        const totalPages11 = totalPages;
        const currentPage11 = currentPage;

        let startPage = Math.max(1, currentPage11 - 2);
        let endPage = Math.min(totalPages11, startPage + MAX_PAGES_DISPLAYED - 1);

        if (endPage - startPage + 1 < MAX_PAGES_DISPLAYED) {
            startPage = Math.max(1, endPage - MAX_PAGES_DISPLAYED + 1);
        }

        const pageNumbers = [];

        if (startPage > 1) {
            pageNumbers?.push(
                <li key="ellipsisStart" className="page-item disabled">
                    <span className="page-link">...</span>
                </li>
            );
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers?.push(
                <li key={i} className={currentPage11 === i ? "page-item active" : "page-item"}>
                    <Link to="#" className="page-link" onClick={() => handlePageClick(i)}>{i}</Link>
                </li>
            );
        }

        if (endPage < totalPages11) {
            pageNumbers?.push(
                <li key="ellipsisEnd" className="page-item disabled">
                    <span className="page-link">...</span>
                </li>
            );
        }

        return pageNumbers;
    };

    // Ellipsis With Pagination code is end

    const handlePageSizeChange = (e: any) => {
        const newSize = Number(e.target.value);
        setPageSize(newSize);
        setCustomPageSize(newSize);
        table.setPageSize(newSize);
    };

    const handleSelectChange = (selectedOption: any) => {
        if (selectedOption) {
            handlePageSizeChange({ target: { value: selectedOption.value } });
        }
    };

    return (
        <Fragment>
            {isGlobalFilter && <Row className="mb-3">
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <form>
                        <Row>
                            <Col sm={5}>
                                {manualFiltering ?
                                    <div className={(isProductsFilter || isContactsFilter || isCompaniesFilter || isNFTRankingFilter) ? "search-box me-2 mb-2 d-inline-block" : "search-box me-2 mb-2 d-inline-block col-12"}>
                                        <DebouncedInput
                                            value={onValueSearch ?? ''}
                                            onChange={value => handleValueChange(String(value))}
                                            placeholder={SearchPlaceholder}
                                        />
                                        <i className="bx bx-search-alt search-icon"></i>
                                    </div>
                                    :
                                    <div className={(isProductsFilter || isContactsFilter || isCompaniesFilter || isNFTRankingFilter) ? "search-box me-2 mb-2 d-inline-block" : "search-box me-2 mb-2 d-inline-block col-12"}>
                                        <DebouncedInput
                                            value={globalFilter ?? ''}
                                            onChange={value => setGlobalFilter(String(value))}
                                            placeholder={SearchPlaceholder}
                                        />
                                        <i className="bx bx-search-alt search-icon"></i>
                                    </div>}
                            </Col>
                            {manualPagination === true && <Col sm={6} className="d-flex justify-content-end align-items-center">
                            <div className="text-muted">Items per page: </div> &nbsp;
                                <Select
                                    options={pageSizeOptions}
                                    value={pageSizeOptions?.find(option => option?.value === customPageSize)}
                                    onChange={handleSelectChange}
                                    className="custom-select"
                                />
                            </Col>}
                            {isProductsFilter && (
                                <ProductsGlobalFilter />
                            )}
                            {isCustomerFilter && (
                                <CustomersGlobalFilter />
                            )}
                            {isOrderFilter && (
                                <OrderGlobalFilter />
                            )}
                            {isContactsFilter && (
                                <ContactsGlobalFilter />
                            )}
                            {isCompaniesFilter && (
                                <CompaniesGlobalFilter />
                            )}
                            {isLeadsFilter && (
                                <LeadsGlobalFilter />
                            )}
                            {isCryptoOrdersFilter && (
                                <CryptoOrdersGlobalFilter />
                            )}
                            {isInvoiceListFilter && (
                                <InvoiceListGlobalSearch />
                            )}
                            {isTicketsListFilter && (
                                <TicketsListGlobalFilter />
                            )}
                            {isNFTRankingFilter && (
                                <NFTRankingGlobalFilter />
                            )}
                            {isTaskListFilter && (
                                <TaskListGlobalFilter />
                            )}
                        </Row>
                    </form>
                </CardBody>
            </Row>}


            <div className={divClass}>
                <Table hover className={tableClass}>
                    <thead className={theadClass}>
                        {getHeaderGroups()?.map((headerGroup: any) => (
                            <tr className={trClass} key={headerGroup.id}>
                                {headerGroup.headers.map((header: any) => (
                                    manualSorting === false ?
                                        <th key={header.id} className={thClass}  {...{
                                            onClick: header.column.getToggleSortingHandler(),
                                        }}>
                                            {header.isPlaceholder ? null : (
                                                <React.Fragment>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: ' ',
                                                        desc: ' ',
                                                    }
                                                    [header.column.getIsSorted() as string] ?? null}
                                                    {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} table={table} />
                                                        </div>
                                                    ) : null}
                                                </React.Fragment>
                                            )}
                                        </th>
                                        :
                                        <th
                                            key={header.id}
                                            className={thClass}
                                            onClick={() => {
                                                if (
                                                    header.column.columnDef.enableSorting === undefined
                                                ) {
                                                    handleSorting(header.column.columnDef);
                                                }
                                            }}
                                        >
                                            <b>
                                                {header.isPlaceholder ? null : (
                                                    <React.Fragment>
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {order[header.column.columnDef.accessorKey] ===
                                                            "ASC" && " ▲"}
                                                        {order[header.column.columnDef.accessorKey] ===
                                                            "DESC" && " ▼"}
                                                        {header.column.getCanFilter() ? (
                                                            <div>
                                                                <Filter column={header.column} table={table} />
                                                            </div>
                                                        ) : null}
                                                    </React.Fragment>
                                                )}
                                            </b>
                                        </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {/* <tbody> It's needed for future
                        {getRowModel()?.rows?.length > 0 ? (
                            getRowModel().rows?.map((row: any) => {
                                return (
                                    <tr key={row.id}>
                                        {row?.getVisibleCells()?.map((cell: any) => {
                                            return (
                                                <td key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={columns?.length} className="text-center">
                                    <div className="py-2">
                                        <NoResultsMessage />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody> */}

                    <tbody>
                        {data && data?.length > 0 ? ( // Check if there is data to display
                            getRowModel()?.rows?.length > 0 ? (
                                // Render table rows if data is available
                                getRowModel().rows?.map((row: any) => {
                                    return (
                                        <tr key={row.id}>
                                            {row?.getVisibleCells()?.map((cell: any) => {
                                                return (
                                                    <td key={cell.id}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })
                            ) : (
                                // Render a single row with a message if there is no data
                                <tr>
                                    <td colSpan={columns?.length} className="text-center">
                                        <div className="py-2">
                                            <NoResultsMessage />
                                        </div>
                                    </td>
                                </tr>
                            )
                        ) : (
                            <tr>
                                <td colSpan={columns?.length} className="text-center">
                                    <div className="py-2">
                                        <NoResultsMessage />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>

                </Table>
            </div>

            {data?.length !== 0 && <>
                {manualPagination ?
                    <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                        <div className="col-sm">
                            <div className="text-muted">Showing<span className="fw-semibold ms-1">{totalNumberOfRows}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                                <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                                    <Link to="#" className="page-link" onClick={handleFirstPage}>
                                        {"<<"}
                                    </Link>
                                </li>
                                <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                                    <Link to="#" className="page-link" onClick={handlePreviousPage}>Previous</Link>
                                </li>
                                {/* Without the ellipsis */}
                                {/* {getTotalRecordsCount()?.map((item: any, key: number) => ( 
                                <React.Fragment key={key}>
                                    <li className="page-item">
                                        <Link to="#" className={currentPage === item ? "page-link active" : "page-link"} onClick={() => handlePageClick(item)}>{item}</Link>
                                    </li>
                                </React.Fragment>
                            ))} */}
                                {renderPageNumbers()}
                                <li className={currentPage === totalPages ? "page-item disabled" : "page-item"}>
                                    <Link to="#" className="page-link" onClick={handleNextPage}>Next</Link>
                                </li>
                                <li className={currentPage === totalPages ? 'page-item disabled' : 'page-item'}>
                                    <Link to="#" className="page-link" onClick={handleLastPage}>
                                        {">>"}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Row>
                    :
                    <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                        <div className="col-sm">
                            <div className="text-muted">Showing<span className="fw-semibold ms-1">{data?.length}</span> of <span className="fw-semibold">{getState().pagination.pageSize}</span> Results
                            </div>
                        </div>
                        <div className="col-sm-auto">
                            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                                <li className={!getCanPreviousPage() ? 'page-item disabled' : 'page-item'}>
                                    <Link to="#" className="page-link" onClick={handleFirstPageAuto}>
                                        {"<<"}
                                    </Link>
                                </li>
                                <li className={!getCanPreviousPage() ? "page-item disabled" : "page-item"}>
                                    <Link to="#" className="page-link" onClick={previousPage}>Previous</Link>
                                </li>
                                {getPageOptions()?.map((item: any, key: number) => (
                                    <React.Fragment key={key}>
                                        <li className="page-item">
                                            <Link to="#" className={getState().pagination.pageIndex === item ? "page-link active" : "page-link"} onClick={() => setPageIndex(item)}>{item + 1}</Link>
                                        </li>
                                    </React.Fragment>
                                ))}
                                <li className={!getCanNextPage() ? "page-item disabled" : "page-item"}>
                                    <Link to="#" className="page-link" onClick={nextPage}>Next</Link>
                                </li>
                                <li className={!getCanNextPage() ? 'page-item disabled' : 'page-item'}>
                                    <Link to="#" className="page-link" onClick={handleLastPageAuto}>
                                        {">>"}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Row>
                }
            </>}
        </Fragment>
    );
};

export default TableContainer;