import { HSN, LIST_OF_HSN } from "./apiRoutes";
import { catalogServices } from "./apiServices";

export const listOfHSN = async (data: object) => {
    const response = await catalogServices.post(LIST_OF_HSN, data);
    return response?.data;
};

export const addHSN = async (data: object) => {
    const response = await catalogServices.post(HSN, data);
    return response?.data;
};

export const editHSN = async (hsnCodeId: number, data: object) => {
    const response = await catalogServices.put(`${HSN}/${hsnCodeId}`, data);
    return response?.data;
};

export const deleteHSN = async (hsnCodeId: number | undefined) => {
    const response = await catalogServices.delete(`${HSN}/${hsnCodeId}`);
    return response?.data;
};

