import React from 'react';
import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { countOfOrder, countOfStaffOrder, dashboardWigets } from 'api/dashboardApi';
import { useEffect, useState } from 'react';
import { getFinancialYear, getRole } from 'utils/helpers/sessionStores';
import { RolesEnum } from 'utils/helpers/enums';
import { useMainContext } from 'Context/Context';

const Widgets = () => {

    const role = getRole();
    const financialYear = getFinancialYear();

    const [countData, setCountData] = useState({
        purchaseOrder: 0,
        onlineOrder: 0,
        offlineOrder: 0,
        totalCustomer: 0
    });
    const { filterData, setFilterData } = useMainContext();
    const [staffCountData, setStaffCountData] = useState({
        totalOrder: 0
    });

    useEffect(() => {
        countOfOrder({ financialYear })
            .then((resp) => {
                setCountData(resp.data);
            })
            .catch((err) => {
                console.log(err)
            })
        if (role === RolesEnum.SELLER) {
            dashboardWigets({ financialYear })
                .then((resp) => {
                    setFilterData(resp.data);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [])

    return (
        <React.Fragment>
            {role === RolesEnum.SELLER ? (
                <>
                    <Row>
                        <Col xl={6} md={6}>
                            <Card className="card-animate">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total Business Amount</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                {countData && (
                                                    <CountUp
                                                        start={0}
                                                        end={filterData?.totalBusinessAmount ?? 0}
                                                        duration={8}
                                                        decimals={2}
                                                        decimal="."
                                                    />
                                                )}
                                            </h4>
                                        </div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title rounded fs-3 bg-success-subtle">
                                                <i className="text-success ri-money-dollar-box-fill"></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6} md={6}>
                            <Card className="card-animate">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total Sales Quantity</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                <CountUp
                                                    start={0}
                                                    end={filterData?.totalQuantity ?? 0}
                                                    duration={4}
                                                />
                                            </h4>
                                        </div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title rounded fs-3 bg-warning-subtle">
                                                <i className="text-warning ri-hashtag"></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6} md={6}>
                            <Card className="card-animate">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total Footfall</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                <CountUp
                                                    start={0}
                                                    end={filterData?.totalFootfall ?? 0}
                                                    duration={4}
                                                />
                                            </h4>
                                        </div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title rounded fs-3 bg-info-subtle">
                                                <i className="text-info ri-bar-chart-fill"></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6} md={6}>
                            <Card className="card-animate">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total Customer</p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                <CountUp
                                                    start={0}
                                                    end={countData?.totalCustomer ?? 0}
                                                    duration={4}
                                                />
                                            </h4>
                                        </div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title rounded fs-3 bg-primary-subtle">
                                                <i className="text-primary bx bx-user-circle"></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            ) : (
                <Row>
                    <Col xl={4} md={4}>
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Online Order</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-4">
                                    <div>
                                        <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                            <CountUp
                                                start={0}
                                                end={countData?.onlineOrder ?? 0}
                                                duration={4}
                                            />
                                        </h4>
                                    </div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title rounded fs-3 bg-warning-subtle">
                                            <i className={`text-warning bx bx-wallet`}></i>
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={4} md={4}>
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Offline Order</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-4">
                                    <div>
                                        <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                            <CountUp
                                                start={0}
                                                end={countData?.offlineOrder ?? 0}
                                                duration={4}
                                            />
                                        </h4>
                                    </div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title rounded fs-3 bg-info-subtle">
                                            <i className={`text-info bx bx-shopping-bag`}></i>
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={4} md={4}>
                        <Card className="card-animate">
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">Total Customer</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-4">
                                    <div>
                                        <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                            <CountUp
                                                start={0}
                                                end={countData?.totalCustomer ?? 0}
                                                duration={4}
                                            />
                                        </h4>
                                    </div>
                                    <div className="avatar-sm flex-shrink-0">
                                        <span className="avatar-title rounded fs-3 bg-primary-subtle">
                                            <i className={`text-primary bx bx-user-circle`}></i>
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default Widgets;